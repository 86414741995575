import React from "react";
import styled from "styled-components";
import { PropTypes } from 'prop-types';
import OpenAI, { trimAndRemoveFORMATTER } from '../../services/OpenAI';
import Scrollable from "../Scrollable";

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-weight: 600;
  }
  > p {
    margin: 0.5rem;
  }
  img {
    width: 60px;
  }
  ol {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  input[type="text"] {
    padding: 10px 3rem 10px 1rem;
  }
  button {
    background: #1ebd53;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    color: white;
    text-align: center;
    font-weight: 600;
  }
`;
const Suggestions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  > div {
    border: 1px dashed black;
    width: 240px;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    h3 {
      font-weight: 600;
      text-transform: capitalize;
    }
    > div {
      flex: 1;
    }
    button {
      margin: 1rem 0;
    }
  }
`;

class GoInteractive extends React.PureComponent {
  static propTypes = {
    html: PropTypes.string,
    website: PropTypes.string,
    onComplete: PropTypes.func,
  }
  state = {
    busy: false,
    letters: 0,
    response: '',
  }
  componentDidMount() {
    OpenAI.onStateUpdate(this.openAIUpdate);
    const { website, html } = this.props;
    if (this.state.busy) return;
    this.openAIText = '';
    this.setState({ busy: true, letters: 0, response: '' });
    OpenAI.getSuggestions(website, html);
  }
  componentWillUnmount() {
    OpenAI.offStateUpdate(this.openAIUpdate);
    clearInterval(this.textUpdateInterval);
  }
  openAIUpdate = () => {
    if (!OpenAI.openAIState) return;
    const { generating } = this.state;
    const { description, response, complete } = OpenAI.openAIState;
    if (generating) {
      this.openAIText = `Generating ${description}\n\n${trimAndRemoveFORMATTER(response)}`;
      this.setState({ busy: !complete });
    } else if (OpenAI.suggestions) {
      this.openAIText = `Generating your suggestions!\n\n${OpenAI.suggestions.map(({ d }) => (`${d}`)).join('\n\n')}`;
      this.setState({ busy: !complete });
    }
    if (!complete) {
      if (!this.textUpdateInterval) {
        this.textUpdateInterval = setInterval(this.textUpdate, 40);
      }
    } else {
      clearInterval(this.textUpdate);
      this.textUpdateInterval = null;
      this.props.onComplete();
    }
  }
  textUpdate = () => {
    if (!this.openAIText) return;
    let { letters } = this.state;
    if (this.openAIText.length > letters) letters += 1;
    this.setState({ response: this.openAIText.slice(0, letters), letters });
  }
  generateContent = async (type, description) => {
    this.state.generating = description;
    this.state.letters = 0;
    this.state.busy = true;
    this.state.response = '';
    OpenAI.createContent({ type, description });
  }
  render() {
    const { busy, response } = this.state;
    const { suggestions } = OpenAI;
    return (
      <Scrollable
        key={"Go Interactive"}
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: 'absolute', inset: 0 }}
        rememberScrollPosition={`gointeractive`}
      >
        <Wrapper>
          {busy && <div style={{ width: '100%', padding: '0 2.5rem' }}>
            <div
              style={{ whiteSpace: 'pre-wrap' }}
            >{response}</div>
          </div> || suggestions && <Suggestions>  
            {suggestions.map(({ t, d }) => (
              <div key={d}>
                <h3>{t}</h3>
                <div>{d}</div>
                {t === 'learning path' && <button>Coming Soon!</button> || <button onClick={() => this.generateContent(t, d)}>Generate</button>}
              </div>
            ))}
          </Suggestions>}
        </Wrapper>
      </Scrollable>
    )
  }
};
export default GoInteractive;
