import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import ChangeableImage from '../../components/ChangeableImage/index';
import FocusableDiv from './FocusableDiv';
import AssetCache from '../../services/cache/AssetCache';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import AppConstants from '../../submodules/logictry_config/s3_config.json';

const Wrapper = styled.div`
  position: relative;
  margin: ${Constants.TreeContentHalfIndent} 0px ${Constants.TreeContentHalfIndent} ${Constants.TreeContentIconIndent};
  border-radius: ${Constants.BoxRadius}px;
  overflow: hidden;
  > div:nth-child(2) {
    opacity: 0;
  }
  &:hover {
    > div:nth-child(2) {
      opacity: 1;
    }
  }
`;
const DarkOverlay = styled.div`
  background-color: rgba(0,0,0,0.7);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Constants.White};
`

export default class FileBox extends React.PureComponent {
  static propTypes = {
    file: PropTypes.object,
  };
  componentDidMount() {
    const { file } = this.props;
    file.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { file } = this.props;
    file.offStateUpdate(this);
  }
  checkFocus = () => {
    const { file } = this.props;
    if (!this.ref) return;
    if (GrayOut.isActive(file)) this.ref.focus({ preventScroll: true });
  }
  onDelete = (e) => {
    const { editing } = GrayOut;
    const { file } = this.props;
    e.stopPropagation();
    e.preventDefault();
    if (editing) file.parents[file.parents.length - 1].deleteChild(file);
    else file.updateUrl('', editing);
  }
  onUploadFinish = (_file) => {
    const { editing } = GrayOut;
    const { file } = this.props;
    const imageUrl = _file.signedUrl.split('?')[0];
    file.updateUrl(imageUrl, editing);
    AssetCache.clearAccountObjects();
  }
  onUploadQueued = (queued) => {
    const { editing } = GrayOut;
    const { file } = this.props;
    file.updateUrl(queued ? 'UPLOAD_QUEUED' : '', editing);
  }
  render() {
    const { editing, tree } = GrayOut;
    const { isDisabled } = tree;
    const { file } = this.props;
    const { text, owner } = file;
    const disableEditing = isDisabled || !editing && text && !owner;
    const allowDeleting = !isDisabled && (editing || owner);

    let s3Path;
    let signingUrl;
    if (Company.company && Company.company._id) {
      s3Path = `${Company.company._id}/${UserAccount.account._id}/`;
      signingUrl = `/v1${AppConstants.S3.signingUrls.companyfiles}`;
    } else {
      s3Path = `${UserAccount.account._id}/`;
      signingUrl = `/v1${AppConstants.S3.signingUrls.userfiles}`;
    }

    return (
      <FocusableDiv node={file}>
        <Wrapper ref={(e) => { this.ref = e; this.checkFocus(); }}>
          <ChangeableImage
            s3Path={s3Path}
            signingUrl={signingUrl}
            style={{
              width: Constants.BoxSize,
              height: Constants.BoxSize,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: Constants.BoxRadius,
              border: !disableEditing && '1px dashed'
            }}
            backgroundImage={text}
            onUploadFinish={this.onUploadFinish}
            disableEditing={!!disableEditing}
            allowDeleting={!!allowDeleting}
            onDelete={this.onDelete}
            large
            queueUpload={!UserAccount.isLoggedIn}
            onUploadQueued={this.onUploadQueued}
          />
          {!editing && file && file.text && !file.owner && (
            <div>
              <DarkOverlay>Expand</DarkOverlay>
            </div>
          )}
        </Wrapper>
      </FocusableDiv>
    );
  }
}
