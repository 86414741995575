import React from 'react';
// import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import ScriptLoader from '../../services/ScriptLoader';
import WindowSize from '../../services/WindowSize';

const PARTNERS = {
  "#1 NO POVERTY": {
    "1": {},
  },
  "#2 ZERO HUNGER": {
    "2": {},
  },
  "#3 GOOD HEALTH AND WELL-BEING": {
    "3": {},
  },
  "#4 QUALITY EDUCATION": {
    "4": {},
  },
  "#5 GENDER EQUALITY": {
    "5": {},
  },
  "#6 CLEAN WATER AND SANITATION": {
    "6": {},
  },
  "#7 AFFORDABLE AND CLEAN ENERGY": {
    "7": {},
  },
  "#8 DECENT WORK AND ECONOMIC GROWTH": {
    "8": {},
  },
  "#9 INDUSTRY, INNOVATION AND INFRASTRUCTURE": {
    "9": {},
  },
  "#10 REDUCED INEQUALITIES": {
    "10": {},
  },
  "#11 SUSTAINABLE CITIES AND COMMUNITIES": {
    "11": {},
  },
  "#12 RESPONSIBLE CONSUMPTION & PRODUCTION": {
    "12": {},
  },
  "#13 CLIMATE ACTION": {
    "13": {},
  },
  "#14 LIFE BELOW WATER": {
    "14": {},
  },
  "#15 LIFE ON LAND": {
    "15": {},
  },
  "#16 PEACE, JUSTICE AND STRONG INSTITUTIONS": {
    "16": {},
  },
  "#17 PARTNERSHIPS FOR THE GOALS": {
    "17": {},
  },
}
const sdgColors = [
  "#E5243B", // 1. No Poverty
  "#DDA63A", // 2. Zero Hunger
  "#4C9F38", // 3. Good Health and Well-being
  "#C5192D", // 4. Quality Education
  "#FF3A21", // 5. Gender Equality
  "#26BDE2", // 6. Clean Water and Sanitation
  "#FCC30B", // 7. Affordable and Clean Energy
  "#A21942", // 8. Decent Work and Economic Growth
  "#FD6925", // 9. Industry, Innovation, and Infrastructure
  "#DD1367", // 10. Reduced Inequality
  "#FD9D24", // 11. Sustainable Cities and Communities
  "#BF8B2E", // 12. Responsible Consumption and Production
  "#3F7E44", // 13. Climate Action
  "#0A97D9", // 14. Life Below Water
  "#56C02B", // 15. Life on Land
  "#00689D", // 16. Peace, Justice, and Strong Institutions
  "#19486A"  // 17. Partnerships for the Goals
];
let expertNode;

export default class LogicBaseExpertsPage extends React.PureComponent {
  static propTypes = {
    words: PropTypes.object,
  }
  componentDidMount() {
    ScriptLoader.load('https://logictrystatic.s3.amazonaws.com/js/d3.min.js');
    ScriptLoader.onStateUpdate(this.loadd3Cloud);
    WindowSize.onStateUpdate(this);
    this.renderWordcloud();
  }
  componentWillUnmount() {
    ScriptLoader.offStateUpdate(this.loadd3Cloud);
    if (expertNode) expertNode.parentNode.removeChild(expertNode);
    WindowSize.offStateUpdate(this);
  }
  loadd3Cloud = () => {
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/d3.min.js')) return;
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/cloud.min.js')) ScriptLoader.load('https://logictrystatic.s3.amazonaws.com/js/cloud.min.js');
    this.renderWordcloud();
  }
  renderWordcloud = () => {
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/d3.min.js')) return;
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/cloud.min.js')) return;

    if (!expertNode) {
      // Specify the chart’s dimensions.
      const width = window.innerWidth - 240;
      const height = window.innerHeight - 64;
      const { mobile } = WindowSize;
      const radius = mobile ? 120 : Math.max(180, Math.min(width / 3, height / 3));

      function formatData(partners) {
        const data = [];
        Object.keys(partners).forEach((name) => {
          data.push({
            "name": name,
            "value": 1,
            "children": formatData(partners[name])
          })
        });
        return data;
      }

      const data = {
        "name": "flare",
        "children": formatData(PARTNERS)
      };

      // Create the color scale.
      const color = window.d3.scaleOrdinal(sdgColors);

      // Compute the layout.
      const hierarchy = window.d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value);
      const root = window.d3.partition()
        .size([2 * Math.PI, hierarchy.height + 1])
        (hierarchy);
      root.each(d => d.current = d);

      // Create the arc generator.
      const arc = window.d3.arc()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
        .padRadius(radius * 1.5)
        .innerRadius(d => d.y0 * radius - radius / 2)
        .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1) - radius / 2)

      // Create the SVG container.
      const svg = window.d3.create("svg")
        .attr("viewBox", [-width / 2, -height / 2, width, height])
        .style("width", "100%")
        .style("height", "100%")
        .style("font", mobile ? "10px montserrat" : "11px montserrat");

      // Append the arcs.
      const path = svg.append("g")
        .selectAll("path")
        .data(root.descendants().slice(1))
        .join("path")
        .attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
        .attr("fill-opacity", d => arcVisible(d.current) ? 1 : 0)
        .attr("pointer-events", d => arcVisible(d.current) ? "auto" : "none")
        .attr("d", d => arc(d.current));

      // Make them clickable if they have children.
      path.filter(d => d.children)
        .style("cursor", "pointer")
        .on("click", clicked);

      const format = window.d3.format(",d");
      path.append("title")
        .text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(d.value)}`);

      const label = svg.append("g")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .style("user-select", "none")
        .selectAll("text")
        .data(root.descendants().slice(1))
        .join("text")
        .attr("dy", "0.35em")
        .attr("fill-opacity", d => +labelVisible(d.current))
        .attr("transform", d => labelTransform(d.current))
        .text(d => d.data.name);

      const parent = svg.append("circle")
        .datum(root)
        .attr("r", radius / 2)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .style("cursor", "pointer")
        .on("click", clicked);

      svg.append("image")
        .attr("xlink:href", "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/b3af86d02b00ba06b5b30f9cbeb2c5c4/logiccircle.png")
        .attr("x", -radius * 0.3)
        .attr("y", -radius * 0.3)
        .attr("width", radius * 0.6)
        .attr("height", radius * 0.6)
        .style('pointer-events', 'none');

      // Handle zoom on click.
      function clicked(event, p) {
        parent.datum(p.parent || root);

        root.each(d => d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth)
        });

        const t = svg.transition().duration(750);

        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if this transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        path.transition(t)
          .tween("data", d => {
            const i = window.d3.interpolate(d.current, d.target);
            return t => d.current = i(t);
          })
          .filter(function(d) {
            return +this.getAttribute("fill-opacity") || arcVisible(d.target);
          })
          .attr("fill-opacity", d => arcVisible(d.target) ? (1) : 0)
          .attr("pointer-events", d => arcVisible(d.target) ? "auto" : "none")
          .attrTween("d", d => () => arc(d.current));

        label.filter(function(d) {
            return +this.getAttribute("fill-opacity") || labelVisible(d.target);
          }).transition(t)
          .attr("fill-opacity", d => +labelVisible(d.target))
          .attrTween("transform", d => () => labelTransform(d.current));
      }
      function arcVisible(d) {
        return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
      }
      function labelVisible(d) {
        return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
      }
      function labelTransform(d) {
        const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
        const y = (d.y0 + d.y1) / 2 * radius - radius / 2;
        return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
      }
      expertNode = svg.node()
    }

    document.getElementById('logictry_expert_mount').appendChild(expertNode);
  }
  render() {
    const { mobile } = WindowSize;
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: mobile ? 'fixed' : null, inset: 0 }} id="logictry_expert_mount" />
    )
  }
}