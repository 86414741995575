export default function convertColorToHex(str) {
  if (!str) return null;

  // Check if the color is already in hex format
  if (/^#([0-9A-Fa-f]{3}){1,2}$/.test(str)) {
    return str; // Return hex color as is
  }

  // Create a temporary canvas element to use for color conversion
  var ctx = document.createElement('canvas').getContext('2d');
  ctx.fillStyle = str;

  // Get the computed fillStyle (this will be the converted color in rgb/rgba)
  var computedColor = ctx.fillStyle;
  // Check if the computed color is in rgb or rgba format
  if (computedColor.startsWith('rgb')) {
    return rgbToHex(computedColor); // Convert to hex
  }

  // If it's not a valid color (or not in rgb/rgba), return the original input
  return computedColor;
}

function rgbToHex(rgb) {
  // Handle rgba as well
  if (rgb.startsWith('rgba')) {
    return null;
  }

  // For rgb (without alpha), just convert it
  const rgbValues = rgb.match(/\d+/g);
  if (!rgbValues || rgbValues.length < 3) {
    return rgb; // Return original if conversion fails
  }

  // Convert R, G, B values to hex and combine them
  return `#${rgbValues
    .slice(0, 3) // Use only the first three values (R, G, B)
    .map(v => Number(v).toString(16).padStart(2, '0')) // Convert each to two-digit hex
    .join('')}`; // Concatenate the hex values
}
