import Service from './Service';
import OpenAIApi from './server/OpenAIApi';
import TreeCache from './cache/TreeCache';
const origin = (window.logictry && window.logictry.origin) || window.location.origin;

class OpenAIService extends Service {
  textEmbeddings = {};
  clearOpenAIState() {
    this.openAIState = null;
    this.emitStateUpdate();
  }
  getSuggestions = async (website, html) => {
    sendRequest(`${origin}/v1/openai`, 'POST', JSON.stringify({ website, html }), (response, complete) => {
      this.openAIState = {
        response,
        complete
      };
      this.suggestions = [];
      this.website = website;
      this.html = html;
      try {
        this.openAIState.response.split('{').forEach((cq, i) => {
          if (i === 0) return;
          try {
            const clarifyQuestion = cq.split('}')[0];
            this.suggestions.push(JSON.parse(`{${clarifyQuestion}}`));
          } catch(e) {
            //
          }
        });
      } catch(e) {
        //
      }
      this.emitStateUpdate();
    });
  }
  createContent = async (settings, team) => {
    const { type, description } = settings;
    if (this.openAIState && !this.openAIState.complete) return;
    this.openAIState = {
      type,
      description,
      response: '',
      complete: false,
    };
    this.emitStateUpdate();
    sendRequest(`${origin}/v1/openai`, 'PATCH', JSON.stringify(settings || {}), (response, complete) => {
      this.openAIState = {
        type,
        description,
        response,
        complete
      };
      if (complete) {
        const newTreeText = trimAndRemoveFORMATTER(response);
        const tree = TreeCache.createNew(newTreeText);
        if (team) tree.addTeam(team);
        TreeCache.createTree(tree);
        this.openAIState = null;
        this.emitStateUpdate();
      }
      this.emitStateUpdate();
    });
  }
  getCount = async () => {
    const result = await OpenAIApi.getCount();
    if (result && result.count) this.decisionsMadeCount = result.count;
    this.emitStateUpdate();
  }
  getAnswer = async (tree, resultOnly) => {
    const _id = tree._id;
    const result = await OpenAIApi.update({
      _id,
      template: tree.referenceTree,
      question: tree.title.text,
      answers: tree.getOpenAIPrompt(),
      resultOnly
    }, null, { _id });
    return result;
  }
  updateEmbedding = async (_id, texts) => {
    const add = texts.map(buildRawHTML);
    return OpenAIApi.patchEmbeddingsById(_id, {
      add
    });
  }
  askQuestionClientSide = async (_id, query, texts) => {
    // Format texts and get embeddings
    const formattedTexts = texts.map(buildRawHTML);
    const allTextEmbeddingsFound = formattedTexts.every((text) => this.textEmbeddings[text]);
    const temp = allTextEmbeddingsFound ? [query] : [query, ...formattedTexts];
    const { embeddings } = await OpenAIApi.patchEmbeddingsById(_id, {
      temp
    });

    // Get Embeddings
    const questionEmbedding = embeddings[0];
    if (embeddings.length > 1) {
      embeddings.slice(1).forEach((e, i) => {
        this.textEmbeddings[formattedTexts[i]] = e;
      });
    }

    // Calculate similarities
    const similarities = [];
    formattedTexts.forEach((formattedText, i) => {
      const embedding = this.textEmbeddings[formattedText];
      const similarity = calculateCosineSimilarity(questionEmbedding, embedding);
      similarities.push({ index: i, similarity, embedding, text: formattedTexts[i] });
    });
    similarities.sort((a, b) => b.similarity - a.similarity);

    // Sort in ascending order of index
    let context = '';
    similarities.forEach(({ index, text }, i) => {
      // Only check for previous similarity if i > 0
      const words = context.split(/\s+/);
      if (words.length > 4000) return;
      if (i > 0) {
        context += `\n\n${text}`;
      } else {
        // For the first element, just add the text
        context += `${text}`;
      }
    });

    // Construct prompt
    this.openAIState = {
      response: '',
      complete: false,
    };
    sendRequest(`${origin}/v1/openai/embeddings`, 'POST', JSON.stringify({ context, query } || {}), (response, complete) => {
      this.openAIState = {
        response,
        complete
      };
      this.emitStateUpdate();
    });
  }
  askQuestionServerSide = async (query, ids) => {
    // Construct prompt
    this.openAIState = {
      response: '',
      complete: false,
    };
    sendRequest(`${origin}/v1/openai/embeddings`, 'POST', JSON.stringify({ query, ids } || {}), (response, complete) => {
      this.openAIState = {
        response,
        complete
      };
      this.emitStateUpdate();
    });
  }
}

export function trimAndRemoveFORMATTER(text) {
  return removeSurroundingQuotes(text.replaceAll('```json', '').replaceAll('```html', '').replaceAll('```', '').trim());
}
function removeSurroundingQuotes(str) {
  if (str.startsWith('"') && str.endsWith('"')) {
    return str.slice(1, -1);
  } else if (str.startsWith('"')) {
    return str.slice(1);
  } else if (str.endsWith('"')) {
    return str.slice(0, -1);
  }
  return str;
}
function calculateCosineSimilarity(vector1, vector2) {
  let dotProduct = 0;
  let normVector1 = 0;
  let normVector2 = 0;
  for (let i = 0; i < vector1.length; i += 1) {
    dotProduct += vector1[i] * vector2[i];
    normVector1 += vector1[i] * vector1[i];
    normVector2 += vector2[i] * vector2[i];
  }
  normVector1 = Math.sqrt(normVector1);
  normVector2 = Math.sqrt(normVector2);
  return dotProduct / (normVector1 * normVector2);
}
function buildRawHTML(inputHTML) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(inputHTML, 'text/html');

  function buildHTML(node) {
      let result = '';

      // Check if the node is an element node
      if (node.nodeType === Node.ELEMENT_NODE) {
          // Start the tag (without any attributes)
          result += `<${node.tagName.toLowerCase()}>`;

          // Add the inner HTML of the node
          node.childNodes.forEach(child => {
              result += buildHTML(child);
          });

          // Close the tag
          result += `</${node.tagName.toLowerCase()}>`;
      } else if (node.nodeType === Node.TEXT_NODE) {
          // For text nodes, just return the text content
          result += node.textContent;
      }

      return result;
  }

  // Build HTML from the child nodes of the body
  const bodyChildren = Array.from(doc.body.childNodes);
  let output = '';
  bodyChildren.forEach(child => {
      output += buildHTML(child);
  });

  return output;
}
function sendRequest(url, method, body, cb) {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = () => {
    if (xhttp.readyState === XMLHttpRequest.LOADING || xhttp.readyState === XMLHttpRequest.DONE) {
      cb(xhttp.responseText, xhttp.readyState === XMLHttpRequest.DONE)
    }
  };
  xhttp.open(method, url, true);
  xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  const csrfToken = document.querySelector('meta[name="csrf-token"]');
  if (csrfToken) xhttp.setRequestHeader("csrf-token", csrfToken.content);
  xhttp.send(body);
}

const singleton = new OpenAIService();
export default singleton;
