/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import Constants from '../../submodules/logictry_config/constants';
import MaxWidth from '../../components/MaxWidth';
import UserAccount from '../../services/UserAccount';
import WindowSize from '../../services/WindowSize';
import ReferralApi from '../../services/server/ReferralApi';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import Button from '../../components/Button';
import ChangePassword from '../RegisterPage/ChangePassword';
import StatefulButton from '../../components/StatefulButton';
import StatefulTextField from '../../components/StatefulTextField';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import ClickableIcon from '../../components/ClickableIcon/index';
import ConfirmDialog from '../../components/ConfirmDialog/index';
import Scrollable from '../../components/Scrollable';
import UserProfileHeader from '../../components/UserProfileHeader';
import ProgressBar from '../../components/ProgressBar';
import ShareOnSocial from '../../components/ShareOnSocial';
import Select from '../../styledhtml/Select';

const SettingsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0.5rem 6rem 0.5rem;
`;
const Section = styled.div`
  width: 100%;
  padding: 4rem 0;
  border-bottom: ${Constants.BottomBorderDivider};

  > div {
    padding: 0.5rem 0rem;
    display: flex;

    &:last-child {
      border-bottom: unset;
    }
  }

  button {
    padding: 0px;
    color: ${Constants.PrimaryColor};
    font-size: ${Constants.SemiSmallFontSize};
    font-weight: 500;
  }

  i {
    color: ${Constants.PrimaryColor};
  }

  span {
    justify-content: unset;
  }
  select {
    color: ${Constants.PrimaryColor};
    font-weight: 500;
  }
`;
const Title = styled.div`
  font-weight: bold;
  font-size: ${Constants.NormalText};
  align-items: center;
`;
const Row = styled.div`
  font-size: ${Constants.SemiSmallFontSize};
  display: flex;
  // justify-content: space-between;
  flex-direction: row;
  align-items: center;

  > *:nth-child(1) {
    min-width: 300px;
    ${Constants.MediaMobileAndTablet} {
      min-width: 180px;
    }
    ${Constants.MediaMobile} {
      min-width: 140px;
    }
    margin: 0px;
    display: flex;
    align-items: center;
    > * {
      margin-right: 10px;
    }
  }
  > * {
    margin: 10px;
  }

  > *:nth-child(2) {
    padding-left: 16px;
  }

  ${Constants.MediaMobile} {
    flex-direction: column;
    align-items: flex-start;
  }

  > Button {
    color: ${Constants.DarkOrange};
  }
  textarea {
    border: 0.0625rem solid rgba(0,0,0,0.42);
    width: 100%;
    max-width: calc(80vw - 16px);
    opacity: 1;
    height: 120px;
    overflow: hidden;
    margin-left: 26px;
    resize: none;
    padding: 8px 12px;
  }
`;
const QRCodeDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > div:first-child {
    width: 100%;
    text-align: left;
  }

  > *:nth-child(2) {
    margin: 4rem 0 2rem;
  }
  > div:last-child {
    width: 240px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }
`;
// const ConnectAccount = styled.div`
//   cursor: pointer;
//   &:hover {
//     color: ${Constants.PrimaryColor};
//     font-weight: bold;
//   }
// `;

const originalAccountState = {
  facebook: {},
  notifications: {
    all: false,
    email: {
      all: false,
    },
  },
};

export default class SettingsPage extends React.PureComponent {
  state = {
    account: JSON.parse(JSON.stringify(originalAccountState)),
    changingPassword: false,
    warning: '',
    success: '',
    mfaWarning: '',
    mfaAuthUrl: false,
    verifyCode: '',
    confirmDisableMFA: false,
    socialSettings: {},
    count: 0,
    confirmDelete: false,
  };

  componentDidMount() {
    UserAccount.updateState = null;
    UserAccount.onStateUpdate(this.userAccountUpdate);
    WindowSize.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this.userAccountUpdate);
    this.userAccountUpdate();
    ReferralApi.getCount().then((res) => {
      if (!res) return;
      const { count } = res;
      this.setState({ count });
    });
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this.userAccountUpdate);
    WindowSize.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this.userAccountUpdate);
  }
  connectFacebookNative = () => {
    // if (UserAccount.account.facebook && UserAccount.account.facebook.id) Server.disconnectFacebook();
  }
  connectFacebookMobile = (response) => {
    if (!response || !response.profile || !response.tokenDetail) return;

    const facebook = {
      email: response.profile.email,
      id: response.profile.id,
      name: response.profile.name,
      accessToken: response.tokenDetail.accessToken,
    };

    UserAccount.updateUserProfile({ facebook });
  }
  userAccountUpdate = () => {
    const { updateState, account } = UserAccount;
    const isLogicWiki = Constants.isLogicWiki;
    const publicUser = isLogicWiki && LogicBaseUserCache.get(account._id);
    if (isLogicWiki && !publicUser) return;
    let warning = '';
    let success = '';
    if (updateState) {
      if (updateState.error === 'UsernameAlreadyRegistered') warning = 'Username is already in use';
      else if (updateState.error) warning = 'Update failed';
      else if (updateState.success) success = 'Success!';
    }
    this.setState({ account: { ...JSON.parse(JSON.stringify(originalAccountState)), ...JSON.parse(JSON.stringify(account)) }, warning, success, socialSettings: publicUser && publicUser.socialSettings || {} });
  }

  updateState = () => this.forceUpdate();
  resetProfile = () => this.accountUpdate();
  saveProfile = () => {
    if (!this.state.account.fullname || this.state.account.fullname.length <= 0) return this.setState({ warning: 'Full Name blank' });
    const isLogicWiki = Constants.isLogicWiki;
    if (isLogicWiki) {
      let publicUser = LogicBaseUserCache.get(this.state.account._id);
      if (publicUser && publicUser._id) {
        publicUser.socialSettings = this.state.socialSettings;
        LogicBaseUserCache.update(publicUser);
      } else {
        const newUser = LogicBaseUserCache.createObject({ _id: this.state.account._id, a: 0, fr: 0, fe: 0, v: true });
        LogicBaseUserCache.create(newUser);
      }
    }
    return UserAccount.updateUserProfile(this.state.account);
  }

  enableMFA = () => {
    UserAccount.enableMFA().then((results) => {
      if (results.success && results.authUrl) this.setState({ mfaAuthUrl: results.authUrl, verifyCode: '' });
    });
  }
  verifyMFA = () => {
    const { verifyCode } = this.state;
    UserAccount.verifyMFA(verifyCode).then((error) => {
      if (error === 'MFAVerificationFailed') this.setState({ mfaWarning: 'Invalid code' });
    });
  }
  disableMFA = () => {
    if (!this.state.confirmDisableMFA) return this.setState({ confirmDisableMFA: true });
    UserAccount.disableMFA();
    return this.setState({ mfaAuthUrl: '', verifyCode: '', confirmDisableMFA: false });
  }
  cancelDisableMFA = () => this.setState({ confirmDisableMFA: false });

  handleClose = () => this.setState({ changingPassword: false });
  handleChange = (change) => {
    if (change === 'allNotifications') this.state.account.notifications.all = !this.state.account.notifications.all;
    if (change === 'emailNotifications') this.state.account.notifications.email.all = !this.state.account.notifications.email.all;
    if (change === 'newAppLike') this.state.account.notifications.email.newAppLike = !this.state.account.notifications.email.newAppLike;
    if (change === 'newAppPin') this.state.account.notifications.email.newAppPin = !this.state.account.notifications.email.newAppPin;
    if (change === 'newAppCollection') this.state.account.notifications.email.newAppCollection = !this.state.account.notifications.email.newAppCollection;
    if (change === 'newCollectionApp') this.state.account.notifications.email.newCollectionApp = !this.state.account.notifications.email.newCollectionApp;
    if (change === 'newCollectionEditor') this.state.account.notifications.email.newCollectionEditor = !this.state.account.notifications.email.newCollectionEditor;
    if (change === 'newCollectionSubscriber') this.state.account.notifications.email.newCollectionSubscriber = !this.state.account.notifications.email.newCollectionSubscriber;
    if (change === 'newComment') this.state.account.notifications.email.newComment = !this.state.account.notifications.email.newComment;
    if (change === 'newCommentReply') this.state.account.notifications.email.newCommentReply = !this.state.account.notifications.email.newCommentReply;
    if (change === 'newCommentMention') this.state.account.notifications.email.newCommentMention = !this.state.account.notifications.email.newCommentMention;
    if (change === 'newCommentLike') this.state.account.notifications.email.newCommentLike = !this.state.account.notifications.email.newCommentLike;
    if (change === 'newFollower') this.state.account.notifications.email.newFollower = !this.state.account.notifications.email.newFollower;
    if (change === 'newFollowRequest') this.state.account.notifications.email.newFollowRequest = !this.state.account.notifications.email.newFollowRequest;
    if (change === 'newFollowRequestApproved') this.state.account.notifications.email.newFollowRequestApproved = !this.state.account.notifications.email.newFollowRequestApproved;
    if (change === 'appProjectsCreated') this.state.account.notifications.email.appLoggedOutProjectCreated = !this.state.account.notifications.email.appLoggedOutProjectCreated;
    if (change === 'notifyInvitedEditor')  this.state.account.notifications.email.notifyWhenInvitedEditor = !this.state.account.notifications.email.notifyWhenInvitedEditor;
    if (change === 'disableLoggedoutTreeCreation') {
      if (!this.state.account.privacySettings) this.state.account.privacySettings = {};
      this.state.account.privacySettings.disableLoggedoutTreeCreation = !this.state.account.privacySettings.disableLoggedoutTreeCreation;
    }
    if (change === 'disableLoggedoutCollectionSubscription') {
      if (!this.state.account.privacySettings) this.state.account.privacySettings = {};
      this.state.account.privacySettings.disableLoggedoutCollectionSubscription = !this.state.account.privacySettings.disableLoggedoutCollectionSubscription;
    }
    if (change === 'featureFlagBeta') {
      if (!this.state.account.featureFlags) this.state.account.featureFlags = {};
      this.state.account.featureFlags.beta = !this.state.account.featureFlags.beta;
    }
    this.forceUpdate();
  }
  changePassword = () => this.setState({ changingPassword: true });
  logout = () => UserAccount.logout();
  allNotifications = () => this.handleChange('allNotifications');
  emailNotifications = () => this.handleChange('emailNotifications');
  appProjectsCreated = () => this.handleChange('appProjectsCreated');
  notifyInvitedEditor = () => this.handleChange('notifyInvitedEditor');
  featureFlagBeta = () => this.handleChange('featureFlagBeta');
  disableLoggedoutTreeCreation = () => this.handleChange('disableLoggedoutTreeCreation');
  disableLoggedoutCollectionSubscription = () => this.handleChange('disableLoggedoutCollectionSubscription');
  deleteAccount = () => {
    this.state.account.permanentlyDelete = true;
    this.saveProfile();
    setTimeout(() => {
      UserAccount.logout();
    },1000);
  }
  render() {
    const { mobile } = WindowSize;
    const { account, mfaAuthUrl, warning, mfaWarning, success, confirmDisableMFA, count, confirmDelete } = this.state;
    if (!account || !account._id) return null;
    const isLogicWiki = Constants.isLogicWiki;
    if (this.state.changingPassword) {
      return (
        <FullScreenVerticalCenterContent>
          <ChangePassword email={UserAccount.account.email} changePassword onComplete={this.handleClose} />
        </FullScreenVerticalCenterContent>
      );
    }
    const featureFlags = account.featureFlags || {};
    return (
      <Scrollable
        vertical
        style={{ position: (mobile) ? 'relative' : 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <FullScreenVerticalCenterContent alignTop>
          <MaxWidth>
            <DrawerBreadcrumb></DrawerBreadcrumb>
            <UserProfileHeader
              user={UserAccount.account}
              hideProfile
            />
            <SettingsWrapper>
              <Section>
                <Title>Account Settings</Title>
                <Row>
                  <div>Fullname{!this.state.account.fullname && <b style={{ color: Constants.PrimaryColor }}>&nbsp;(missing)</b>}</div>
                  <StatefulTextField
                    value={this.state.account.fullname}
                    onChange={(fullname) => { this.state.account.fullname = fullname; this.forceUpdate(); }}
                    hideUnderline
                    placeholder="Enter Full Name"
                    maxLength={50}
                    width={'100%'}
                  />
                </Row>
                <Row>
                  <div>Username{!this.state.account.username && <b style={{ color: Constants.PrimaryColor }}>&nbsp;(missing)</b>}</div>
                  <StatefulTextField
                    value={this.state.account.username}
                    onChange={(username) => {
                      this.state.account.username = username;
                      this.forceUpdate();
                    }}
                    hideUnderline
                    placeholder="Enter Username"
                    maxLength={30}
                    width={'100%'}
                    dontAllowSpaces
                  />
                </Row>
                <Row>
                  <div>Bio</div>
                  <textarea maxLength={250} style={{ paddingLeft: 12 }} onChange={(e) => { this.state.account.description = e.target.value; this.forceUpdate(); }} defaultValue={this.state.account.description} />
                </Row>
                <Row>
                  <div>Website</div>
                  <StatefulTextField
                    value={this.state.account.website}
                    onChange={(website) => { this.state.account.website = website; this.forceUpdate(); }}
                    hideUnderline
                    placeholder="Enter Website"
                    maxLength={256}
                    width={'100%'}
                  />
                </Row>
                <Row>
                  <div>Email</div>
                  <StatefulTextField
                    value={this.state.account.email}
                    onChange={(email) => { this.state.account.email = email; this.forceUpdate(); }}
                    hideUnderline
                    placeholder="Enter Email"
                    maxLength={50}
                    width={'100%'}
                  />
                </Row>
                <Row>
                  <div>Password</div>
                  <Button onClick={this.changePassword}>Change Password</Button>
                </Row>
                <Row>
                  <div>Logout</div>
                  <Button onClick={this.logout}>Log Out</Button>
                </Row>
              </Section>
              {isLogicWiki && <Section>
                <Title>Social Sharing</Title>
                <Row>
                  <div>
                    <div>Invite Friends</div>
                  </div>
                  <div style={{ width: 'calc(100% - 2rem)' }}>
                    <ShareOnSocial title="Share on social" text={'Check out Logictry'} facebook={`https://logictry.com/?referrer=${account._id}`} twitter={`https://logictry.com/?referrer=${account._id}`} linkedIn={`https://logictry.com/?referrer=${account._id}`} />
                    <div style={{ margin: '2rem 0 1rem' }}>
                      <ProgressBar progress={(count * 100 / 10)} primaryColor={Constants.PrimaryColor} />
                    </div>
                    {count < 10 && <div style={{ textAlign: 'center' }}>{count} of 10 friends invited - invite {10 - count} more to earn a badge!</div>
                      || <div style={{ textAlign: 'center' }}>{count} friends invited - keep it up!</div>}
                  </div>
                </Row>
              </Section>}
              {isLogicWiki && <Section>
                <Title>Privacy Settings</Title>
                <Row>
                  <div>
                    <Select value={this.state.socialSettings.s || 'public'} onChange={(e) => { this.state.socialSettings.s = e.target.value; this.forceUpdate(); }}>
                      <option value="public">Public</option>
                      <option value="private">Private</option>
                    </Select>
                  </div>
                  <div>Who can follow you? Set to private if you want to approve followers before they can follow you.</div>
                </Row>
                <Row>
                  <div>
                    <Select value={this.state.socialSettings.a || 'private'} onChange={(e) => { this.state.socialSettings.a = e.target.value; this.forceUpdate(); }}>
                      <option value="public">Anyone</option>
                      <option value="followers">Followers</option>
                      <option value="private">Only You</option>
                    </Select>
                  </div>
                  <div>Who can see your answers? Set to followers if you only want your followers to see your answers.  Set to private if you don't want anyone to see what you are answering.</div>
                </Row>
              </Section>}
              <Section>
                <Title>Security Settings</Title>
                <Row>
                  <div>
                    <ClickableIcon onClick={this.disableLoggedoutTreeCreation} icon={(!account.privacySettings || !account.privacySettings.disableLoggedoutTreeCreation)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Allow Logged Out Submissions</div>
                  </div>
                  <div>This allows submissions while logged out simply by submitting email. Disable to require being logged in to submit content.</div>
                </Row>
                <Row>
                  <div>
                    <ClickableIcon onClick={this.disableLoggedoutCollectionSubscription} icon={(!account.privacySettings || !account.privacySettings.disableLoggedoutCollectionSubscription)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Allow Logged Out Collection Subscriptions</div>
                  </div>
                  <div>This allows collection subscriptions while logged out simply by submitting email. Disable to require being logged in to subscribe.</div>
                </Row>
              </Section>
              <Section>
                <Title>Multi-Factor Authentication</Title>
                {(account.multiFactorAuthentication && account.multiFactorAuthentication.verified) ? (
                  <Row>
                    <div>Currently Enabled</div>
                    <Button onClick={this.disableMFA}>Disable MFA</Button>
                  </Row>
                ) : (mfaAuthUrl) ? (
                  <QRCodeDisplay>
                    <div>Scan the above code with Google Authenticator or Authy, and then copy the code and click verify.</div>
                    <QRCode value={mfaAuthUrl} />
                    <StatefulTextField
                      value={this.state.verifyCode}
                      onChange={(verifyCode) => { this.setState({ verifyCode }); }}
                      hideUnderline
                      placeholder="Enter Verification Code"
                      width={240}
                      centerText
                    />
                    <div>
                      <StatefulButton
                        noColor
                        text="Cancel"
                        onClick={() => this.setState({ mfaAuthUrl: false })}
                      />
                      <StatefulButton
                        noColor
                        text="Verify"
                        onClick={this.verifyMFA}
                        warning={mfaWarning}
                        success={success}
                      />
                    </div>
                  </QRCodeDisplay>
                ) : (
                  <Row>
                    <div>Currently Disabled</div>
                    <Button onClick={this.enableMFA}>Enable MFA</Button>
                  </Row>
                )}
              </Section>
              {/* <Section>
                <Title>Connected Accounts</Title>
                <Row>
                  <div>Facebook</div>
                  {!account.facebook.id && (NativeApp.native ? (
                    <Button onClick={this.connectFacebookNative}>{'Connect Account'}</Button>
                  ) : (
                    <FacebookProvider appId="388220501956504">
                      <Login
                        scope="email,public_profile"
                        onSuccess={this.connectFacebookMobile}
                        onResponse={this.connectFacebookMobile}
                        onError={this.connectFacebookMobile}
                      >
                        <ConnectAccount>{'Connect Account'}</ConnectAccount>
                      </Login>
                    </FacebookProvider>
                  ))}
                  {account.facebook.id && <div>Connected</div>}
                </Row>
              </Section> */}
              <Section>
                <Title>Email Settings</Title>
                <Row>
                  <div>
                    <ClickableIcon onClick={this.appProjectsCreated} icon={(account.notifications.email.appLoggedOutProjectCreated)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when you receive new submissions</div>
                  </div>
                </Row>
                <Row>
                  <div>
                    <ClickableIcon onClick={this.notifyInvitedEditor} icon={(account.notifications.email.notifyWhenInvitedEditor)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when you are invited as an editor to apps</div>
                  </div>
                </Row>
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newAppLike')} icon={(account.notifications.email.newAppLike)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when your apps are liked</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newAppPin')} icon={(account.notifications.email.newAppPin)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when your apps are pinned</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newAppCollection')} icon={(account.notifications.email.newAppCollection)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when your apps are added to collections</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newCollectionApp')} icon={(account.notifications.email.newCollectionApp)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when new apps are added to collections you are subscribed to</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newCollectionEditor')} icon={(account.notifications.email.newCollectionEditor)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when you are invited as an editor to collections</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newCollectionSubscriber')} icon={(account.notifications.email.newCollectionSubscriber)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when your collections are subscribed to</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newComment')} icon={(account.notifications.email.newComment)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when your apps have new comments</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newCommentReply')} icon={(account.notifications.email.newCommentReply)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when your comments are replied to</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newCommentMention')} icon={(account.notifications.email.newCommentMention)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when you are mentioned in comments</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newCommentLike')} icon={(account.notifications.email.newCommentLike)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when your comments are liked</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newFollower')} icon={(account.notifications.email.newFollower)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when you have new followers</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newFollowRequest')} icon={(account.notifications.email.newFollowRequest)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when you have new follow requests</div>
                  </div>
                </Row>}
                {isLogicWiki && <Row>
                  <div>
                    <ClickableIcon onClick={() => this.handleChange('newFollowRequestApproved')} icon={(account.notifications.email.newFollowRequestApproved)? 'fas fa-check-square' : 'far fa-square'} />
                    <div>Notify when your follow requests are approved</div>
                  </div>
                </Row>}
              </Section>
              <Section>
                <Title>Delete Account</Title>
                <Row>
                  <Button onClick={() => this.setState({ confirmDelete: true })}>Delete Account</Button>
                </Row>
              </Section>
              <div style={{ maxWidth: 400, width: '100%', marginTop: 50, display: 'flex', justifyContent: 'space-around' }}>
                <Button onClick={this.userAccountUpdate}>Reset</Button>
                <StatefulButton
                  text="Save"
                  onClick={this.saveProfile}
                  warning={warning}
                  success={success}
                />
              </div>
            </SettingsWrapper>
          </MaxWidth>
        </FullScreenVerticalCenterContent>
        <ConfirmDialog text="Are you sure you wish to disable two factor authentication?" description="Your account will no longer have the added security layer provided." open={!!confirmDisableMFA} onNo={this.cancelDisableMFA} onYes={this.disableMFA} />
        <ConfirmDialog text="Are you sure you wish to delete your account?" description="Your account will no longer be active and viewable by anyone.  You will have 30 days to restore your account and then it will be permanently deleted." open={!!confirmDelete} onNo={() => this.setState({ confirmDelete: false })} onYes={this.deleteAccount} />
      </Scrollable>
    );
  }
}
