import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import Connect from '../../services/Connect';
import Navigation from '../../services/Navigation';
import TreeCreateNode from '../../services/TreeCreateNode';
import RootOrBranchesBox from './RootOrBranchesBox';
import CreateChildren from './CreateChildren';
import { ROOT, BRANCHES, REPORT, ACTIONS, EMAIL } from '../../models/nodetypes';
import EditableDivWrapper from './EditableDivWrapper';
import ConnectAutoHeight from './ConnectAutoHeight';
import ClickableDiv from '../../components/ClickableDiv/index';
import ClickableIcon from '../../components/ClickableIcon/index';
import ShowNodeTypeWrapper from './ShowNodeTypeWrapper';
import ConfirmDialog from '../../components/ConfirmDialog';
import Scrollable from '../../components/Scrollable';
import { findDynamicSizingContent } from '../../models/nodetypes';
import WindowSize from '../../services/WindowSize';

Element.prototype.documentOffsetTop = function documentOffsetTop() {
  return this.offsetTop + (this.offsetParent ? this.offsetParent.documentOffsetTop() : 0);
};
// Element.prototype.scrollIntoViewCenter = function scrollIntoViewCenter() {
//   window.scrollTo({ top: this.documentOffsetTop() - (window.innerHeight / 2), behavior: 'smooth' });
// };
const DefaultCSS = styled.div``;
const Icon = styled.div`
  > div {
    display: flex;
    align-items: center;
    > div {
      text-decoration: underline;
    }
  }
  * {
    font-size: 0.75rem;
  }
`;
const AllowSavingProgress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  min-height: 60px;
  position: relative;
`;

export default class TreeAnswerBox extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.object,
    saveProgress: PropTypes.func,
    onScroll: PropTypes.func,
  }
  state = {
    keys: [],
    showSavePopup: false,
  }
  componentDidMount() {
    const { tree } = this.props;
    const { root } = tree;
    root.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
    Connect.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.tree !== prevProps.tree) {
      if (prevProps.tree && prevProps.tree.root) prevProps.tree.root.offStateUpdate(this);
      if (this.props.tree && this.props.tree.root) this.props.tree.root.onStateUpdate(this);
    }
  }
  componentWillUnmount() {
    const { tree } = this.props;
    const { root } = tree;
    root.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
    Connect.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  updateWidth = () => {
    this.forceUpdate();
  }
  onKeyDown = (e) => {
    this.state.keys.push(e.key);
    const { activeObject, activeObjectParent } = GrayOut;
    const { tree } = this.props;
    const { editing } = tree;
    if (!activeObject) return;
    const { isInsideChatbot, answersChecked, checked } = activeObject;
    const allowGoToNext = editing || !isInsideChatbot || (answersChecked && answersChecked.length > 0) || checked;
    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      if (allowGoToNext) GrayOut.goToNextQuestion(e.shiftKey);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      if (editing) TreeCreateNode.createLast();
      else if (allowGoToNext) {
        setTimeout(() => {
          GrayOut.goToNextQuestion();
        }, activeObject.updateRate);
      }
    } else if (editing && this.state.keys[0] === 'Control') {
      if (!activeObject || !activeObjectParent) return;
      if (this.state.keys[1] === 'ArrowUp') {
        activeObjectParent.moveChildUp(activeObject);
      } else if (this.state.keys[1] === 'ArrowDown') {
        activeObjectParent.moveChildDown(activeObject);
      }
    }
  }
  onKeyUp = (e) => {
    this.state.keys = this.state.keys.filter((key) => key !== e.key);
  }
  onRef = (e) => {
    GrayOut.addScrollableParent(e);
  }

  render() {
    const { editing } = GrayOut;
    const { scrollDisabled } = Connect;
    const { tree, saveProgress, onScroll } = this.props;
    const { isSavingProjectsAllowed, root } = tree;
    const { allowSavingProgress, maxWidth, padding } = root;
    if (!tree.root) return <h1>App is empty</h1>;

    if (editing) {
      return (
        <DefaultCSS>
          <Scrollable
            key={tree.root.key}
            scrollRef={this.onRef}
            tabIndex={-1}
            onKeyDown={this.onKeyDown}
            onKeyUp={this.onKeyUp}
            style={{
              position: 'absolute', top: 0, right: 0, bottom: 0, left: 0,
              paddingTop: '16px', paddingRight: '32px', paddingBottom: '16px', paddingLeft: Constants.TreeContentIconIndent,
              flex: 1,
            }}
            vertical
            showVertical
            rememberScrollPosition={`tree_editing_${tree._id}`}
          >
            <EditableDivWrapper key={tree.root.key} node={tree.root}>
              <ShowNodeTypeWrapper node={tree.root}>
                <RootOrBranchesBox key={tree.root.key} node={tree.root} placeholder={ROOT} />
              </ShowNodeTypeWrapper>
            </EditableDivWrapper>
            <EditableDivWrapper key={tree.branches.key} node={tree.branches}>
              <ShowNodeTypeWrapper node={tree.branches}>
                <RootOrBranchesBox key={tree.branches.key} node={tree.branches} placeholder={BRANCHES} />
              </ShowNodeTypeWrapper>
            </EditableDivWrapper>
            <EditableDivWrapper key={tree.report.key} node={tree.report}>
              <ShowNodeTypeWrapper node={tree.report}>
                <RootOrBranchesBox key={tree.report.key} node={tree.report} placeholder={REPORT} />
              </ShowNodeTypeWrapper>
            </EditableDivWrapper>
            {tree.email && <EditableDivWrapper key={tree.email.key} node={tree.email}>
              <ShowNodeTypeWrapper node={tree.email}>
                <RootOrBranchesBox key={tree.email.key} node={tree.email} placeholder={EMAIL} />
              </ShowNodeTypeWrapper>
            </EditableDivWrapper>}
            {tree.actions && <EditableDivWrapper key={tree.actions.key} node={tree.actions}>
              <ShowNodeTypeWrapper node={tree.actions}>
                <RootOrBranchesBox key={tree.actions.key} node={tree.actions} placeholder={ACTIONS} />
              </ShowNodeTypeWrapper>
            </EditableDivWrapper>}
            <div style={{ height: '40vh' }} />
          </Scrollable>
        </DefaultCSS>
      );
    }

    // Mobile zooming
    // const { fullscreenPane } = MobileTreePane;
    // transition: 1s all;
    // let extrascale = 1;
    // let translateX = '0px';
    // let translateY = '0px';
    // if (fullscreenPane) {
    //   const { x, y } = fullscreenPane.paneRef.getBoundingClientRect();
    //   extrascale = 2;
    //   translateX = `-${x - }px`;
    //   translateY = `-${y}px`;
    // }
    // const height = 800;
    // const width = 800;
    // const scale = window.innerWidth / width;
    // const offset = (mobile && -(width - window.innerWidth)) / 2 || 0;
    // const left = offset;
    // const right = offset;
    // const top = (window.innerHeight - height) / 2;
    // const bottom = top;
    // const transform = (mobile && `translateX(${translateX}) translateY(${translateY}) scale(${scale * extrascale})`);
    const { showSavePopup } = this.state;
    const paneStyle = {
      paddingTop: Constants.TreeContentHalfIndent,
      paddingBottom: Constants.TreeContentHalfIndent,
      paddingRight: Constants.TreeContentIconIndent,
    };

    let enableAutoResizing;
    if (Constants.isWidget && Navigation.currentSearch.autoresize !== 'false') {
      const dynamicSizingFound = findDynamicSizingContent(tree.root);
      enableAutoResizing = !dynamicSizingFound;
      if (!enableAutoResizing) Connect.setHeightFullscreen();
    }

    return (
      <DefaultCSS>
        <Scrollable
          key={tree.root.key}
          scrollRef={this.onRef}
          tabIndex={-1}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.onKeyUp}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }}
          vertical={!scrollDisabled}
          showVertical={!scrollDisabled}
          rememberScrollPosition={`tree_${tree._id}`}
          onScroll={onScroll}
          disableFlex={enableAutoResizing && scrollDisabled}
        >
          {showSavePopup && <ConfirmDialog open text="Save Progress" description={<><p>By clicking yes, your progress will be saved.</p><p>You will receive an email with instructions for how to continue editing.</p><p>Are you sure you wish to save progress at this time?</p></>} onNo={() => this.setState({ showSavePopup: false })} onYes={() => { this.setState({ showSavePopup: false }); saveProgress(); }} />}
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100%', ...paneStyle }}>
            {isSavingProjectsAllowed && allowSavingProgress && <AllowSavingProgress>
              <Icon>
                <ClickableDiv onClick={() => this.setState({ showSavePopup: true })}>
                  <ClickableIcon small icon="far fa-save" />
                  <div>Save progress</div>
                </ClickableDiv>
              </Icon>
            </AllowSavingProgress>}
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth, padding, margin: 'auto', flex: 1 }}>
              <CreateChildren key={tree.root.key} node={tree.root} />
            </div>
          </div>
          {enableAutoResizing && <ConnectAutoHeight />}
        </Scrollable>
      </DefaultCSS>
    );
  }
}
