/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import WindowSize from '../../services/WindowSize';
import Navigation from '../../services/Navigation';
import Storage from '../../services/Storage';
import DrawerService from '../../services/Drawer';
import Company from '../../services/Company';
import AssetCache from '../../services/cache/AssetCache';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import TreeCache from '../../services/cache/TreeCache';
import TeamCache from '../../services/cache/TeamCache';
import UserAccount from '../../services/UserAccount';
import Constants from '../../submodules/logictry_config/constants';
import Link from '../../components/Link/index';
import UsersBox from '../../components/UsersBox';
import CreateTeamPopup from '../../components/CreateTeamPopup';
import ConfirmDialog from '../../components/ConfirmDialog/index';
import FixedFullscreenDiv from '../../components/FixedFullscreenDiv';
import CancelAllTouchs from '../../utils/cancelalltouches';
import Scrollable from '../../components/Scrollable/index';
import Version from '../../version.json';

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  border-right: ${Constants.LightBorder};
  white-space: nowrap;
  transition: all 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: ${Constants.White};
  width: 240px;
  a {
    text-decoration: none;
  }
`;
const Content = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  div {
    word-wrap: break-word;
    white-space: break-spaces;
  }
  > i {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    cursor: pointer;
  }
  > div:nth-child(1) {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }
  > div:first-child {
    > div {
      display: flex;
      align-items: center;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      > div {
        font-size: 13px;
      }
    }
    i {
      font-size: 0.7rem;
      width: 14px;
      margin-right: 1rem;
      text-align: center;
      flex-shrink: 0;
    }
  }
`
const ClickToClose = styled(FixedFullscreenDiv)`
  z-index: 3;
`;
const Links = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 0.25rem;
  > * {
    margin-left: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: ${Constants.SmallFontSize};
  }
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  background-color: #dadce0;
  padding: 0px;
  flex-shrink: 0;
`;

export default class Drawer extends React.PureComponent {
  state = {
    ...Storage.state.drawerState,
  }
  componentDidMount() {
    UserAccount.onStateUpdate(this);
    DrawerService.onStateUpdate(this);
    AssetCache.onStateUpdate(this);
    SpreadsheetCache.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
    Company.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
    DrawerService.offStateUpdate(this);
    AssetCache.offStateUpdate(this);
    SpreadsheetCache.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
    Company.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  hideCreateTeam = () => this.setState({ showCreateTeam: false });
  render() {
    const { isDevAccount } = UserAccount;
    const { mobile } = WindowSize;
    const { showCreateTeam } = this.state;
    const mobileStyle = mobile && { position: 'fixed', zIndex: 3, top: 0, left: 0, bottom: 0} || {};
    const { companyTeams } = Company;
    return [
      (mobile) && (<ClickToClose ref={(e) => CancelAllTouchs(e, { onClick: () => DrawerService.toggle() })} key="drawer_click_to_close" />),
      <Wrapper key="drawer_content" style={mobileStyle}>
        <Scrollable
          vertical
          style={{ position: 'absolute', inset: 0 }}
        >
          <Content>
            <div>
              <div onClick={() => Navigation.push('/')}>
                <i style={{ fontSize: 12 }} className="fas fa-network-wired"></i><div>Apps</div>
              </div>
              <div onClick={() => Navigation.push('/?content=assets')}>
                <i style={{ fontSize: 12 }} className="fas fa-images"></i><div>Assets</div>
              </div>
              <div onClick={() => Navigation.push('/?content=spreadsheets')}>
                <i style={{ fontSize: 12 }} className="fas fa-table"></i><div>Spreadsheets</div>
              </div>
              <div onClick={() => Navigation.push('/?content=databases')}>
                <i style={{ fontSize: 12 }} className="fas fa-database"></i><div>Databases</div>
              </div>
              {isDevAccount && <div onClick={() => Navigation.push('/?content=links')}>
                <i style={{ fontSize: 12 }} className="fas fa-link"></i><div>Links</div>
              </div>}
              <div onClick={() => Navigation.push('/?content=submissions')}>
                <i style={{ fontSize: 10 }} className="fas fa-project-diagram"></i><div>Submissions</div>
              </div>
              {companyTeams && <>
                <div style={{ padding: 0 }}>
                  <Divider key="company_divider" />
                </div>
                <div onClick={() => this.setState({ showCreateTeam: true })}>
                  <i style={{ fontSize: 13 }} className="fas fa-plus"></i>
                  <div>Create Team</div>
                </div>
                {companyTeams.map(({ _id, longname }) => (
                  <div onClick={() => Navigation.push(`/teams/${_id}`)}>
                    <i style={{ fontSize: 11 }} className="fas fa-users"></i>
                    <div>{longname}</div>
                  </div>
                ))}
              </>}
            </div>
            <Divider key="company_divider" />
            <Links>
              <Link url={Navigation.tutorials}>Tutorials</Link>
              <Link url={Navigation.templates}>Templates</Link>
              {/* <Link url={Navigation.videos}>Videos</Link> */}
              <Link url={Navigation.support}>Support</Link>
              <Link url={Navigation.feedback}>Feedback</Link>
              <Link url={Navigation.legal}>Legal</Link>
              {isDevAccount && <div>v{Version.version}</div>}
            </Links>
          </Content>
        </Scrollable>
        {showCreateTeam && <CreateTeamPopup open={showCreateTeam} onClose={this.hideCreateTeam} />}
      </Wrapper>
    ];
  }
}
