/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SparkMD5 from 'spark-md5';
import Constants from '../../submodules/logictry_config/constants';
import ClickableIcon from '../ClickableIcon/index';
import ConfirmDialog from '../ConfirmDialog/index';
import CreateProject from '../../services/CreateProject';
import Server from '../../services/server';
import Popup from '../Popup/index';
import CopyHtmlToClipboardButton from '../CopyHtmlToClipboardButton';
import { validateUrl } from '../../utils/regex';

let videoType;
let fileExtension;
try {
  videoType = 'video/mp4'; //MediaRecorder.isTypeSupported('video/mp4') ? 'video/mp4' : 'video/webm';
  fileExtension = '.mp4'; //MediaRecorder.isTypeSupported('video/mp4') ? '.mp4' : '.webm';
} catch (e) {
  //
}

const VideoCamera = styled.div`
  position: absolute;
  > button:first-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  > div:last-child {
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`;
const RecordButton = styled.div`
  position: absolute;
  bottom: 10vh;
  left: calc(50vw - 40px);
  border: 8px solid rgba(255, 255, 255, 0.4);
  height: 80px;
  width: 80px;
  border-radius: 50%;
  > button {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    background: rgba(227, 73, 28, 1);
  }
`;
const RecordingText = styled.div`
  color: white;
`;
const CloseButton = styled.div`
  position: absolute;
  top: 0.25rem;
  right: 1rem;
  font-size: 2rem;
  color: white;
  cursor: pointer;
`;
const HoverDiv = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  :hover {
    div {
      opacity: 1 !Important;
    }
  }
`;
const ProfilePic = styled.div`
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: center;
  cursor: pointer;

  > * {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;
const DeleteButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  left: unset;
  color: ${Constants.White};
  opacity: 0;
`;
const UploadOverlay = styled.div`
  
`;
const DisableUploadOverlay = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const FileName = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0.5rem;
  > div {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const Iframe = styled.div`
  // transform: scale(0.25, 0.25);
  // transform-origin: top left;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  iframe {
    width: 100%;
    height: 100%;
  }
`;
const PopupWrapper = styled.div`
  position: fixed;
  top: 3rem;
  left: 3rem;
  width: calc(100% - 6rem);
  height: calc(100% - 6rem);
  ${Constants.MediaMobileAndTablet} {
    left: 1rem;
    width: calc(100% - 2rem);
    height: calc(100% - 4rem);
  }
  > *:first-child {
    height: 100%;
    width: 100%;
  }
  > div:last-child {
    position: fixed;
    top: 0rem;
    left: 0rem;
    right: 0rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
  }
`;

const origin = (window.logictry && window.logictry.origin) || window.location.origin;

export default class ChangeableImage extends React.PureComponent {
  static propTypes = {
    backgroundImage: PropTypes.string,
    backgroundColor: PropTypes.string,
    onUploadQueued: PropTypes.func,
    onUploadFinish: PropTypes.func,
    style: PropTypes.object,
    disableEditing: PropTypes.bool,
    hideUploadText: PropTypes.bool,
    allowDeleting: PropTypes.bool,
    onDelete: PropTypes.func,
    disableUpload: PropTypes.bool,
    s3Path: PropTypes.string,
    signingUrl: PropTypes.string,
    additionalQueryParams: PropTypes.object,
    fileType: PropTypes.string,
    onlyAllowImage: PropTypes.bool,
    queueUpload: PropTypes.bool,
  }
  constructor(props) {
    super();
    this.UploadText = props.onlyAllowImage ? 'Upload New Image' : 'Upload New File';
    this.state = {
      overlayText: this.UploadText,
      imagePreview: null,
      showOverlay: false,
      error: false,
      errorDescription: '',
      showVideoCamera: false,
      cameraState: 'unstarted',
      showAssetPopup: false,
      nodeRef: null,
    }
  }
  componentWillUnmount() {
    CreateProject.unqueueChangeableImage(this);
  }
  fileLoaded = false;
  showUploadDisabledPopup = () => {
    this.setState({ error: 'Upload disabled', errorDescription: 'You must be logged in', overlayText: this.UploadText, imagePreview: null, showOverlay: false });
  }
  videoStart = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ cameraState: 'started' });
    navigator.getUserMedia  = navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    this.videoCameraStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    this.videoCanvas.srcObject = this.videoCameraStream;
  }
  toggleRecording = () => {
    if (this.state.cameraState === 'started') {
      try {
        this.videoMediaRecorder = new MediaRecorder(this.videoCameraStream, { mimeType: videoType });
        this.videoBlobRecorded = [];

        // event : new recorded video blob available
        this.videoMediaRecorder.addEventListener('dataavailable', (e) => {
          this.videoBlobRecorded.push(e.data);
        });

        // event : recording stopped & all blobs sent
        this.videoMediaRecorder.addEventListener('stop', () => {
          // create local object URL from the recorded video blobs
          // const videoFile = URL.createObjectURL(new Blob(this.videoBlobRecorded, { type: videoType }));
          this.setState({ cameraState: 'started' });
          const dateNow = Date.now();
          const filename = `${dateNow}${fileExtension}`;
          const body = new File(this.videoBlobRecorded, filename, { type: videoType });
          this.__uploadFile(body);
        });

        // Start recording with each recorded blob having 1 second video
        this.videoMediaRecorder.start(1000);
        this.setState({ cameraState: 'recording' });
      } catch (e) {
        //
      }
    } else if (this.state.cameraState === 'recording') {
      this.videoMediaRecorder.stop();
      this.setState({ cameraState: 'stopping' });
    }
  }
  closeVideo = () => {
    if (!this.videoCameraStream) return;
    this.videoCameraStream.getTracks().forEach((track) => {
      track.stop();
    });
    this.setState({ cameraState: 'unstarted' });
  }
  uploadExistingFile = async (e) => {
    const { queueUpload, onUploadQueued } = this.props;
    const s = e.target.files[0];
    if (!s) return;

    // Show image preview
    if (s.type.includes('image')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (this.state.errorDescription) return;
        this.setState({
          imagePreview: (reader.result)
        });
      };
      reader.readAsDataURL(s);
    }

    if (queueUpload) {
      this.setState({
        overlayText: `${s.name}`,
        showOverlay: true,
      });
      CreateProject.queueChangeableImage(this);
      this.queuedFile = s;
      if (onUploadQueued) onUploadQueued(true);
    } else {
      this.setState({
        overlayText: `0%`,
        showOverlay: true,
      });
      this.__uploadFile(s);
    }
  }
  resumeUpload = (user) => {
    return new Promise((resolve) => {
      this.queuedResolve = resolve;
      this.queuedUser = user;
      this.__uploadFile(this.queuedFile);
      this.queuedFile = null;
      this.queuedNext = null;
    });
  }
  getSignedUrl = async (file, callback) => {
    let { s3Path, signingUrl, additionalQueryParams } = this.props;
    if (this.queuedUser) s3Path = s3Path.replace('loggedout/', `${this.queuedUser}/`);
    let uri = `${origin}${signingUrl}?objectName=${this.hashFilename}/${file.name}&contentType=${file.type}&path=${s3Path}`;
    Object.keys(additionalQueryParams || {}).forEach((key) => {
      uri = `${uri}&${key}=${additionalQueryParams[key]}`;
    });
    if (this.queuedUser) uri = `${uri}&owner=${this.queuedUser}`;
    const result = await Server.__request({
      uri,
      method: 'GET',
      json: true,
    });
    if (!result) return;
    callback(result.body);
  }
  __uploadFile(s) {
    if (s.size > 4000000) return this.setState({ error: 'Upload Failed', errorDescription: 'File size too large (4 MB max size)', overlayText: this.UploadText, imagePreview: null, showOverlay: false });
    // Converted hashing to use binary chunks instead of one large string
    let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
      file = s,
      chunkSize = 2097152,
      chunks = Math.ceil(file.size / chunkSize),
      currentChunk = 0,
      spark = new SparkMD5.ArrayBuffer(),
      fileReader = new FileReader();
    fileReader.onload = (e) => {
      spark.append(e.target.result);
      currentChunk++;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        this.hashFilename = spark.end();
        this.uploadFile(s);
      }
    };
    fileReader.onerror = () => {
      this.setState({ error: 'Upload Failed', errorDescription: 'Something went wrong, please try again.', overlayText: this.UploadText, imagePreview: null, showOverlay: false });
    };
    function loadNext() {
      var start = currentChunk * chunkSize,
          end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    return loadNext();
  }
  uploadFile = (s) => {
    this.getSignedUrl({ name: s.name, type: s.type }, (url) => {
      if (url && url.signedUrl) {
        const request = new XMLHttpRequest();
        request.open("PUT", url.signedUrl, true);
        request.setRequestHeader('Content-Disposition', 'inline');
        request.setRequestHeader('Content-Type', s.type);
        request.upload.addEventListener("progress", (evt) => {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            this.setState({
              overlayText: `${Math.round(percentComplete*100)}%`,
              showOverlay: true,
            });
          }
        }, false);
        request.onreadystatechange = (evt) => {
          if (evt.target.readyState === 4) {
            this.onUploadFinish(url);
          }
        }
        request.send(s);
      } else {
        this.onUploadFinish(url);
      }
    });
  }
  onUploadFinish = (results) => {
    if (results.error) return this.setState({ error: 'Already exists', errorDescription: 'This asset has already been uploaded', overlayText: this.UploadText, showOverlay: false, imagePreview: null });
    this.setState({
      overlayText: this.UploadText,
      showOverlay: false,
      imagePreview: null,
    });
    this.closeVideo();
    this.props.onUploadFinish(results);
    if (this.queuedResolve) {
      this.queuedResolve();
      this.queuedResolve = null;
      this.queuedFile = null;
      this.queuedNext = null;
      this.queuedUser = null;
    }
  }
  clearQueuedFile = () => {
    const { onUploadQueued } = this.props;
    CreateProject.unqueueChangeableImage(this);
    if (onUploadQueued) onUploadQueued(false);
    this.queuedResolve = null;
    this.queuedFile = null;
    this.queuedNext = null;
    this.queuedUser = null;
    this.setState({
      overlayText: this.UploadText,
      showOverlay: false,
      imagePreview: null
    });
  }
  clearError = () => this.setState({ error: null, errorDescription: null });
  render() {
    const { disableEditing, allowDeleting, onDelete, backgroundColor, disableUpload, fileType, onlyAllowImage, hideUploadText } = this.props;
    let { backgroundImage } = this.props;
    const { imagePreview, showVideoCamera, cameraState, showAssetPopup, nodeRef, showOverlay } = this.state;
    const style = this.props.style || {};
    const small = style.height && style.height < 100;
    const isApp = fileType === 'folder';
    backgroundImage = backgroundImage && !validateUrl(backgroundImage) && backgroundImage;
    let name = backgroundImage && backgroundImage.split('/').slice(-1) || '';
    if (name) {
      if (isApp) {
        const date = (new Date(parseInt(name)));
        if (!isNaN(name)) name = date.toLocaleString().replace(',', '\r\n');
      }
      else name = `${name}${fileType ? ` (${fileType})` : ''}`;
    }
    const isPDF = backgroundImage && ['.pdf'].find((ext) => backgroundImage.toLowerCase().endsWith(ext));
    const isHTML = backgroundImage && ['.html'].find((ext) => backgroundImage.toLowerCase().endsWith(ext));
    const isImage = fileType && fileType.includes('image/') || backgroundImage && ['.jpg', '.png', '.jpeg', '.svg', '.webp', '.avif'].find((ext) => backgroundImage.toLowerCase().endsWith(ext));
    const isVideo = fileType && fileType.includes('video/') || backgroundImage && ['.mp4', '.webm', '.mov'].find((ext) => backgroundImage.toLowerCase().endsWith(ext));
    const isIframeable = isApp || isPDF || isHTML;
    const showBackgroundText = backgroundImage && !onlyAllowImage && !isImage && !isVideo;
    const assetUrl = backgroundImage && (isApp ? `${backgroundImage}/index.html` : backgroundImage);
    const openAsset = () => {
      if (!(assetUrl && disableEditing)) return;
      return this.setState({ showAssetPopup: true });
    };
    return (
      <HoverDiv style={{ pointerEvents: (showOverlay && !this.queuedFile) ? 'none': null, backgroundColor: disableEditing && showBackgroundText && (backgroundColor || Constants.PrimaryColor) || null, borderRadius: style.borderRadius }}>
        {(small || isApp || isHTML || (showBackgroundText && !isIframeable)) && (
          <FileName style={{ justifyContent: small ? null : 'center', color: small ? Constants.DarkTextColor : null, left: small ? 40 : 0, right: small ? 70 : 0, whiteSpace: small ? 'nowrap' : null }}>
            <div style={{ whiteSpace: (isApp && !small) ? 'break-spaces' : null }}>{name}</div>
          </FileName>
        ) || (showBackgroundText && <div onClick={openAsset}><Iframe><iframe src={assetUrl}></iframe></Iframe></div>)}
        <div onClick={openAsset}>
          <ProfilePic style={{ display: 'block', backgroundSize: style.backgroundSize || 'contain', backgroundPosition: style.backgroundPosition, backgroundRepeat: style.backgroundRepeat, border: style.border, borderRadius: style.borderRadius, width: style.width || style.height, height: style.height, lineHeight: `${style.height}px`, position: 'relative', backgroundImage: ((imagePreview && `url(${imagePreview})`) || isImage && `${this.props.backgroundImage && !showBackgroundText ? `url(${this.props.backgroundImage})` : ''}`) }}>
            {assetUrl && isVideo && (
              <div style={{ pointerEvents: 'none', position: 'absolute', inset: 0, width: '100%', height: '100%', opacity: 1 }}>
                <video playsInline muted width="100%" height="100%">
                  <source src={`${assetUrl}#t=0.1`} />
                </video>
              </div>
            )}
            {!disableEditing && (showVideoCamera && (
              <VideoCamera style={{ opacity: 1 }}>
                <button style={{ visibility: cameraState === 'unstarted' ? null : 'hidden' }} ref={(e) => { this.cameraStartButton = e; }} onClick={this.videoStart}></button>
                <div style={{ visibility: cameraState === 'unstarted' ? 'hidden' : null, pointerEvents: cameraState === 'unstarted' ? 'none' : null }}>
                  <video
                    ref={(e) => { this.videoCanvas = e; }}
                    autoPlay
                    muted
                    playsInline
                  ></video>
                  <RecordButton
                    ref={(e) => { this.cameraStartRecording = e; }}
                    onClick={this.toggleRecording}
                  >
                    <button></button>
                  </RecordButton>
                  <RecordingText></RecordingText>
                  <CloseButton onClick={this.closeVideo}>
                    <i className="fas fa-times" />
                  </CloseButton>
                </div>
              </VideoCamera>
            ) || (
              <div>
                <label
                  style= {{ position: 'absolute', inset: 0, width: '100%', height: '100%', cursor: 'pointer' }}>
                  <input
                    type="file"
                    accept={onlyAllowImage ? 'image/*' : '*'}
                    onChange={this.uploadExistingFile}
                    style={{ position: 'absolute', inset: 0, width: '100%', height: '100%', opacity: 0, pointerEvents: 'none' }}
                  />
                </label>
              </div>
            ))}
            {disableEditing && small && showBackgroundText && (
              <div style={{ pointerEvents: 'none', opacity: 1, lineHeight: `${style.height}px`, cursor: 'pointer', fontSize: Constants.SmallFontSize }}>
                <i className="fas fa-images"></i>
              </div>
            )}
            {!disableEditing && (small ? (
              <div style={{ pointerEvents: 'none', opacity: 1, lineHeight: `${style.height}px`, cursor: 'pointer' }}>
                <i className="fas fa-upload"></i>
                {showOverlay && <UploadOverlay style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, color: (backgroundImage || showOverlay) ? 'white' : null, backgroundColor: (backgroundImage || showOverlay) ? 'rgba(0,0,0,0.7)' : null, pointerEvents: 'none', opacity: `${!backgroundImage || showOverlay ? '1' : '0'}`, lineHeight: `${style.height}px` }}>{this.state.overlayText}</UploadOverlay>}
              </div>
            ) : (
              <UploadOverlay style={{ padding: '0.5rem', color: (backgroundImage || showOverlay) ? 'white' : null, backgroundColor: (backgroundImage || showOverlay) ? 'rgba(0,0,0,0.7)' : null, pointerEvents: 'none', opacity: `${!backgroundImage || showOverlay ? '1' : '0'}`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div>{this.state.overlayText}</div>
              </UploadOverlay>
            ))}
          </ProfilePic>
        </div>
        <ConfirmDialog
          open={!!(!disableEditing && this.state.error)}
          text={this.state.error || ''}
          description={this.state.errorDescription}
          onOk={this.clearError}
        />
        {allowDeleting && <DeleteButton><ClickableIcon onClick={onDelete} icon="fas fa-trash" /></DeleteButton>}
        {!hideUploadText && !disableEditing && small && <UploadOverlay style={{ marginLeft: '0.5rem' }}>{this.UploadText}</UploadOverlay>}
        {disableUpload && <DisableUploadOverlay onClick={this.showUploadDisabledPopup} />}
        {this.queuedFile && <DeleteButton><ClickableIcon onClick={this.clearQueuedFile} icon="fas fa-trash" /></DeleteButton>}
        {showAssetPopup && <Popup
          showCloseButton
          onClose={() => this.setState({ showAssetPopup: false })}
          allowBackgroundClose
        >
          <PopupWrapper>
            {isImage && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img style={{ maxWidth: '100%', maxHeight: '100%' }} src={assetUrl}></img></div>
              || isVideo && <video playsInline controls src={assetUrl}></video>
              || isIframeable && <iframe src={assetUrl}></iframe>
              || <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>Preview Unavailable</h1>}
            <div style={{ position: 'fixed', top: '-100vh' }} key={nodeRef} ref={(e) => { this.setState({ nodeRef: e }); }}>{assetUrl}</div>
            <div>
              <CopyHtmlToClipboardButton target={nodeRef} text="Copy Link" copiedText="Copied!" />
            </div>
          </PopupWrapper>
        </Popup>}
      </HoverDiv>
    );
  }
}
