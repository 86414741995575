/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import SidebarService from '../../services/SidebarService';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import ClickableIcon from '../../components/ClickableIcon';
import ClickableDiv from '../../components/ClickableDiv/index';
import VersioningList from '../../components/VersioningList/index';
import SpreadsheetSettings from '../../components/ObjectSettings/SpreadsheetSettings';

const Wrapper = styled.div`
  position: fixed;
  right: 0px;
  background-color: ${Constants.White};
  font-size: ${Constants.SemiLargeFontSize};
  * {
    font-size: ${Constants.SemiSmallFontSize};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border-top-left-radius: 8px;
  box-shadow: ${Constants.BoxShadow};
  // border-left: 1px solid ${Constants.DarkGray};
  overflow-y: auto;

  > div {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  h3 {
    text-align: center;
  }
`;
const AnswerButtons = styled.div`
  display: flex;
  width: 100%;
  > * {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  * {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const AccessStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0px 0.25rem 0px;
  > i {
    margin-right: 10px;
  }
`;
const Divider = styled.div`
  width: 100%;
  background-color: rgb(242, 242, 242);
  margin: 20px 0px;
  text-align: center;
`;

export default class Sidebar extends React.PureComponent {
  static propTypes = {
    spreadsheet: PropTypes.object,
    saveChanges: PropTypes.func,
    checkout: PropTypes.func,
    checkin: PropTypes.func,
    showEditor: PropTypes.bool,
  };
  state = {
    showVersions: false,
  }
  componentDidMount() {
    const { spreadsheet } = this.props;
    spreadsheet.onStateUpdate(this);
    SpreadsheetCache.onStateUpdate(this);
    SidebarService.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { spreadsheet } = this.props;
    spreadsheet.offStateUpdate(this);
    SpreadsheetCache.offStateUpdate(this);
    SidebarService.offStateUpdate(this);
  }
  getUserFullname = (users, userId) => {
    if (!users || !userId) return '';
    const foundUser = users.find((_user) => _user._id === userId);
    return foundUser ? foundUser.fullname : '';
  }
  getAnswerButtons = () => {
    const { showWysiwyg } = SidebarService;
    const { checkout, checkin, spreadsheet } = this.props;
    const { isCheckoutAllowed, isCheckedOut, isCheckinAllowed, isOwnerOrAdmin, _id } = spreadsheet;
    const answerButtons = [];
    if (isOwnerOrAdmin && !isCheckedOut && isCheckoutAllowed) answerButtons.push(<ClickableDiv key="checkout_button" onClick={checkout}><i style={{ height: 14, width: 16 }} className="fa fa-lock" /></ClickableDiv>);
    if (isOwnerOrAdmin && isCheckedOut && isCheckinAllowed) answerButtons.push(<ClickableDiv key="checkin_button" onClick={checkin}><i style={{ height: 14, width: 16 }} className="fa fa-unlock" /></ClickableDiv>);
    answerButtons.push(<div key="show_html"><ClickableIcon grayedOut={!showWysiwyg} onClick={() => SidebarService.updateShowWysiwyg(!showWysiwyg)} icon="fab fa-html5" /></div>);
    if (_id) answerButtons.push(<div key="show_versions"><ClickableIcon grayedOut={true} onClick={this.toggleVersions} icon="fas fa-code-branch" /></div>);
    answerButtons.push(<div key="settings"><SpreadsheetSettings object={spreadsheet} /></div>);
    return answerButtons;
  }
  getVersionContent = () => {
    const { spreadsheet } = this.props;
    const { version, sheetDiffs, currentVersionDisplayed } = spreadsheet;
    if (!sheetDiffs) return null;
    const diffs = sheetDiffs[0];
    if (!version || !diffs) return null;
    return [
      <Divider key="versions_divider">Versions</Divider>,
      <VersioningList
        key="versioning_list"
        node={spreadsheet}
        diffs={diffs}
        version={version}
        currentVersionDisplayed={currentVersionDisplayed}
        showVersion={this.showVersion}
        diffCache={SpreadsheetCache.diffCache}
      />
    ]
  }
  showVersion = (version, deltas, index) => {
    const { spreadsheet } = this.props;
    spreadsheet.showVersion(version, deltas.slice(0, index));
  }
  toggleVersions = () => {
    const { showVersions } = this.state;
    this.setState({ showVersions: !showVersions });
  }
  render() {
    const { showWysiwyg } = SidebarService;
    const { showEditor, saveChanges, checkout, checkin, spreadsheet } = this.props;
    const { showVersions } = this.state;
    const { isLocked, isOwnerOrAdmin, isCheckedOut, isCheckinAllowed, isCheckoutAllowed, currentVersionDisplayed, isEditor } = spreadsheet;
    const width = 40;
    const borderBottomLeftRadius = 8;
    const borderTopLeftRadius = 8;
    const overflowY = 'hidden';
    const buttons = this.getAnswerButtons();
    const top = `calc(50vh - ${25 * buttons.length}px)`;
    const height = `${50 * buttons.length}px`;
    if (isLocked) return null;
    if (showVersions) {
      return (<Wrapper style={{ top: Constants.DesktopHeaderHeight, width: 240, height: `calc(100vh - ${Constants.DesktopHeaderHeight})` }}>
        <div>
          {isOwnerOrAdmin && isCheckedOut && isCheckinAllowed && <AccessStatus key="checkin" role="button" onClick={checkin}><i className="fa fa-unlock" /><div>Finish Editing</div></AccessStatus>}
          {isOwnerOrAdmin && isCheckedOut && !isCheckinAllowed && <AccessStatus key="checkedout"><i className="fa fa-lock" /><div>Already In-use</div></AccessStatus>}
          {isOwnerOrAdmin && !isCheckedOut && isCheckoutAllowed && <AccessStatus key="checkout" role="button" onClick={checkout}><i className="fa fa-lock" /><div>Start Editing</div></AccessStatus>}
          {!isOwnerOrAdmin && <div style={{ textAlign: 'center', padding: 10 }}>You dont have permission to edit</div>}
          <AnswerButtons style={{ marginTop: 10, flexDirection: 'row' }}>
            <div><ClickableIcon grayedOut={false} onClick={this.toggleVersions} icon="fas fa-code-branch" /></div>
            <div><ClickableIcon grayedOut={!showWysiwyg} onClick={() => SidebarService.updateShowWysiwyg(!showWysiwyg)} icon="fab fa-html5" /></div>
            <ClickableDiv onClick={saveChanges}><ClickableIcon disabled={!isEditor || !currentVersionDisplayed} icon="fas fa-save" /></ClickableDiv>
          </AnswerButtons>
          {this.getVersionContent()}
          <div style={{ flex: 1, height: 40 }}></div>
        </div>
      </Wrapper>);
    }
    return (
      <Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
        <div style={{ padding: 0 }}>
          <Buttons style={{ marginTop: 0, flexDirection: showEditor ? 'row' : 'column' }}>
            {buttons}
          </Buttons>
        </div>
      </Wrapper>
    );
  }
}
