import Model from './model';

export default class LogicBaseAppPin extends Model {
  constructor(_id, follower, app) {
    super();
    this._id = _id;
    this.follower = follower;
    this.app = app;
  }
  getJson = () => {
    const { _id, follower, app } = this;
    return {
      _id,
      fr: follower,
      a: app,
    }
  }
}
