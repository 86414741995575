/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import TreeDisplay from '../../services/TreeDisplay';
import { DESCRIPTION, TITLE } from '../../models/nodetypes';

const Wrapper = styled.div`
  ${Constants.MediaTabletAndDesktop} {
    padding-right: 40px;
  }
`;
const InputsWrapper = styled.div`
  margin: 0.5rem 0px;
  display: flex;
  flex-direction: column;
  text-align: left;
  > * {
    padding: 0px;
  }
  > *:nth-child(1) {
    width: 100%;
    font-size: ${Constants.SemiSmallFontSize};
    max-height: 21px;
    overflow: hidden;
  }
  > *:nth-child(2) {
    width: 100%;
    font-size: ${Constants.SmallFontSize};
    max-height: 18px;
    overflow: hidden;
  }
`;

export default class TreeMetaBox extends React.PureComponent {
  static propTypes = {
    editing: PropTypes.bool,
  }
  componentDidMount() {
    TreeDisplay.onStateUpdate(this.checkFocus);
    this.checkFocus();
  }
  componentWillUnmount() {
    TreeDisplay.offStateUpdate(this.checkFocus);
  }
  changeTitle = (value) => {
    const { tree } = TreeDisplay;
    tree.title.updateText(value);
  }
  changeDescription = (value) => {
    const { tree } = TreeDisplay;
    tree.description.updateText(value);
  }
  onFocus = (node) => {
    TreeDisplay.addActiveObject(node);
  }
  checkFocus = () => {
    const { tree } = TreeDisplay;
    if (this.titleRef && TreeDisplay.isActive(tree.title)) {
      this.titleRef.focus({ preventScroll: true });
    } else if (this.descriptionRef && TreeDisplay.isActive(tree.description)) {
      this.descriptionRef.focus({ preventScroll: true });
    }
  }
  onBlur = (node) => {
    TreeDisplay.removeActiveObject(node);
  }

  render() {
    const { tree } = TreeDisplay;
    const { editing } = this.props;
    const { project, title, description } = tree;
    if (!editing) {
      const titleParam = Navigation.getQueryParameter('title');
      const descriptionParam = Navigation.getQueryParameter('description');
      if (titleParam) title.text = titleParam;
      if (descriptionParam) description.text = descriptionParam;
    }
    return (
      <Wrapper>
        {(project || editing) ? (
          <InputsWrapper>
            <input key={title.key} tabIndex={0} ref={(e) => { this.titleRef = e; }} onFocus={() => this.onFocus(title)} onChange={(e) => { this.changeTitle(e.target.value); }} onBlur={() => this.onBlur(title)} placeholder={title && title.placeholderText ? title.placeholderText.text : TITLE} defaultValue={title ? title.text : ''} />
            <input key={description.key} tabIndex={0} ref={(e) => { this.descriptionRef = e; }} onFocus={() => this.onFocus(description)} onChange={(e) => { this.changeDescription(e.target.value); }} onBlur={() => this.onBlur(description)} placeholder={description && description.placeholderText ? description.placeholderText.text : DESCRIPTION} defaultValue={description ? description.text : ''} />
          </InputsWrapper>
        ) : (
          (title || description) && (<InputsWrapper>
            {title && <div>{title.text}</div>}
            {description && <div>{description.text}</div>}
          </InputsWrapper>)
        )}
      </Wrapper>
    );
  }
}

