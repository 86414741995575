/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import Navigation from '../../services/Navigation';
import StatefulButton from '../../components/StatefulButton';

export default class LoginToViewPage extends React.PureComponent {
  render() {
    return (
      <FullScreenVerticalCenterContent>
        <h1 style={{ textAlign: 'center', marginBottom: '2rem' }}>You must login to view</h1>
        <StatefulButton text="Go to Login" onClick={() => Navigation.push(Navigation.login)} />
      </FullScreenVerticalCenterContent>
    );
  }
}
