import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import User from '../../models/user';

const Button = styled.div`
  display: flex;
  justify-content: center;
`;
const ProfileAvatar = styled.div`
  position: relative;
  text-align: center;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  font-size: ${Constants.SemiLargeFontSize};
`;
const EmptyAvatar = styled.div`
  font-size: ${Constants.SmallFontSize};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #dbdbdb;
`;


export default class UserAvatar extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    onClick: PropTypes.func,
    buttonRef: PropTypes.func,
    showShadow: PropTypes.bool,
    mini: PropTypes.bool,
    backgroundColor: PropTypes.string,
  }

  render() {
    const { user, buttonRef, onClick, showShadow, mini } = this.props;
    const profileSize = mini ? 22 : 32;
    const backgroundImage = user && (new User(user)).getImage();
    let initials = '';
    if (user && user.fullname) initials = user.fullname.split(' ').map((_string) => _string.charAt(0)).slice(0, 3).join('');
    const boxShadow = showShadow ? 'unset' : 'unset';
    return (
      <Button
        ref={buttonRef}
        onClick={onClick}
        style={{ cursor: onClick ? 'pointer' : null }}
      >
        {backgroundImage ? (
          <ProfileAvatar style={{ width: profileSize, height: profileSize, fontSize: profileSize, backgroundImage: `url("${backgroundImage}")`, backgroundColor: `${backgroundImage ? null : Constants.White}`, color: `${backgroundImage ? null : Constants.DarkGray}`, boxShadow }} />
        ) : (
          <EmptyAvatar style={{ width: profileSize, height: profileSize, fontWeight: 'bold', boxShadow }}>{initials}</EmptyAvatar>
        )}
      </Button>
    );
  }

}
