import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
// import AthletaAutofill from '../../Mindmaps/AthletaAutofill';
import SpreadsheetWidget from '../../components/SpreadsheetWidget';
import WindowSize from '../../services/WindowSize';
import Navigation from '../../services/Navigation';
import SidebarService from '../../services/SidebarService';
import AutoSaveSpreadsheet from '../../services/AutoSaveSpreadsheet';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import UserCache from '../../services/cache/UserCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import LoadingIndicator from '../../components/LoadingIndicator';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import Sidebar from './Sidebar';
import MetaBox from './MetaBox';

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Wrapper = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  overflow: hidden;
  // padding: 20px;
  font-size: ${Constants.SmallFontSize};
`;
const SpreadsheetWrapper = styled.div`
  input {
    width: 100% !Important;
    height: 100% !Important;
    text-align: left !Important;
    padding: 4px 8px !Important;
  }
`;
const EditingDisabled = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  color: white;
  background-color: rgba(0,0,0,0.08);
  > div {
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 4px 0px;
    background-color: ${Constants.PrimaryColor};
    position: absolute;
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 2rem;
    line-height: 2rem;
    > div {
      color: white;
      font-weight: bold;
      font-size: ${Constants.SemiSmallFontSize};
    }
  }
`;


export default class SpreadsheetPage extends React.PureComponent {
  state = {
    grid: [],
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    SpreadsheetCache.onStateUpdate(this.spreadsheetCacheUpdate);
    this.spreadsheetCacheUpdate();
    UserCache.onStateUpdate(this);
    SidebarService.onStateUpdate(this);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    SpreadsheetCache.offStateUpdate(this.spreadsheetCacheUpdate);
    UserCache.offStateUpdate(this);
    SidebarService.offStateUpdate(this);
    if (this.spreadsheet && this.spreadsheet._id) {
      this.spreadsheet.offStateUpdate(this.spreadsheetUpdate);
      this.spreadsheet.title.offStateUpdate(this.spreadsheetUpdate);
      this.spreadsheet.description.offStateUpdate(this.spreadsheetUpdate);
      this.spreadsheet.sheets[0].offStateUpdate(this.sheetUpdate);
    }
    this.spreadsheet = null;
  }

  onChange = (changes) => {
    if (!this.spreadsheet || !this.spreadsheet.sheets) return;
    let { grid } = this.state;
    grid = grid.map((row) => [...row]);
    changes.forEach(({ row, col, value }, i) => { // eslint-disable-line
      // Autoexpand rows
      if (row > (grid.length - 1)) {
        for (let j = grid.length - 1; j < row; j += 1) {
          grid.push([]);
        }
      }

      // Autoexpand columns
      if (col > (grid[row].length - 1)) {
        grid[row] = [...grid[row], ...(new Array(col - (grid[row].length - 1))).fill({ value: '' })];
      }

      if (!grid[row] && !value) return;
      if (!grid[row]) grid[row] = [];
      grid[row][col] = { ...grid[row][col], value };
    });
    grid.forEach((row, i) => {
      if (row.length !== grid[i].length) grid[i] = [...row, ...(new Array(grid[i].length - row.length)).fill({ value: '' })];
    });
    this.spreadsheet.sheets[0].updateData(grid);
    this.setState({ grid });
  }
  createColumn = (column, count) => {
    const { grid } = this.state;
    if (!grid[0]) grid[0] = [];
    for (let i = 0; i < count; i += 1) {
      if (column >= 0) grid.forEach((_row) => _row.splice(column, 0, { value: '' }));
      else grid.forEach((_row) => _row.push({ value: '' }));
    }
    this.spreadsheet.sheets[0].updateData(grid);
    this.forceUpdate();
  }
  createRow = (row, count) => {
    const { grid } = this.state;
    for (let i = 0; i < count; i += 1) {
      if (row >= 0) grid.splice(row, 0, []);
      else grid.push([]);
    }
    this.spreadsheet.sheets[0].updateData(grid);
    this.forceUpdate();
  }
  deleteColumn = (_update, startColumn, colCount) => {
    const { grid } = this.state;
    if (!grid[0]) return;
    if (startColumn >= 0) grid.forEach((_row) => _row.splice(startColumn, colCount));
    if (_update) {
      this.spreadsheet.sheets[0].updateData(grid);
      this.forceUpdate();
    }
  }
  deleteRow = (_update, startRow, rowCount) => {
    const { grid } = this.state;
    if (startRow >= 0) grid.splice(startRow, rowCount);
    if (_update) {
      this.spreadsheet.sheets[0].updateData(grid);
      this.forceUpdate();
    }
  }
  spreadsheetCacheUpdate = () => {
    this.state.grid = [];
    this.spreadsheetId = Navigation.currentLocation[2];
    this.spreadsheet = SpreadsheetCache.get(this.spreadsheetId);
    if (!this.spreadsheet || !this.spreadsheet.sheets) return this.forceUpdate();
    this.sheetUpdate();
    this.spreadsheetUpdate();
    this.spreadsheet.onStateUpdate(this.spreadsheetUpdate);
    this.spreadsheet.title.onStateUpdate(this.spreadsheetUpdate);
    this.spreadsheet.description.onStateUpdate(this.spreadsheetUpdate);
    return this.spreadsheet.sheets[0].onStateUpdate(this.sheetUpdate);
  }
  spreadsheetUpdate = () => {
    this.autoSaveSpreadsheet();
  }
  sheetUpdate = () => {
    this.state.grid = [];
    this.spreadsheet.sheets[0].data.forEach((row, i) => {
      if (!this.state.grid[i]) this.state.grid[i] = [];
      row.forEach((val, j) => {
        this.state.grid[i][j] = { value: val.value || '' };
      });
    });
    this.state.grid.forEach((row, i) => {
      if (row.length !== this.state.grid[i].length) this.state.grid[i] = [...row, ...(new Array(this.state.grid[i].length - row.length)).fill({ value: '' })];
    });
    return this.forceUpdate();
  }
  save = () => {
    AutoSaveSpreadsheet.save(this.spreadsheet);
  }
  autoSaveSpreadsheet = () => {
    AutoSaveSpreadsheet.autoSave(this.spreadsheet);
  }
  checkout = () => {
    this.spreadsheet = SpreadsheetCache.get(Navigation.currentLocation[2], true);
  }
  checkin = async () => {
    const id = Navigation.currentLocation[2];
    const updated = await AutoSaveSpreadsheet.save(this.spreadsheet);
    if (updated) return;
    this.spreadsheet = SpreadsheetCache.get(id, null, true);
  }
  changeTitle = (value) => {
    const { spreadsheet } = this;
    spreadsheet.title.updateText(value);
    this.forceUpdate();
  }
  changeDescription = (value) => {
    const { spreadsheet } = this;
    spreadsheet.description.updateText(value);
    this.forceUpdate();
  }

  render() {
    const { mobile } = WindowSize;
    const { spreadsheet } = this;
    if (!spreadsheet) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (spreadsheet.error) return <FullScreenVerticalCenterContent><h1>{`Spreadsheet Error: ${spreadsheet.error}`}</h1></FullScreenVerticalCenterContent>;
    const { isEditor, isOwnerOrAdmin, key, isLocked, isCheckedOut, editor, isShowingAPreviousVersion } = spreadsheet;
    let checkedOutTo;
    if (!isLocked && isCheckedOut && !isEditor) {
      const editors = UserCache.getUsersByIds([editor._id]);
      checkedOutTo = editors && editors[0];
    }
    const showAlreadyCheckedOut = isOwnerOrAdmin && !isLocked && isCheckedOut && !isEditor;
    const showNotCurrentlyEditing = isOwnerOrAdmin && !isLocked && !isCheckedOut && !isEditor;
    const showIsLocked = isOwnerOrAdmin && isLocked;
    const showNotAllowedToEdit = !isOwnerOrAdmin;
    const showDeleted = spreadsheet.isDeleted;
    return (
      <OuterWrapper>
        <DrawerBreadcrumb><MetaBox spreadsheet={spreadsheet} /></DrawerBreadcrumb>
        <Wrapper>
          <SpreadsheetWrapper key={key}>
            <SpreadsheetWidget
              key={key}
              data={this.state.grid}
              onCellsChanged={this.onChange}
              spreadsheetKey={key}
              onFinishEditing={() => this.forceUpdate()}
              onCreateRow={(row, count) => this.createRow(row, count)}
              onCreateColumn={(col, count) => this.createColumn(col, count)}
              onDeleteRow={(startRow, endRow) => this.deleteRow(true, startRow, endRow)}
              onDeleteColumn={(startColumn, endColumn) => this.deleteColumn(true, startColumn, endColumn)}
              allowEditing={isEditor && (!showAlreadyCheckedOut && !showNotCurrentlyEditing && !showIsLocked && !showNotAllowedToEdit && !isShowingAPreviousVersion)}
              showRowsAndCols
            />
          </SpreadsheetWrapper>
        </Wrapper>
        {showDeleted && <EditingDisabled><div>Spreadsheet has been deleted</div></EditingDisabled>
        || showAlreadyCheckedOut && <EditingDisabled><div><div>Spreadsheet already being edited - {checkedOutTo ? `by ${checkedOutTo.fullname || checkedOutTo.username}` : 'by someone else'}</div></div></EditingDisabled>
        || showNotCurrentlyEditing && <EditingDisabled><div><div>Not currently editable - click start editing to begin</div></div></EditingDisabled>
        || showIsLocked && <EditingDisabled><div><div>Spreadsheet locked - unlock to edit</div></div></EditingDisabled>
        || showNotAllowedToEdit && <EditingDisabled><div><div>Not allowed to edit - must be an owner or admin to edit</div></div></EditingDisabled>
        || isShowingAPreviousVersion && <EditingDisabled><div><div>Auto saving disabled - you must click save to make this version the current version</div></div></EditingDisabled>}
        {isOwnerOrAdmin && !mobile && <Sidebar key={spreadsheet.key} createColumn={this.createColumn} createRow={this.createRow} spreadsheet={spreadsheet} saveChanges={this.save} checkout={this.checkout} checkin={this.checkin} />}
      </OuterWrapper>
    );
  }
}
