import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Popup from '../Popup/index';
import Button from '../Button/index';
import { validateEmail } from '../../utils/regex';
import LogicBaseCollectionSubscriptionApi from '../../services/server/LogicBaseCollectionSubscriptionApi';

const Wrapper = styled.form`
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 2rem 3rem;
  max-width: 500px;
  h1 {
    text-align: center;
  }
  label {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0.25rem 0;
  }
  input {
    margin-right: 8px;
  }
  h2 {
    margin-top: 2rem;
    font-size: 1rem;
  }
  code {
    font-size: 0.8rem;
  }
  details {
    margin-top: 2rem;
  }
  summary {
    font-size: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default class InviteSubscribers extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    collection: PropTypes.object,
  }
  inviteAll = () => {
    const { collection, onClose } = this.props;
    const emails = Array.from(new Set(document.getElementById('invitesubscribers__emails').value.split(',').map((e) => e.trim())))
    emails.forEach((email) => {
      if (validateEmail(email)) return;
      const newSubscription = { email, c: collection._id };
      LogicBaseCollectionSubscriptionApi.create(newSubscription);
    });
    onClose();
  }
  render() {
    const { onClose } = this.props;
    return (
      <div>
        <Popup
          onClose={onClose}
          allowBackgroundClose
          showCloseButton
        >
          <Wrapper>
            <h1>Invite Subscribers</h1>
            <p>Provide a list of comma seperated email addresses only (e.g. "email1@gmail.com, email2@gmail.com, ...")</p>
            <textarea
              id="invitesubscribers__emails" maxLength={1000}
              style={{ width: '100%', border: '1px solid', minHeight: 200, padding: 12 }}
            />
            <ButtonsWrapper>
              <Button onClick={this.close} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={this.inviteAll} color="primary" autoFocus>
                Invite All
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </Popup>
      </div>
    );
  }
}
