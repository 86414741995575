import Service from './Service';
import UserAccount from './UserAccount';
import Constants from '../submodules/logictry_config/constants';

let _previousLoggedIn;
let _beforeLoginLocation;

class Navigation extends Service {
  pageRef;
  // The Links
  get about() { return '/about'; }
  get account() { return '/account'; }
  get accountBusiness() { return '/account/business'; }
  get accountContacts() { return '/account/contacts'; }
  get accountNotifications() { return '/account/notifications'; }
  get accountSettings() { return '/account/settings'; }
  get activities() { return '/activities'; }
  get ambassadors() { return '/ambassadors'; }
  get analytics() { return '/analytics'; }
  get browse() { return '/browse'; }
  get businesses() { return '/businesses'; }
  get careers() { return '/careers'; }
  get careerPosition() { return '/careers'; }
  get caseStudies() { return '/case-studies'; }
  get collections() { return '/collections'; }
  get companySettings() { return '/company/settings'; }
  get companies() { return '/companies'; }
  get contactus() { return '/contact-us'; }
  get databases() { return '/databases'; }
  get databaseCollections() { return '/databasecollections'; }
  get departments() { return '/departments'; }
  get documentation() { return '/documentation'; }
  get events() { return '/events'; }
  get feedback() { return '/feedback' }
  get teams() { return '/teams'; }
  get home() {
    if (Constants.isIphone) return '/search';
    return '/';
  }
  get industries() { return '/industries'; }
  get invite() { return '/invite'; }
  get legal() { return '/legal' }
  get local() { return '/local'; }
  get login() { return '/login'; }
  get news() { return '/news'; }
  get apps() { return '/apps'; }
  get onepager() { return '/one-pager'; }
  get partners() { return '/partners'; }
  get platform() { return '/platform'; }
  get pricing() { return '/pricing'; }
  get sitenav() { return '/sitenav'; }
  get services() { return '/services'; }
  get solutions() { return '/solutions'; }
  get spreadsheets() { return '/spreadsheets'; }
  get submissions() { return '/submissions'; }
  get support() { return '/support'; }
  get register() { return '/register'; }
  get resetPassword() { return '/reset-password'; }
  get team() { return '/team'; }
  get templates() { return '/templates' };
  get tutorials() { return '/tutorials' };
  userProfile(user) {
    if (Constants.isIphone) return `/users/${user._id}`;
    return `/@${user.username || user._id}`;
  }
  get users() { return '/users'; }
  get versions() { return '/versions'; }
  get videos() { return '/videos'; }
  get welcome() { return '/welcome' };
  navigate(url, opt) {
    if (Constants.isIphone) {
      const currentPage = this.currentLocation[1];
      const page = url.split('/')[1];
      const isTabPage = ['search','collections','users','account'].includes(page);
      const tab = document.getElementById(`tab-button-${page}`);
      if (isTabPage && tab && !url.split('/')[2]) {
        tab.click();
        if (currentPage !== page) setTimeout(() => {
          tab.click();
        }, 500);
      } else if (isTabPage && tab && currentPage !== page) {
        tab.click();
        if (url !== `/${page}`) {
          setTimeout(() => {
            if (opt && opt.replace) this.history.replace(url, opt && opt.state);
            else this.history.push(url, opt && opt.state);
          }, 500);
        }
      } else {
        if (opt && opt.replace) this.history.replace(url, opt && opt.state);
        else this.history.push(url, opt && opt.state);
      }
    } else {
      this.useNavigate(url, opt);
    }
    this.emitStateUpdate();
  }
  get currentLocation() {
    return window.location.pathname.split('/');
  }
  get currentSearch() {
    const currentSearch = new URLSearchParams(window.location.search);
    const newSearch = {};
    for (const [key, value] of currentSearch) {
      newSearch[key.toLowerCase()] = value;
    }
    return newSearch;
  }
  getQueryParameter(param) {
    if (!param) return null;
    const queryParameters = this.currentSearch;
    if (!Object.hasOwnProperty.call(queryParameters, param)) return null;
    const value = queryParameters[param];
    delete queryParameters[param];
    this.navigate(`${window.location.pathname}?${encodeQueryData(queryParameters)}`, { replace: true });
    return value;
  }
  addOrReplaceQueryParam(param, value) {
    const queryParameters = this.currentSearch;
    if (value) queryParameters[param] = value;
    else delete queryParameters[param];
    this.navigate(`${window.location.pathname}?${encodeQueryData(queryParameters)}`);
  }
  clearQueryParameters() {
    if (!window.location.search) return;
    this.navigate(`${window.location.pathname}`, { replace: true });
  }

  back() {
    this.navigate(-1);
  }

  appstore() {
    // window.location = 'https://itunes.apple.com/app/id1441014538';
  }

  push(_location, _state) {
    if (this.__openNewTab || Constants.isWidget) return this.site(_location);

    if (_location === `${window.location.pathname}${window.location.search}` && JSON.stringify(this.location.state || null) === JSON.stringify(_state || null)) {
      if (Constants.isIphone) {
        window.logictry.scrollTop();
      }
      this.emitStateUpdate('SCROLL_TOP');
      return;
    }

    // If loggedIn dont push these sites
    if ((_location === this.login || _location === this.register || _location === this.resetPassword) && UserAccount.isLoggedIn) return;

    // Return to location after login
    if ((_location === this.login || _location === this.register) && (this.currentLocation[1] !== 'login' && this.currentLocation[1] !== 'register')) _beforeLoginLocation = `${window.location.pathname}${window.location.search}`;

    // Push if web but replace if native
    this.navigate(_location, { state: _state });
  }
  replace(_location) {
    this.navigate(_location, { replace: true });
  }
  site(_site) {
    if (!_site) return null;
    if (_site.indexOf('mailto:') >= 0) {
      window.location = _site;
      return null;
    }
    const win = window.open(_site, '_blank');
    return win;
  }
  search(_query) {
    let query = '/browse?';
    if (_query) Object.keys(_query).forEach((key) => { query = query.concat(`${key}=${_query[key]}&`); });
    query = query.slice(0, -1);
    this.push(query);
  }
}

const singleton = new Navigation();
UserAccount.onStateUpdate(() => {
  /**
   * After login, redirect user:
   * 1. Back to where they were
   * 2. To home page
   */
  const loggedOutOnlyPages = ['login', 'register', 'reset-password', 'contact-us'];
  if ((loggedOutOnlyPages.includes(singleton.currentLocation[1])) && !_previousLoggedIn && UserAccount.isLoggedIn) {
    if (_beforeLoginLocation && loggedOutOnlyPages.every((a) => !_beforeLoginLocation.includes(a))) {
      singleton.push(_beforeLoginLocation);
    }
  }
  /**
   * After logout, redirect user:
   * 1. To home page
   */
  if (UserAccount.account.error === 'UserNotFound') {
    singleton.push(singleton.register);
  } else if (_previousLoggedIn && !UserAccount.isLoggedIn) {
    window.location = `${window.location.origin}`;
  }
  _previousLoggedIn = UserAccount.isLoggedIn;
});
export default singleton;

function encodeQueryData(data) {
  let encodedString = '';
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (encodedString.length > 0) {
        encodedString += '&';
      }
      encodedString += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }
  }
  return encodedString;
}
