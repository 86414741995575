import Service from '../Service';
import TreeApi from '../server/TreeApi';
import Tree, { TreeJsonToTreeText } from '../../models/tree';
import UserAccount, { LOGGED_IN, LOGGED_OUT } from '../UserAccount';
import DiffCache from './DiffCache';
import EstimateObjectSize from '../../utils/estimateobjectsize';
// eslint-disable-next-line import/no-cycle
import Company from '../Company';
import Constants from '../../submodules/logictry_config/constants';
import { diff } from 'jsondiffpatch';
import AppConstants from '../../submodules/logictry_config/s3_config.json';


class TreeCache extends Service {
  initialize() {
    /**
     * Object keys are the treeId
     * Object values are the treeJSON
     */
    this.treesJson = {};
    /**
     * Object keys are the page
     * Object values are ids of trees
     */
    this.accountTrees = {};
    /**
     * Object keys are the treeId
     * Object values are the treeModel
     */
    this.trees = {};
    /**
     * Object keys are the treeId
     * Object values are the treeModel
     */
    this.templatesAsProjects = {};
    /**
     * Updates Active
     */
    this.updatesActive = new Set();
    /**
     * Updates Queued
     */
    this.updatesQueued = {};
    /**
     * Diff Cache
     */
    this.diffCache = new DiffCache(AppConstants.S3.signingUrls.templateversions, AppConstants.S3.buckets[Constants.environment].templateversions);
    this.diffCache.__treeCacheReferenceForDebugging = this;
    this.diffCache.onUpdateDiffs = this.updateDiffs.bind(this);
  }
  createNew = (text) => new Tree(true, 'Template', { _id: UserAccount.account._id }, UserAccount.account._id, [], [], UserAccount.account._id, { text }, null, null, null, null, null, null, null, null, null, null, null, Company.company && Company.company._id || '');
  checkNewerObject = (_object) => {
    if (!this.treesJson[_object._id]) return true;
    if (!this.trees[_object._id]) return true;
    if (!this.treesJson[_object._id].version) return true;
    return _object.version > this.treesJson[_object._id].version;
  }
  createFromJson(json) {
    this.treesJson[json._id] = json;
    this.trees[json._id] = this.__deleteExistingAndCreateNewObjectFromJson(json, false);
    return this.trees[json._id];
  }
  createObject = (_results) => {
    const { isDevAccount } = UserAccount;
    const results = JSON.parse(JSON.stringify(_results));
    return new Tree(results.editing, results.type, results.editor, results.owner, results.users, results.teams, results.userLoggedIn, results.children, results._id, results.referenceTree, results.createdTime, results.updateTime, results.parentTree, results.state, results.diffs, results.version, results.shareSetting, results.embedSetting, results.password, results.company, results.background, results.customSource, isDevAccount, results.progress);
  }
  revertTree = (_tree) => {
    const referenceTreeJson = this.treesJson[_tree.referenceTree];
    if (!referenceTreeJson || referenceTreeJson.error) {
      const results = this.treesJson[_tree._id];
      this.trees[_tree._id] = this.__deleteExistingAndCreateNewObjectFromJson(results, results.type === 'Template');
    } else {
      this.__deleteIfExisting(_tree._id);
      this.trees[_tree._id] = this.__deleteExistingAndCreateNewObjectFromJson(referenceTreeJson, false, true);
      const results = this.treesJson[_tree._id];
      this.__addTreeAnswers(this.trees[_tree._id], results);   
    }
    this.emitStateUpdate();
  }
  duplicateTree = (_treeModel) => {
    if (_treeModel.type === 'Project') return null;
    const _tree = _treeModel.getTreeTemplateJson();
    _tree.children.forEach((c) => {
      if (c.attributes[0] !== 'Title') return;
      c.text = `Copy of ${c.text}`;
    })
    return this.__deleteExistingAndCreateNewObjectFromJson({ type: _treeModel.type, owner: UserAccount.account._id, company: Company.company && Company.company._id || '', userLoggedIn: UserAccount.account._id, children: _tree.children, shareSetting: _treeModel.shareSetting }, true);
  }
  // checkVersion(id) {
  //   TreeApi.getTree(id, { version: true }).then(async (results) => {
  //     console.log('version', id, results);
  //   });
  // }
  // isIdInvalid = (id) => id.length > 28 || id.length < 20;
  getTree(id, _editing, _checkout, _checkin, _password, _apiKey) {
    // Dont run if in transition
    if (![LOGGED_IN, LOGGED_OUT].includes(UserAccount.state)) return null;
    if (!id) return null;
    if (id && this.isIdInvalid && this.isIdInvalid(id)) return { error: 'IdInvalid' };
    /**
     * Showing the template should be done
     */
    if (!_checkout && !_checkin && this.trees[id]) {
      if (this.trees[id].error) return this.trees[id];
      if (this.trees[id].type === 'Project') {
        if (this.trees[id].answersAdded) return this.trees[id];
        const referenceTreeJson = this.treesJson[this.trees[id].referenceTree];
        const results = this.treesJson[id];
        if (referenceTreeJson && referenceTreeJson.error) {
          // Create tree without referenceTree
          this.trees[id] = this.__deleteExistingAndCreateNewObjectFromJson(results, false);
          return this.trees[id];
        }
        if (referenceTreeJson && referenceTreeJson._id) {
          const { diffsNeeded, totalDiffs } = this.__checkIfDIffsNeeded(results, referenceTreeJson);
          if (diffsNeeded && !totalDiffs) {
            setTimeout(async () => {
              const totalDiffs = await this.__loadCorrectVersion(results, referenceTreeJson);
              this.__deleteIfExisting(id);
              this.trees[id] = this.__deleteExistingAndCreateNewObjectFromJson(referenceTreeJson, false, true);
              if (totalDiffs) this.trees[id].diffs = totalDiffs;
              this.__addTreeAnswers(this.trees[id], results);
              this.emitStateUpdate();
            });
            return null;
          } else {
            this.__deleteIfExisting(id);
            this.trees[id] = this.__deleteExistingAndCreateNewObjectFromJson(referenceTreeJson, false, true);
            if (totalDiffs) this.trees[id].diffs = totalDiffs;
            this.__addTreeAnswers(this.trees[id], results);
            return this.trees[id];
          }
        }
      } else if (this.trees[id].type === 'Template') {
        if (_editing) return this.trees[id];
        const existingProject = this.templatesAsProjects[id];
        if (existingProject && existingProject.__initialTemplateString === this.trees[id].getTreeTemplateText()) {
          return existingProject;
        }
        const project = this.createObject({ ...this.trees[id].getTreeTemplateJson(), children: { text: this.trees[id].getTreeTemplateText() }, editing: false, userLoggedIn: UserAccount.account._id });
        this.templatesAsProjects[id] = project;
        return project;
      }
    }
    /**
     * Update the tree
     */
    const query = {};
    if (_checkout) query.checkout = true;
    if (_checkin) query.checkin = true;
    if (_password) query.password = _password;
    if (_apiKey) query.apikey = _apiKey;
    TreeApi.getTree(id, query).then(async (results) => {
      if (!results) return;
      if (results.error) {
        if ((results.error === 'CheckoutFailed' || results.error === 'CheckinFailed') && this.trees[id] && this.trees[id]._id) {
          this.revertTree(this.trees[id]);
          if (this.onUpdateFail) this.onUpdateFail(results);
        } else {
          this.trees[id] = results;
        }
      } else if ((_checkin || _checkout) && results.success) {
        this.treesJson[id].editor = results.editor;
        this.trees[id].editor = results.editor;
      } else if (results.type === 'Project' && results.referenceTree) {
        this.treesJson[id] = results;
        /**
         * Detect if it is a project with a reference tree to overlay answers
         */
        this.trees[id] = { loading: true };
        const referenceTreeJson = await TreeApi.getTree(results.referenceTree);
        if (!referenceTreeJson) return;
        if (referenceTreeJson && referenceTreeJson.error) {
          // Create tree without referenceTree
          this.trees[id] = this.__deleteExistingAndCreateNewObjectFromJson(results, false);
          this.treesJson[results.referenceTree] = referenceTreeJson;
        } else if (referenceTreeJson && referenceTreeJson._id) {
          this.treesJson[results.referenceTree] = referenceTreeJson;
          const totalDiffs = await this.__loadCorrectVersion(results, referenceTreeJson);
          this.__deleteIfExisting(id);
          this.trees[id] = this.__deleteExistingAndCreateNewObjectFromJson(referenceTreeJson, false, true);
          if (totalDiffs) this.trees[id].diffs = totalDiffs;
          this.__addTreeAnswers(this.trees[id], results);
        }
      } else if (results.type === 'Template') {
        this.treesJson[results._id] = results;
        this.trees[results._id] = this.__deleteExistingAndCreateNewObjectFromJson(results, true);
        // Check the Diff Paging
        this.__checkDiffPaging(this.trees[results._id]);
      }
      this.emitStateUpdate();
    });
    return null;
  }
  __checkIfDIffsNeeded(project, template) {
    let totalDiffs = [];
    const referenceVersion = project.reference && project.reference.trees && project.referenceTree && project.reference.trees.find(({ _id }) => _id === project.referenceTree);
    if (!referenceVersion || !referenceVersion.version) return { diffsNeeded: false };
    const projectReferenceTreeVersion = referenceVersion.version;
    let referenceTreeVersion = template.version;
    totalDiffs = [...template.diffs];
    let keepSearching = projectReferenceTreeVersion < (referenceTreeVersion - totalDiffs.length);
    while (keepSearching) {
      const nextDiffs = this.diffCache.getDiffs(template._id, referenceTreeVersion - totalDiffs.length);
      if (nextDiffs) totalDiffs = [...totalDiffs, ...nextDiffs];
      keepSearching = nextDiffs && nextDiffs.length > 0 && (projectReferenceTreeVersion < (referenceTreeVersion - totalDiffs.length));
    }
    if (projectReferenceTreeVersion < (referenceTreeVersion - totalDiffs.length)) return { diffsNeeded: true };
    return { diffsNeeded: true, totalDiffs };
  }
  async __loadCorrectVersion(project, template) {
    let totalDiffs = [];
    const referenceVersion = project.reference && project.reference.trees && project.referenceTree && project.reference.trees.find(({ _id }) => _id === project.referenceTree);
    if (!referenceVersion || !referenceVersion.version) return null;
    const projectReferenceTreeVersion = referenceVersion.version;
    let referenceTreeVersion = template.version;
    totalDiffs = [...template.diffs];
    let keepSearching = projectReferenceTreeVersion < (referenceTreeVersion - totalDiffs.length);
    while (keepSearching) {
      const nextDiffs = await this.diffCache.getDiffsSync(template._id, referenceTreeVersion - totalDiffs.length);
      if (nextDiffs) totalDiffs = [...totalDiffs, ...nextDiffs];
      keepSearching = nextDiffs && (projectReferenceTreeVersion < (referenceTreeVersion - totalDiffs.length));
    }
    if (projectReferenceTreeVersion < (referenceTreeVersion - totalDiffs.length)) return null;
    return totalDiffs;
  }
  /**
   * Get all the trees associated with account for following cases:
   *  - Templates vs projects
   *  - Owner vs contributor
   */
  query(_query) {
    // Dont run if in transition
    if (![LOGGED_IN, LOGGED_OUT].includes(UserAccount.state)) return null;
    const _queryString = JSON.stringify(_query);
    if (this.accountTrees[_queryString]) {
      /**
       * Return the trees only if all have been found
       */
      const trees = this.accountTrees[_queryString].map((_id) => this.trees[_id]);
      if (trees.every((_tree) => _tree)) return trees;
    }
    /**
     * Get the account trees if not found
     */
    TreeApi.getAccountTrees(_query).then(async (results) => {
      /**
       * Need to handle error cases
       */
      if (!results) return;
      if (!results.forEach) return;
      /**
       * Parse the account trees
       */
      this.accountTrees[_queryString] = [];
      for (let i = 0; i < results.length; i += 1) {
        const _tree = results[i];
        this.accountTrees[_queryString].push(_tree._id);
        if (this.checkNewerObject(_tree)) {
          const id = _tree._id;
          this.treesJson[id] = _tree;
          const editing = _tree.type === 'Template';

          // If the template exists, use that to create
          const referenceTreeJson = this.treesJson[_tree.referenceTree];
          if (referenceTreeJson && referenceTreeJson._id) {
            this.trees[id] = { loading: true };
            const totalDiffs = await this.__loadCorrectVersion(_tree, referenceTreeJson);
            this.__deleteIfExisting(id);
            this.trees[id] = this.__deleteExistingAndCreateNewObjectFromJson(referenceTreeJson, editing, true);
            if (totalDiffs) this.trees[id].diffs = totalDiffs;
            this.__addTreeAnswers(this.trees[id], _tree);
          } else {
            this.trees[id] = this.__deleteExistingAndCreateNewObjectFromJson(_tree, editing);
          }
        }
      }
      /**
       * Emit state update
       */
      this.emitStateUpdate();
    });
    return null;
  }
  getByIds(_ids) {
    const idsToFetch = [];
    const objects = [];
    _ids.forEach((_id) => {
      const object = this.trees[_id];
      if (object) objects.push(object);
      else idsToFetch.push(_id);
    });
    if (idsToFetch.length === 0) return objects;
    TreeApi.getAccountTrees({ _ids: idsToFetch }).then(async (results) => {
      if (!results) return;
      if (!results.forEach) return;
      idsToFetch.forEach((_id) => {
        const result = results.find((result) => result._id === _id);
        if (result) {
          this.treesJson[_id] = result;
          this.trees[_id] = this.__deleteExistingAndCreateNewObjectFromJson(result);
        } else {
          this.treesJson[_id] = { error: 'NotFound' };
          this.trees[_id] = { error: 'NotFound' };
        }
      });
      this.emitStateUpdate();
    });
    return null;
  }
  /**
   * Create tree
   */
  createTree(_treeModel, _subtree, _team, _steps) {
    let _tree;
    if (_treeModel.type === 'Project') return;
    let deleteTemplateAsProject;
    if (_treeModel.type === 'Template' && _treeModel._id) {
      if (!_treeModel.isSavingProjectsAllowed) return;
      _tree = _treeModel.getTreeProjectJson(_subtree, null, true);
      if (_tree.email && window.logictry && window.logictry.company) {
        _tree.email.from = (window.logictry.company.longname || window.logictry.company.shortname) || '';
        _tree.email.subdomain = window.logictry.company.shortname || '';
      }
      deleteTemplateAsProject = _treeModel._id;
    }
    else _tree = _treeModel.getTreeTemplateJson();

    if (!_tree) return;
    if (_tree._id) {
      this.treesJson[_tree._id] = { status: 'creating' };
      this.trees[_tree._id] = { status: 'creating' };
    }
    if (_tree.type === 'Template') _tree.children = { text: TreeJsonToTreeText(_tree) }; // eslint-disable-line
    if (_tree.type === 'Project') {
      if (window.logictry && window.logictry.company && window.logictry.company._id) _tree.company = window.logictry.company._id;
      _tree.teams = _tree.teams.filter(({ _id }) => _id === _team);
    }
    if (this.onCreate) this.onCreate(_treeModel);

    if (_steps) _tree.steps = _steps;
    TreeApi.createTree(_tree).then((results) => {
      if (!results || !results._id || results.error) {
        if (this.onCreateFail) this.onCreateFail(results);
        return this.emitStateUpdate();
      }
      this.accountTrees = {};
      this.treesJson[results._id] = results;
      this.trees[results._id] = this.__deleteExistingAndCreateNewObjectFromJson(results, results.type === 'Template');
      if (this.onCreateSuccess) this.onCreateSuccess(results);
      if (deleteTemplateAsProject) {
        setTimeout(() => {
          const existing = this.templatesAsProjects[deleteTemplateAsProject];
          if (existing) {
            existing.destroy();
            delete this.templatesAsProjects[deleteTemplateAsProject];
          }
        });
      }
      return this.emitStateUpdate();
    });
  }
  /**
   * Save tree
   */
  async updateTree(_treeModel, newState) {
    // Check if currently updating
    if (this.updatesActive.has(_treeModel._id)) {
      // eslint-disable-next-line prefer-rest-params
      this.updatesQueued[_treeModel._id] = [_treeModel, newState];
      return false;
    }
    this.updatesActive.add(_treeModel._id);

    // Get the JSON object
    let updatesToSend;
    let updatesToSyncLocally;
    if (_treeModel.type === 'Project') updatesToSend = _treeModel.getTreeProjectJson(null, null, true);
    else updatesToSend = _treeModel.getTreeTemplateJson();

    if (!updatesToSend) {
      this.updatesActive.delete(_treeModel._id);
      return false;
    }
    if (newState) {
      const userLoggedIn = UserAccount.account._id;
      if (updatesToSend.owner === userLoggedIn) {
        // eslint-disable-next-line no-param-reassign
        updatesToSend.state = newState;
        // eslint-disable-next-line no-param-reassign
        _treeModel.state = newState;
      }
    }

    // Check for changes based on type
    if (_treeModel.type === 'Template') updatesToSend.children = { text: TreeJsonToTreeText(updatesToSend) };

    // Filter out all the keys that have not changed
    Object.keys(updatesToSend).forEach((_key) => {
      if (['_id', 'version'].includes(_key)) return;
      if (JSON.stringify(updatesToSend[_key]) !== JSON.stringify(this.treesJson[updatesToSend._id][_key])) return;
      delete updatesToSend[_key];
    });

    // Attempt to create version if a template
    if (updatesToSend.children) {
      _treeModel.createVersion();

      // If its a template, then check versioning and diffs
      if (_treeModel.type === 'Template') {
        if (updatesToSend.version === _treeModel.version) {
          // If version not incremented, remove children
          delete updatesToSend.children;
        } else {
          // If version is incrememented, check the diff paging
          const results = await this.diffCache.checkDiffPaging(_treeModel._id, _treeModel.version, _treeModel.diffs, true);
          // If no results, then the update failed and dont save
          if (!results) {
            if (this.onUpdateFail) this.onUpdateFail();
            this.updatesActive.delete(_treeModel._id);
            return false;
          }
          // Sync the diffs
          const { keep, page } = results;
          // eslint-disable-next-line no-param-reassign
          _treeModel.diffs = keep;
          updatesToSend.diffs = JSON.parse(JSON.stringify(keep));

          // If there is not paging, reduce the payload
          if (!page || page.length === 0) {
            updatesToSyncLocally = JSON.parse(JSON.stringify(updatesToSend));

            updatesToSend.newDiff = keep[0];
            if (updatesToSend.newDiff && updatesToSend.newDiff.delta) {
              delete updatesToSend.children;
            }
            delete updatesToSend.diffs;
          }
        }
      } else if (_treeModel.type === 'Project') {
        if (updatesToSend.version === _treeModel.version) {
          // If version not incremented, remove children
          delete updatesToSend.children;
        } else {
          const delta = diff(this.treesJson[updatesToSend._id].children, updatesToSend.children);
          if (EstimateObjectSize(delta) < EstimateObjectSize(updatesToSend.children)) {
            updatesToSyncLocally = JSON.parse(JSON.stringify(updatesToSend));
            const newDiff = { version: updatesToSend.version, delta, owner: updatesToSend.owner, createdTime: (new Date()) };
            delete updatesToSend.children;
            updatesToSend.newDiff = newDiff;
          }
        }
      }

      updatesToSend.version = _treeModel.version;
      if (updatesToSyncLocally) updatesToSyncLocally.version = _treeModel.version;
    }

    // Dont update if no changes
    if (Object.keys(updatesToSend).every((k) => ['_id', 'version', 'reference'].includes(k))) {
      this.updatesActive.delete(_treeModel._id);
      if (_treeModel.isTemplateAndShowingAPreviousVersion) this.revertTree(_treeModel);
      return false;
    }

    // Project save protections for reference assets
    if (_treeModel.type === 'Project') {
      const treeJson = this.treesJson[_treeModel._id];

      // Add protection to not update if the reference tree version is changed
      if (updatesToSend.children || updatesToSend.newDiff) {
        if (treeJson.reference) {
          const reference = treeJson.reference.trees.find(({ _id }) => _id === treeJson.referenceTree);
          if (reference.version !== _treeModel.referenceTreeVersion) {
            this.updatesActive.delete(_treeModel._id);
            return false;
          }
        }
      }

      // Added protection to not remove reference spreadsheets once applied
      if (updatesToSend.reference && treeJson.reference) {
        treeJson.reference.spreadsheets.forEach((refSpread) => {
          const refIndex = updatesToSend.reference.spreadsheets.findIndex((refToSend) => refSpread._id === refToSend._id);
          if (refIndex >= 0) {
            updatesToSend.reference.spreadsheets[refIndex] = refSpread;
          } else {
            updatesToSend.reference.spreadsheets.push(refSpread);
          }
        });
        if (treeJson.reference.spreadsheets.length === updatesToSend.reference.spreadsheets.length) {
          delete updatesToSend.reference;
          if (updatesToSyncLocally) delete updatesToSyncLocally.reference;
        } else if (updatesToSyncLocally) {
          updatesToSyncLocally.reference = JSON.parse(JSON.stringify(updatesToSend.reference));
        }
      }
    }

    // Update
    if (this.onUpdate) this.onUpdate(_treeModel);
    TreeApi.updateTree(updatesToSend).then((results) => {
      // Remove the active update
      this.updatesActive.delete(_treeModel._id);

      // Check for a queuedUpdate
      const queuedUpdate = this.updatesQueued[_treeModel._id];
      if (queuedUpdate) {
        setTimeout(() => this.updateTree(...queuedUpdate));
        delete this.updatesQueued[_treeModel._id];
      }

      // Handle errors
      if (!results || !results.success || results.error) {
        if (results && results.error === 'IncorrectVersion') {
          this.treesJson[_treeModel._id] = results.latest;
        }
        this.revertTree(_treeModel);
        if (this.onUpdateFail) this.onUpdateFail(results);
        return this.emitStateUpdate();
      }

      // Update if changed
      const _tree = updatesToSyncLocally || updatesToSend;
      Object.keys(_tree).forEach((_key) => {
        this.treesJson[_tree._id][_key] = JSON.parse(JSON.stringify(_tree[_key]));
      });

      // Check the Diff Paging
      this.__checkDiffPaging(this.trees[_tree._id]);

      // Update state
      if (newState) this.accountTrees = {};
      if (this.onUpdateSuccess) this.onUpdateSuccess(_treeModel);
      return this.emitStateUpdate();
    });
    return true;
  }
  updateDiffs(_id, _diffs) {
    const tree = this.trees[_id];
    // eslint-disable-next-line no-param-reassign
    tree.diffs = _diffs;
    this.updateTree(tree);
  }
  /**
   * delete tree
   */
  deleteTree(_tree, _permanently) {
    if (this.onDelete) this.onDelete(_tree);
    TreeApi.deleteTree(_tree._id, _permanently).then((results) => {
      delete this.trees[_tree._id];
      delete this.treesJson[_tree._id];

      // Handle fail
      if (!results || !results.success) {
        if (this.onDeleteFail) this.onDeleteFail(results);
        return this.emitStateUpdate();
      }

      // Handle success
      this.accountTrees = {};
      if (this.onDeleteSuccess) this.onDeleteSuccess(_tree);
      return this.emitStateUpdate();
    });
  }
  __deleteExistingAndCreateNewObjectFromJson(json, editing, dontDeleteExisting) {
    // Delete if existing and then create new
    if (!dontDeleteExisting && json._id) this.__deleteIfExisting(json._id);
    return this.createObject({ ...json, editing, userLoggedIn: UserAccount.account._id });
  }
  __deleteIfExisting(_id) {
    if (!this.trees[_id] || !this.trees[_id].destroy) return;
    const existing = this.trees[_id];
    delete this.trees[_id];

    // Call destroy on the tree to remove all circular references
    setTimeout(() => {
      existing.destroy();
    });
  }
  __checkDiffPaging(tree) {
    // Check the Diff Paging
    if (tree.isOwnerOrAdmin && tree.type === 'Template' && tree.isEditor) this.diffCache.checkDiffPaging(tree._id, tree.version, tree.diffs);
  }
  __addTreeAnswers(tree, answers) {
    tree.addAnswers(JSON.parse(JSON.stringify(answers)));
  }
}

const singleton = new TreeCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
