/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import Popup from '../Popup/index';
import Constants from '../../submodules/logictry_config/constants';
import UserAccount from '../../services/UserAccount';
import Navigation from '../../services/Navigation';
import Company from '../../services/Company';
import Button from '../Button/index';

const Wrapper = styled.div`
  // display: flex;
  max-width: 600px;
  padding: 40px;

  a {
    color: ${Constants.PrimaryColor};
  }
  button
`;
const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0px;
  input {
    cursor: pointer;
    margin-right: 10px;
  }
`
const AcceptButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const IDontAgree = styled(AcceptButton)`
  div {
    color: ${Constants.PrimaryColor};
    font-size: ${Constants.SemiSmallFontSize};
    text-decoration: underline;
  }
`;

class LegalPopup extends React.PureComponent {
  state = {
    termsChecked: false,
  }
  componentDidMount() {
    UserAccount.onStateUpdate(this);
    Navigation.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
    Navigation.offStateUpdate(this);
  }
  accept = () => {
    const { account } = UserAccount;
    UserAccount.updateUserProfile({
      _id: account._id,
      legal: {
        terms: new Date(),
        privacy: new Date(),
        cookies: {
          necessary: new Date(),
        },
        platform: {
          terms: new Date(),
        }
      }
    });
  }
  render() {
    if (!UserAccount.isLoggedIn) return null;
    if (Navigation.currentLocation[1] === 'legal') return null;
    const { isAuthorizedToLogIn } = Company;
    if (!isAuthorizedToLogIn) return null;
    const { termsChecked } = this.state;
    const { account } = UserAccount;
    const { legal } = account;
    if (legal && legal.terms && legal.privacy && legal.platform && legal.platform.terms && legal.cookies && legal.cookies.necessary) return null;
    return (
      <Popup
        preventDeletion
      >
        <Wrapper>
          <img alt="logictry" style={{ margin: '0px 0px 0.5rem 0px', height: 40 }} src="https://logictrystatic.s3.amazonaws.com/images/logo062623.png" />
          <p>Welcome to the Logictry platform!</p>
          <p>Before continuing, we do need you to review our <a target="_blank" href="https://logictry.com/legal/platform/terms">Platform Terms of Service</a> to familiarize yourself with our terms and conditions.</p>
          <p>We also use cookies for basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.</p>
          <Checkbox><input type='checkbox' onClick={() => this.setState({ termsChecked: !termsChecked })} value={termsChecked}></input>I agree to the Platform Terms of Service</Checkbox>
          {termsChecked && <AcceptButton><Button filledColor onClick={this.accept}><div>Accept</div></Button></AcceptButton>}
          <IDontAgree><Button onClick={() => UserAccount.logout()}><div>I don't agree</div></Button></IDontAgree>
        </Wrapper>
      </Popup>
    );
  }
}
export default LegalPopup;
