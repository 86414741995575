import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import UserCache from '../../services/cache/UserCache';

const Wrapper = styled.div`
  display: flex;
`;

const App = styled.div`
  cursor: pointer;
  > div:last-child {
    width: 100%;
    > button {
      width: 3rem;
      color: white;
      text-align: center;
      margin-right: 1rem;
      font-size: ${Constants.SmallFontSize};
      i {
        padding-right: 0.25rem;
      }
    }
  }
  .hideuntilhover i {
    opacity: 0;
  }
  .hideuntilhover i:focus {
    opacity: 1;
  }
  :hover {
    .hideuntilhover i {
      opacity: 1;
    }
  }
`;
const AppSort = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-right: 1rem;
`;
const AppImage = styled.div`
  width: 100%;
  padding-bottom: 56%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`

export default class LogicBaseAppList extends React.PureComponent {
  static propTypes = {
    apps: PropTypes.array,
    listView: PropTypes.bool,
    small: PropTypes.bool,
    collection: PropTypes.string,
    allowSorting: PropTypes.bool,
    horizontal: PropTypes.bool,
    wrap: PropTypes.bool,
    showCreateNew: PropTypes.bool,
    onCreateNew: PropTypes.func,
  }
  state = {
    menuOpen: false,
    appId: Navigation.currentLocation[2],
    q: Navigation.currentLocation[1] === 'search' && Navigation.currentLocation[2] && unescape(Navigation.currentLocation[2]) || Navigation.currentSearch.q || '',
    collectionParam: Navigation.currentSearch.collection
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  render() {
    const { mobile, tablet } = WindowSize;
    const { apps, listView, small, collection, allowSorting, wrap, showCreateNew, onCreateNew } = this.props;
    const { collectionParam, appId } = this.state;
    return (
      <Wrapper style={{ flexWrap: wrap && 'wrap', flexDirection: listView && 'column', gap: small && (wrap && '1rem 0.75rem' || '0.75rem') || (wrap && '1.5rem 1rem' || '1rem') }}>
        {showCreateNew && <App
          onClick={onCreateNew}
          style={{
            position: 'relative',
            border: '1px dashed black',
            backgroundPosition: 'cover',
            height: small && (mobile && 90 || 100) || (tablet && 135 || 157.5),
            width: small && (mobile && 160 || 178) || (tablet && 240 || 280),
            paddingBottom: listView && 'unset',
            flexShrink: 0,
            borderRadius: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div style={{
            fontSize: small && Constants.SmallFontSize || Constants.NormalFontSize,
            textAlign: 'center'
          }}>+ New App</div>
        </App>}
        {apps.map((app, i) => {
          const { _id, title, background } = app;
          const isCurrentApp = appId === _id;
          const onclick = () => {
            if (Constants.isWidget) {
              const params = new URLSearchParams(window.location.search);
              if (collectionParam) params.set('collection', collectionParam);
              Navigation.navigate(`${Navigation.apps}/${_id}?${params.toString()}`);
            } else {
              const relativeUrl = collectionParam && `${Navigation.apps}/${_id}?collection=${collectionParam}`
                || collection && `${Navigation.apps}/${_id}?collection=${collection}`
                || `${Navigation.apps}/${_id}`;
              if (!Constants.isLogicWiki) {
                Navigation.site(`https://logictry.com${Navigation.apps}/${_id}`);
              } else {
                if (!isCurrentApp) Navigation.push(relativeUrl, collectionParam && {
                  'logicbaseapp_related_vertical': window.history.state[`logicbaseapp_related_vertical`] || 0,
                  'logicbaseapp_related_horizontal': window.history.state[`logicbaseapp_related_horizontal`] || 0
                });
              }
            }
          }
          const backgroundImage = background && background.image && `url("${background.image}") center / cover no-repeat` || null;
          const outerStyle = { display: 'flex', flexDirection: 'column', flex: 0 };
          const innerStyle = {
            color: 'white', fontWeight: 500, position: 'relative',
            background: backgroundImage || background && background.color || 'linear-gradient(45deg, #1ebd53, #4edc82)' || null,
            backgroundPosition: 'cover',
            height: small && (mobile && 90 || 100) || (tablet && 135 || 157.5),
            width: small && (mobile && 160 || 178) || (tablet && 240 || 280),
            paddingBottom: listView && 'unset',
            flexShrink: listView && 0,
            borderRadius: '0.5rem'
          }
          return (
            <App key={_id} style={{ ...outerStyle, flexDirection: 'row', filter: isCurrentApp && 'brightness(0.5)' }}>
              {allowSorting && <AppSort>
                {i > 0 && <i className="fas fa-arrow-up" style={{ padding: '0.5rem' }} onClick={() => { apps.splice(i, 1); apps.splice(i - 1, 0, app); this.forceUpdate(); }}></i>}
                {i < apps.length - 1 && <i className="fas fa-arrow-down" style={{ padding: '0.5rem' }} onClick={() => { apps.splice(i, 1); apps.splice(i + 1, 0, app); this.forceUpdate(); }}></i>}
              </AppSort>}
              <AppImage style={innerStyle} onClick={onclick}>
                {!(background && background.image) && <div style={{ position: 'absolute', inset: small && '0.5rem' || '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'hidden', textAlign: 'center' }}>
                  <div style={{
                    fontSize: small && Constants.SmallFontSize || Constants.NormalFontSize,
                    WebkitLineClamp: tablet && '4' || '5',
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    overflow: 'hidden'
                  }}>{title && (title.text.charAt(0).toUpperCase() + title.text.slice(1))}</div>
                </div>}
              </AppImage>
            </App>
          )
        })}
      </Wrapper>
    );
  }
}
