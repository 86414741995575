/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import TreeCache from '../../services/cache/TreeCache';
import TreeCreateNode from '../../services/TreeCreateNode';
import Navigation from '../../services/Navigation';
import SidebarService from '../../services/SidebarService';
import ClickableIcon from '../../components/ClickableIcon';
import VersioningList from '../../components/VersioningList';
import Tooltip from '../../components/Tooltip/index';
import SocialShare from '../../components/SocialShare/index';
import TemplateTreeSettings from '../../components/ObjectSettings/TemplateTreeSettings';
import ProjectTreeSettings from '../../components/ObjectSettings/ProjectTreeSettings';
import Scrollable from '../../components/Scrollable/index';
import NodeSettings from '../../components/NodeSettings';

const Wrapper = styled.div`
  position: absolute;
  right: 0px;
  font-size: ${Constants.SemiLargeFontSize};
  * {
    font-size: ${Constants.SemiSmallFontSize};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // border-top-left-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.4);
  // filter: saturate(0.3) brightness(1.6);
  background-color: ${Constants.White};
  h3 {
    text-align: center;
  }
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  > * {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const AnswerButtons = styled.div`
  display: flex;
  width: 100%;
  > * {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Divider = styled.div`
  width: 100%;
  background-color: rgb(242, 242, 242);
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
`;
const AccessStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 5px 20px;
  > i {
    margin-right: 10px;
  }
`;

const LockedMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 5px 0px;
  > i {
    margin-right: 10px;
  }
`;
const TopMenuItems = styled.div`
  padding: 0.5rem 0;
`

export default class Sidebar extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.object,
    saveChanges: PropTypes.func,
    checkout: PropTypes.func,
    checkin: PropTypes.func,
    showEditor: PropTypes.bool,
    showMini: PropTypes.bool,
  };
  componentDidMount() {
    SidebarService.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
    TreeCreateNode.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
    const { tree } = this.props;
    tree.onStateUpdate(this);
  }
  componentWillUnmount() {
    SidebarService.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
    TreeCreateNode.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
    const { tree } = this.props;
    tree.offStateUpdate(this);
  }
  getFormButton(selectType) {
    return <div key="tasks"><Tooltip text="View questions"><ClickableIcon disabled={selectType === 'Tree'} onClick={() => SidebarService.updateSelectType('Tree')} icon="fas fa-tasks" /></Tooltip></div>;
  }
  getPdfButton(selectType) {
    return <div key="word">
      <Tooltip text="View report"><ClickableIcon disabled={selectType === 'Text'} onClick={() => SidebarService.updateSelectType('Text')} icon="fas fa-file-pdf" /></Tooltip>
    </div>;
  }
  getChatButton(selectType) {
    return <div key="chat">
      <Tooltip text="View chat interface"><ClickableIcon disabled={selectType === 'Chat'} onClick={() => SidebarService.updateSelectType('Chat')} icon="fas fa-comment" /></Tooltip>
    </div>;
  }
  getAnswerButtons = () => {
    const { selectType } = SidebarService;
    const { tree } = this.props;
    const { project } = tree;
    const answerButtons = [];
    answerButtons.push(<div key="arrow_up"><Tooltip text="Scroll to top"><ClickableIcon disabled={selectType !== 'Tree'} onClick={this.scrollTop} icon="fa fa-arrow-up" /></Tooltip></div>);
    if (!project) answerButtons.push(<div key="edit"><Tooltip text="Edit"><ClickableIcon onClick={() => Navigation.push(`/apps/${tree._id}/edit`)} icon="fas fa-edit" /></Tooltip></div>);
    answerButtons.push(this.getFormButton(selectType));
    answerButtons.push(this.getPdfButton(selectType));
    if (!project) answerButtons.push(this.getChatButton(selectType));
    answerButtons.push(<div key="social_share"><SocialShare tree={tree} /></div>);
    answerButtons.push((tree.type === 'Template' && (
      <TemplateTreeSettings key="settings" object={tree} />
    ) || (
      <ProjectTreeSettings key="settings" object={tree} />
    )))
    return answerButtons;
  }
  getCodeButton = (tree, disableCode, selectType) => <div><Tooltip text="Switch to code"><ClickableIcon disabled={!tree || !tree._id || disableCode || selectType === 'Code'} onClick={() => SidebarService.updateSelectType('Code')} icon="fas fa-code" /></Tooltip></div>;
  getVersionContent = (marginTop) => {
    const { showVersions, showWysiwyg, showNodeTypes } = SidebarService;
    const { tree } = this.props;
    const { version, diffs, currentVersionDisplayed } = tree;
    if (!version || !diffs) return null;
    const htmlIcon = <div><Tooltip text="Toggle Wysiwyg"><ClickableIcon grayedOut={!showWysiwyg} onClick={() => SidebarService.updateShowWysiwyg(!showWysiwyg)} icon="fab fa-html5" /></Tooltip></div>;
    const versionIcon = <div><Tooltip text="Toggle Versions"><ClickableIcon grayedOut={!showVersions} onClick={() => SidebarService.updateShowVersions(!showVersions)} icon="fas fa-code-branch" /></Tooltip></div>;
    const showNodeTypesIcon = <div><Tooltip text="Toggle Node Types"><ClickableIcon grayedOut={!showNodeTypes} onClick={() => SidebarService.updateShowNodeTypes(!showNodeTypes)} icon="fas fa-info" /></Tooltip></div>;

    if (!showVersions) return [
      <AnswerButtons key="select_type_buttons" style={{ marginTop, flexDirection: 'row' }}>
        {htmlIcon}
        {showNodeTypesIcon}
        {versionIcon}
      </AnswerButtons>,
    ];
    return [
      <AnswerButtons key="select_type_buttons" style={{ marginTop, flexDirection: 'row' }}>
        {htmlIcon}
        {showNodeTypesIcon}
        {versionIcon}
      </AnswerButtons>,
      <Divider key="versions_divider"><div style={{ width: '100%', textAlign: 'center' }}>Versions</div></Divider>,
      <VersioningList
        key="versioning_list"
        node={tree}
        diffs={diffs}
        version={version}
        currentVersionDisplayed={currentVersionDisplayed}
        showVersion={this.showVersion}
        diffCache={TreeCache.diffCache}
      />
    ]
  }
  scrollTop = () => GrayOut.scrollTop();
  showVersion = (version, deltas, index) => {
    const { tree } = this.props;
    tree.showVersion(version, deltas.slice(0, index));
    GrayOut.clearAllActiveObjects();
  }
  viewAsProject = () => {
    const { tree } = this.props;
    if (!tree._id) return;
    Navigation.push(`${Navigation.apps}/${tree._id}`);
  }
  viewAsTemplate = () => {
    const { tree } = this.props;
    Navigation.push(`${Navigation.apps}/${tree._id}/edit`);
  }
  createProject = () => {
    const { tree } = this.props;
    Navigation.push(`${Navigation.apps}/${tree._id}?autocreate`);
  }
  render() {
    const { saveChanges, showEditor, tree, checkout, checkin, showMini } = this.props;
    const { isEditor, isCheckoutAllowed, isCheckedOut, isCheckinAllowed, isOwnerOrAdmin, isLocked, isDeleted } = tree;
    const { activeObject, activeObjectParents } = GrayOut.activeOrLastActiveObjectAndParents();
    const { selectType, showVersions } = SidebarService;
    const showActiveObjectButtons = activeObject && activeObjectParents && selectType === 'Tree';

    if (showMini) return null;

    if (showEditor) {
      const top = 0;
      const width = 240;
      const height = '100%';
      const borderBottomLeftRadius = 0;
      const borderTopLeftRadius = 0;
      const marginTop = 10;
      const overflowY = null;
      // const disableGraph = !tree || !tree._id || selectType === 'Graph';
      const textHtmlWysiwygLine = this.getVersionContent(marginTop);

      const scrollTopIcon = () => <div><Tooltip text="Scroll to top"><ClickableIcon disabled={selectType !== 'Tree'} onClick={this.scrollTop} icon="fa fa-arrow-up" /></Tooltip></div>;
      const saveIcon = (disabled) => <div><Tooltip text="Save"><ClickableIcon disabled={disabled} onClick={() => saveChanges()} icon="fas fa-save" /></Tooltip></div>;
      const previewIcon = () => <div><Tooltip text="Preview"><ClickableIcon disabled={!tree._id} onClick={() => this.viewAsProject()} icon="fas fa-eye" /></Tooltip></div>;
      const treeIcon = () => <div><Tooltip text="Switch to tree"><ClickableIcon disabled={selectType === 'Tree'} onClick={() => SidebarService.updateSelectType('Tree')} icon="fas fa-tasks" /></Tooltip></div>;
      const graphIcon = () => <div><Tooltip text="Switch to doc"><ClickableIcon disabled={selectType === 'Doc'} onClick={() => SidebarService.updateSelectType('Doc')} icon="fas fa-file" /></Tooltip></div>;

      if (isDeleted) {
        return (<Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <TopMenuItems>
              <LockedMessage><i className="fa fa-trash" /><div>Deleted</div></LockedMessage>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {scrollTopIcon()}
                {saveIcon(true)}
                {previewIcon()}
              </AnswerButtons>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {treeIcon()}
                {graphIcon()}
                {this.getCodeButton(tree, false, selectType)}
              </AnswerButtons>
              {textHtmlWysiwygLine}
            </TopMenuItems>
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>);
      }

      if (isLocked) {
        return (<Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <TopMenuItems>
              <LockedMessage><i className="fa fa-lock" /><div>Locked</div></LockedMessage>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {scrollTopIcon()}
                {saveIcon(true)}
                {previewIcon()}
              </AnswerButtons>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {treeIcon()}
                {graphIcon()}
                {this.getCodeButton(tree, false, selectType)}
              </AnswerButtons>
              {textHtmlWysiwygLine}
            </TopMenuItems>
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>);
      }
      if (!isEditor) {
        return (<Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <TopMenuItems>
              {isOwnerOrAdmin && isCheckedOut && !isCheckinAllowed && <AccessStatus><i className="fa fa-lock" /><div>Already In-use</div></AccessStatus>}
              {isOwnerOrAdmin && !isCheckedOut && isCheckoutAllowed && <AccessStatus role="button" onClick={() => checkout()}><i className="fa fa-lock" /><div>Start Editing</div></AccessStatus>}
              {!isOwnerOrAdmin && <div style={{ textAlign: 'center', padding: 10 }}>You dont have permission to edit</div>}
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {scrollTopIcon()}
                {saveIcon(true)}
                {previewIcon()}
              </AnswerButtons>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {treeIcon()}
                {graphIcon()}
                {this.getCodeButton(tree, false, selectType)}
              </AnswerButtons>
              {textHtmlWysiwygLine}
            </TopMenuItems>
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>);
      }
      if (showVersions) {
        return (
          <Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
            <Scrollable
              vertical
              showVertical
              style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
            >
              <TopMenuItems>
                {isOwnerOrAdmin && isCheckedOut && isCheckinAllowed && <AccessStatus role="button" onClick={() => checkin()}><i className="fa fa-unlock" /><div>Finish Editing</div></AccessStatus>}
                <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                  {scrollTopIcon()}
                  {saveIcon(!isEditor || !tree.currentVersionDisplayed)}
                  {previewIcon()}
                </AnswerButtons>
                <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                  {treeIcon()}
                  {graphIcon()}
                  {this.getCodeButton(tree, false, selectType)}
                </AnswerButtons>
                {textHtmlWysiwygLine}
              </TopMenuItems>
              <div style={{ flex: 1, height: 40 }}></div>
            </Scrollable>
          </Wrapper>
        );
      }

      return (
        <Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <TopMenuItems>
              {isOwnerOrAdmin && isCheckedOut && isCheckinAllowed && <AccessStatus role="button" onClick={() => checkin()}><i className="fa fa-unlock" /><div>Finish Editing</div></AccessStatus>}
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {scrollTopIcon()}
                {saveIcon(!isEditor || !tree.currentVersionDisplayed)}
                {previewIcon()}
              </AnswerButtons>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {treeIcon()}
                {graphIcon()}
                {this.getCodeButton(tree, false, selectType)}
              </AnswerButtons>
              {textHtmlWysiwygLine}
            </TopMenuItems>
            {showActiveObjectButtons && showEditor && <NodeSettings key={activeObject && activeObject.key} node={activeObject} />}
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>
      );
    }
    const width = 40;
    const marginTop = 0;
    const buttons = this.getAnswerButtons();
    const top = 0;
    const height = '100%';
    return (
      <div>
        <Wrapper style={{ top, width, height }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <Buttons style={{ marginTop, flexDirection: 'column', justifyContent: 'center' }}>
              {buttons}
            </Buttons>
          </Scrollable>
        </Wrapper>
      </div>
    );
  }
}
