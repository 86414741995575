import React from 'react';
import UserCache from '../services/cache/UserCache';
import Navigation from '../services/Navigation';

export default function getFormattedTextElements(text) {
  const formattedTextElements = [];
  let currentIndex = 0;
  const regex = /@([a-fA-F0-9]{24})/g;
  let match;
  while ((match = regex.exec(text)) !== null) {
    const matchedIndex = match.index;
    const user = (UserCache.getUsersByIds([match[1]]) || [])[0];
    // Add text before the mention
    formattedTextElements.push(
      <span key={currentIndex++}>{text.substring(currentIndex, matchedIndex)}</span>
    );
    // Add the mention with underline style
    formattedTextElements.push(
      <span
        key={currentIndex++}
        style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 500 }}
        onClick={(e) => { e.stopPropagation(); Navigation.push(Navigation.userProfile(user)); }}
      >
        {user && (user.fullname || `@${user.username || `${user._id.slice(0, 8)}...`}`) || `@${match[1].slice(0, 8)}...`}
      </span>
    );
    // Update the current index to the end of the mention
    currentIndex = matchedIndex + match[0].length;
  }
  // Add the remaining text after the last mention
  formattedTextElements.push(<span key={currentIndex}>{text.substring(currentIndex)}</span>);
  return formattedTextElements;
}