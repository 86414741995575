import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import UserCache from '../../services/cache/UserCache';
import CompanyService from '../../services/Company';
import UserAccount from '../../services/UserAccount';
import LoadingIndicator from '../../components/LoadingIndicator';
import Button from '../Button/index';
import UserAvatar from '../UserAvatar';
import SelectMenu from '../SelectMenu';
import Popup from '../Popup/index';
import FullHeightDiv from '../FullHeightDiv';
import BoxPopup from '../../containers/TreePage/BoxPopup';
import Scrollable from '../Scrollable/index';
import { ADMIN_ROLE, VIEWER_ROLE } from '../../models/permissions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 6rem);
  width: ${Constants.ListPopupWidth};
  max-width: 90vw;
  overflow: hidden;
  position: relative;

  > input {
    padding: 0.5rem 1rem;
    width: 100%;
    ${Constants.InputBorderBottom}
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 52px;
  }
`;
const UserWrapper = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  > div:nth-child(1) {
    padding: 0px 10px;
  }
  > div:nth-child(2) {
    flex: 1;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 44px;
`;

export default class UsersBox extends BoxPopup {
  componentDidMount() {
    UserCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    this.clearSearchTimeout();
  }
  clearSearchTimeout = () => {
    if (this.__searchTimeout) clearTimeout(this.__searchTimeout);
    this.__searchTimeout = null;
  }
  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.runSearch();
    } else {
      this.clearSearchTimeout();
      this.__searchTimeout = setTimeout(() => {
        this.searchText();
      }, (CompanyService.isExpected && CompanyService.subscriptionVerified) ? 100 : 1000);
    }
  }
  runSearch = () => {
    const { searchInput } = this;
    searchInput.blur();
  }
  searchText = () => {
    this.forceUpdate();
  }
  updateState = (_selectType, user) => {
    const { domainObject } = this.props;
    const foundUser = domainObject.users.find((_user) => _user._id === user._id);
    if (_selectType === 'Owner') domainObject.owner = user._id;

    // eslint-disable-next-line no-param-reassign
    if (foundUser) {
      if (_selectType === 'Owner') foundUser.role = 'Remove';
      else foundUser.role = _selectType;
    } else if (_selectType !== 'Owner') domainObject.addUser(user, _selectType);
    this.forceUpdate();
  }
  render() {
    const { isDevAccount } = UserAccount;
    const { domainObject } = this.props;
    const { isOwnerOrAdmin } = domainObject;
    const { searchInput } = this;
    const search = searchInput && searchInput.value && searchInput.value.toLowerCase();
    const usersInvitedSet = new Set([
      domainObject.owner,
      ...domainObject.users.filter((u) => u.role === ADMIN_ROLE).map((_user) => _user._id),
      ...domainObject.users.filter((u) => u.role === VIEWER_ROLE).map((_user) => _user._id),
    ]);
    const users = UserCache.getUsersByIds(Array.from(usersInvitedSet));
    let usersSearched = null;

    const { isExpected, subscriptionVerified } = CompanyService;
    if (users && isExpected && subscriptionVerified) {
      const subscriptionUsers = (CompanyService.company.subscriptions && CompanyService.company.subscriptions[0] && CompanyService.company.subscriptions[0].users || []);
      usersSearched = UserCache.getUsersByIds([...subscriptionUsers].map((u) => u._id));
      if (usersSearched) {
        const usersAlreadyInvited = [...users];
        const usersNotYetInvited = usersSearched.filter((u) => !usersInvitedSet.has(u._id));
        usersNotYetInvited.sort((a, b) => {
          if (a.fullname < b.fullname) return -1;
          if (b.fullname < a.fullname) return 1;
          return 0;
        });
        usersSearched = [...usersAlreadyInvited, ...usersNotYetInvited]
      }
    }
    if (search) {
      if (isExpected && subscriptionVerified) {
        usersSearched = usersSearched.filter((u) => (u.fullname && u.fullname.toLowerCase().includes(search) || u.username && u.username.toLowerCase().includes(search)));
      } else {
        const filterSet = new Set();
        usersSearched = UserCache.getUsers({
          page: 1,
          search,
        });
        if (usersSearched) usersSearched = usersSearched.filter((user) => {
          if (filterSet.has(user._id)) return false;
          filterSet.add(user._id);
          return true;
        });
      }
    }
    if (!users) return null;
    const usersFound = (subscriptionVerified && usersSearched) || (search && usersSearched) || (!search && users);
    return (
      <Popup
        onClose={this.close}
        allowBackgroundClose
      >
        <Wrapper>
          <input autoComplete="off" autoCapitalize="off" autoCorrect="off" onKeyDown={this.onKeyDown} ref={(e) => { this.searchInput = e; }} id="search" name="search" type="search" placeholder="Search users" onBlur={this.searchText} ></input>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 52, right: 0, bottom: 44, left: 0, padding: '0.5rem 0' }}
          >
            <FullHeightDiv>
              {(search && !usersSearched) && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <LoadingIndicator />
                </div>
              )}
              {usersFound && (usersFound.length > 0 ? (usersFound.map((user) => {
                const isUserAccount = user._id === UserAccount.account._id;
                const currentUser = domainObject.users.find((_user) => _user._id === user._id);
                const currentRole = currentUser ? currentUser.role : null;
                const usersName = user.fullname && user.username && `${user.fullname} (${user.username})` || user.fullname || user.username;
                const showSelection = isOwnerOrAdmin || isUserAccount;
                const selectionRoles = (isDevAccount && ['Owner', ...domainObject.roles]) || (isOwnerOrAdmin && domainObject.roles) || [currentRole];
                return (
                  <UserWrapper key={user._id}>
                    <UserAvatar user={user} />
                    <div>{usersName}</div>
                    {(domainObject.owner === user._id) ? ( // eslint-disable-line
                      <div style={{ width: 80, textAlign: 'center', fontSize: Constants.SemiSmallFontSize }}>Owner</div>
                    ) : (
                      <div style={{ margin: '0px 8px' }}>
                        {(showSelection) && ((currentRole !== 'Remove' && users.find((_user) => _user._id === user._id) ? (
                          <SelectMenu value={currentRole} onChange={(val) => this.updateState(val, user)} options={[...selectionRoles, 'Remove'].map((_role) => ({ value: _role, name: _role }))} />
                        ) : (
                          <SelectMenu value="Choose Role" onChange={(val) => this.updateState(val, user)} options={[...selectionRoles].map((_role) => ({ value: _role, name: _role }))} />
                        ))) || (
                          <div style={{ width: 80, textAlign: 'right', marginRight: 8, fontSize: Constants.SemiSmallFontSize }}>{currentRole}</div>
                        )}
                      </div>
                    )}
                  </UserWrapper>
                );
              })) : (
                <div style={{ textAlign: 'center', marginTop: 20 }}>No users found</div>
              ))}
            </FullHeightDiv>
          </Scrollable>
          <ButtonsWrapper>
            <Button onClick={this.close} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.saveAndClose} color="primary" autoFocus>
              Save
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </Popup>
    );
  }
}
