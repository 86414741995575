import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import ClickableIcon from '../ClickableIcon/index';
import Popup from '../Popup/index';
import MenuList from '../../components/Popup/MenuList';
import MenuItem from '../../components/Popup/MenuItem';
import SocialSharePopup from '../SocialShare/SocialSharePopup';
import LogicBaseAppPinCache from '../../services/cache/LogicBaseAppPinCache';
import LogicBaseAppReportCache from '../../services/cache/LogicBaseAppReportCache';
import TreeCache from '../../services/cache/TreeCache';
import UserAccount from '../../services/UserAccount';
import Navigation from '../../services/Navigation';
import CollectionsPopup from '../CollectionsPopup';
import EmbedCodePopup from '../ObjectSettings/EmbedCodePopup';
import Button from '../Button';
import ConfirmDialog from '../ConfirmDialog';

const MenuWrapper = styled.div`
  i, img {
    margin-right: 1rem;
    justify-content: center;
    display: flex;
    width: 1rem;
  }
`;
const FlagWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 2rem;
  h1 {
    margin-bottom: 1rem;
  }
`;
const FlagButtonsWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;

export default class LogicBaseAppSettings extends React.PureComponent {
  static propTypes = {
    app: PropTypes.object,
  }
  state = {
    menuOpen: false,
    showShareLinks: false,
    showCollectionPopup: false,
    showEmbedPopup: false,
    showFlagPopup: false,
  }
  componentDidMount() {
    LogicBaseAppPinCache.onStateUpdate(this);
    LogicBaseAppReportCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    LogicBaseAppPinCache.offStateUpdate(this);
    LogicBaseAppReportCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
  }
  flag = () => {
    const { app } = this.props;
    const youFlagged = LogicBaseAppReportCache.query({ o: UserAccount.account._id, a: app._id })
    if (youFlagged && youFlagged[0]) {
      setTimeout(() => {
        LogicBaseAppReportCache.__deleteQueryFromCache({ o: UserAccount.account._id, a: app._id });
        LogicBaseAppReportCache.delete(youFlagged && youFlagged[0], null, true);
      });
      this.setState({ showFlagPopup: false });
    } else {
      const d = document.getElementById('logicbaseappsettings__flagdescription').value;
      if (!d) return;
      setTimeout(() => {
        LogicBaseAppReportCache.__deleteQueryFromCache({ o: UserAccount.account._id, a: app._id });
        LogicBaseAppReportCache.create(LogicBaseAppReportCache.createObject({
          o: UserAccount.account._id,
          a: app._id,
          d,
        }));
      });
      this.setState({ showFlagPopup: false });
    }
    this.forceUpdate();
  }
  render() {
    const { isLoggedIn, account } = UserAccount;
    const { app } = this.props;
    const { menuOpen, showShareLinks, showCollectionPopup, showEmbedPopup, showFlagPopup } = this.state;
    const onUpvote = () => {
      const relationship = (isLoggedIn && LogicBaseAppPinCache.query({ fr: account._id, a: app._id }) || [])[0];
      if (relationship) LogicBaseAppPinCache.delete(relationship);
      else LogicBaseAppPinCache.create(LogicBaseAppPinCache.createObject({ fr: account._id, a: app._id }));
    }
    const relationship = (isLoggedIn && (menuOpen && LogicBaseAppPinCache.query({ fr: account._id, a: app._id })) || [])[0];
    const youFlagged = (isLoggedIn) ? (menuOpen && LogicBaseAppReportCache.query({ o: UserAccount.account._id, a: app._id }) || this.youFlagged) : [];
    this.youFlagged =  youFlagged;
    const tree = !!menuOpen && isLoggedIn && TreeCache.getTree(app._id);
    let openInEditorUrl;
    const subdomains = window.location.hostname.split('.');
    if (subdomains.length > 2) {
      openInEditorUrl =`https://${subdomains[0]}.logictry.com/apps/${app._id}`;
    } else {
      openInEditorUrl =`https://app.logictry.com/apps/${app._id}`;
    }
    const showAddToCollection = tree && tree.template;
    return (
      <>
        <ClickableIcon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.anchorEl = e.target; this.setState({ menuOpen: app }); }} icon="fas fa-ellipsis-h" />
        {!!menuOpen && (tree || !isLoggedIn) && <Popup
          onClose={() => this.setState({ menuOpen: false })}
          allowBackgroundClose
          anchorEl={this.anchorEl}
        >
          <MenuWrapper>
            <MenuList>
              <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ menuOpen: false }); window.location = openInEditorUrl; }}>
                <i className="fas fa-edit"></i>
                <div>Open in Editor</div>
              </MenuItem>
              {showAddToCollection && <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ menuOpen: false }); isLoggedIn ? onUpvote() : Navigation.push(Navigation.login); }}>
                <i className="fas fa-star"></i>
                <div>{relationship ? 'Unpin' : 'Pin'}</div>
              </MenuItem>}
              <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showShareLinks: this.state.menuOpen, menuOpen: false }) }}>
                <i className="fas fa-share-alt"></i>
                <div>Share</div>
              </MenuItem>
              <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showEmbedPopup: this.state.menuOpen, menuOpen: false }) }}>
                <i className="fas fa-code"></i>
                <div>Embed</div>
              </MenuItem>
              {isLoggedIn && <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showFlagPopup: this.state.menuOpen, menuOpen: false }); }}>
                <i className="fas fa-flag"></i>
                <div>{(youFlagged && youFlagged[0]) && 'Unflag Content' || 'Flag Content'}</div>
              </MenuItem>}
              {showAddToCollection && <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showCollectionPopup: true, menuOpen: false }); }}>
                <i className="fas fa-images"></i>
                <div>Add to Collections</div>
              </MenuItem>}
            </MenuList>
          </MenuWrapper>
        </Popup>}
        {showShareLinks && <SocialSharePopup onClose={(e) => { if (e) { e.preventDefault(); e.stopPropagation(); } this.setState({ showShareLinks: false }) }} tree={showShareLinks} />}
        {showCollectionPopup && <CollectionsPopup app={app} onClose={(e) => { if (e) { e.preventDefault(); e.stopPropagation(); } this.setState({ showCollectionPopup: false }) }}  />}
        {showEmbedPopup && <EmbedCodePopup domainObject={app} onClose={(e) => { if (e) { e.preventDefault(); e.stopPropagation(); } this.setState({ showEmbedPopup: false }) }}   />}
        {showFlagPopup && ((youFlagged && youFlagged[0]) && <ConfirmDialog
          text="Are you sure you wish to unflag the app?"
          description="We will no longer review the app."
          open onNo={() => this.setState({ showFlagPopup: false })} onYes={() => this.flag()} />
        || <Popup
          allowBackgroundClose
          onClose={this.close}
        >
          <FlagWrapper>
            <h2>Are you sure you wish to flag the app?</h2>
            <p>We will review the app and take any neccessary action.</p>
            <p>REQUIRED: Please share what is wrong with the app.</p>
            <textarea
              id="logicbaseappsettings__flagdescription" maxLength={1000}
              style={{ width: '100%', border: '1px solid', minHeight: 200, padding: 12 }}
            />
            <FlagButtonsWrapper>
              <Button onClick={() => this.setState({ showFlagPopup: false })} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => this.flag()} color="primary" autoFocus>
                Save
              </Button>
            </FlagButtonsWrapper>
          </FlagWrapper>
        </Popup>
        )}
      </>
    )
  }
}
