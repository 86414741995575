import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';

const Wrapper = styled.div`
  width: 40px;
  border-radius: 0.5rem;
  overflow: hidden;
  input {
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 0px;
  }
`;

export default class ColorPicker extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    onChange: PropTypes.func,
  }
  changed = (e) => {
    this.props.onChange(e.target.value);
  }

  render() {
    const { color } = this.props;
    return (
      <Wrapper>
        <input onChange={this.changed} onInput={this.changed} type="color" value={color || Constants.PrimaryColor}></input>
      </Wrapper>
    );
  }
}
