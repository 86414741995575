/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import styled from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import Constants from '../../submodules/logictry_config/constants';
import Header from '../../components/Header';
import UserAccount from '../../services/UserAccount';
import Connect from '../../services/Connect';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import SalesForce from '../../services/SalesForce';
import Company from '../../services/Company';
import { reportError } from '../../services/Errors';
import Routes from './routes';
import AppRoot from '../../services/AppRoot';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import PopoutRoutes from './popoutroutes';
import FixedFullscreenDiv from '../../components/FixedFullscreenDiv';
import LoadingFullscreen from '../../components/LoadingFullscreen/index';
import LegalPopup from '../../components/LegalPopup/index';
import PoweredByLogictry from '../../components/PoweredByLogictry';
import LogicBaseRoutes from './logicbaseroutes';
import LogicBaseHeader from '../../components/LogicBaseHeader';
import LogicBaseLegalPopup from '../../components/LogicBaseLegalPopup/index';
import Drawer from '../../applications/App/Drawer';
import DrawerService from '../../services/Drawer';

const AppWrapper = styled(FixedFullscreenDiv)`
  margin: 0 auto;
  flex-direction: row;
  display: flex;
`;
const AppContent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;
const PopoutWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: auto;
`;
const DesktopWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  > div:last-child {
    position: relative;
  }
`;
const FullscreenWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
`;
const LogicBaseWrapper = styled.div`
  > div:last-child {
    position: relative;
  }
`;

class App extends React.PureComponent {
  state = {
    hasError: false,
  };
  constructor(props) {
    super(props);
    UserAccount.getAccountStatus();
    document.onmousedown = (e) => {
      e = e ||  window.event;
      Navigation.__openNewTab = e.altKey || e.metaKey;
      const element = e.target || e.srcElement;
      __recursivelyAddOnclickToAllHrefs(element);
    };
  }
  componentDidMount() {
    const { isSalesForce, isWidget } = Constants;
    UserAccount.onStateUpdate(this);
    AppRoot.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    SalesForce.onStateUpdate(this);
    Connect.onStateUpdate(this);
    Company.onStateUpdate(this);
    DrawerService.onStateUpdate(this);
    Navigation.onStateUpdate(this);
    if (isSalesForce) SalesForce.loadScript();
    if (isWidget) Connect.incrementApiKeyCall();
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
    AppRoot.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    SalesForce.offStateUpdate(this);
    Connect.offStateUpdate(this);
    Company.offStateUpdate(this);
    DrawerService.offStateUpdate(this);
    Navigation.offStateUpdate(this);
  }
  static getDerivedStateFromError(error) {
    const message = error.message || JSON.stringify(error) || 'getDerivedStateFromError';
    const stack = error.stack;
    reportError(message, stack, true);
    return { hasError: true };
  }

  render() {
    const { currentLocation } = Navigation;
    const { mobile } = WindowSize;
    const { hasError } = this.state;
    if (hasError) return <LoadingFullscreen />;
    const { isSalesForce, isApp, isWidget, isWidgetOnSameDomain } = Constants;
    if (isSalesForce) {
      const { loadSuccess } = SalesForce;
      if (!loadSuccess) return <LoadingFullscreen />;
    } else if (isWidget) {
      const { loadSuccess } = Connect;
      if (loadSuccess === false) return <FullscreenWrapper><FullScreenVerticalCenterContent><h1>Invalid API Key</h1></FullScreenVerticalCenterContent></FullscreenWrapper>;
      if (!loadSuccess) return <LoadingFullscreen />;
    }
    const { isLoggedIn, isLoggingIn, isLoggingOut } = UserAccount;
    const fixedHeader = isLoggedIn;
    if (mobile) {
      document.body.style.position = 'unset';
      document.body.style.inset = 'unset';
      document.body.style.width = 'unset';
      document.body.style.height = 'unset';
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.position = 'fixed';
      document.body.style.inset = '0px';
      document.body.style.width = '100%';
      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';
    }

    if (isLoggingIn || isLoggingOut) return <LoadingFullscreen />;
    if (isLoggedIn && Company.isExpected && !Company.company) return <LoadingFullscreen />;
    const showpoweredby = isWidget && !isWidgetOnSameDomain;

    const showLogicBase = Constants.isLogicWiki;
    if (showLogicBase) {
      if (isWidget) return (
        <BrowserRouter>
          <div
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          >
            <LogicBaseRoutes />
          </div>
          {showpoweredby && <PoweredByLogictry />}
        </BrowserRouter>
      );
      const dontLetBodyScroll = !mobile || ['login', 'register', 'reset-password', 'legal'].includes(currentLocation[1]);
      return (
        <LogicBaseWrapper>
          <div
            style={dontLetBodyScroll && { position: 'absolute', top: '4rem', left: 0, right: 0, bottom: 0 } || { position: 'relative', paddingTop: '3.5rem' }}
          >
            <BrowserRouter>
              <LogicBaseRoutes />
            </BrowserRouter>
          </div>
          <div style={{ position: 'fixed', top: 0, left: 0, right: 0, height: '4rem' }}>
            <LogicBaseHeader />
          </div>
          <LogicBaseLegalPopup />
        </LogicBaseWrapper>
      );
    }

    if (Navigation.currentLocation[1] === 'popout') {
      return (
        <AppWrapper  style={{ display: 'flex', filter: AppRoot.blur ? 'blur(4px)' : null }}>
          <PopoutWrapper>
            <BrowserRouter>
              <PopoutRoutes />
            </BrowserRouter>
          </PopoutWrapper>
        </AppWrapper>
      );
    }

    const { open } = DrawerService;
    const showHeader = ((isApp || isSalesForce) && (!UserAccount.isLoggingIn));
    let appWrapperStyle = { display: 'flex', filter: AppRoot.blur ? 'blur(4px)' : null };
    appWrapperStyle = { ...appWrapperStyle, position: 'fixed', top: '0px', left: '0px', height: '100%', width: '100%' };
    const showDrawer = showHeader && isLoggedIn && open;
    return (
      <AppWrapper style={appWrapperStyle}>
        <AppContent>
          {showHeader && <Header fixed={fixedHeader} />}
          <DesktopWrapper style={{ top: showHeader ? Constants.DesktopHeaderHeight : 0 }}>
            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: showDrawer && !mobile ? 240 : 0 }}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </div>
            {showDrawer && <Drawer />}
          </DesktopWrapper>
          {UserAccount.account && UserAccount.account._id && <LegalPopup key={UserAccount.account._id} />}
          {showpoweredby && <PoweredByLogictry />}
        </AppContent>
      </AppWrapper>
    );
  }
}

function __recursivelyAddOnclickToAllHrefs(node) {
  if (!node) return;
  if (node.tagName === 'A') {
    const sendRedirect = Constants.isWidget && Navigation.currentSearch.allowredirects !== 'false';
    const navPushPage = Constants.isApp && (node.href.startsWith(window.location.origin) || !node.href.startsWith('http')) && node.target !== '_blank';
    node.onclick = (e) => {
      if (!node.href) return;
      if (e.metaKey || e.altKey) {
        // Use default open in new window
      } else if (navPushPage) {
        e.preventDefault();
        e.stopPropagation();
        Navigation.push(node.href.split(window.location.origin)[1] || '');
      } else if (sendRedirect) {
        e.preventDefault();
        e.stopPropagation();
        Connect.redirect(node.href);
      }
    }
  }
  if (!node.parentNode) return;
  __recursivelyAddOnclickToAllHrefs(node.parentNode);
}

export default App;
