import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';

const Wrapper = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${Constants.DarkTextColor};
`;
const Progress = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
`;

export default class ProgressBar extends React.PureComponent {
  static propTypes = {
    progress: PropTypes.number,
    primaryColor: PropTypes.string,
  }
  render() {
    const { progress, primaryColor } = this.props;
    return (
      <Wrapper style={{ height: `${Constants.SmallFontSize}`, flex: 1 }}>
        <Progress style={{ width: `${progress}%`, backgroundColor: primaryColor }} />
      </Wrapper>
    );
  }
}
