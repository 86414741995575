import Model from './model';

export default class LogicBaseUser extends Model {
  constructor(_id, description, followers, followees, verified, socialSettings, collections, pins) {
    super();
    this._id = _id;
    this.description = description;
    this.followers = followers || 0;
    this.followees = followees || 0;
    this.verified = verified;
    this.socialSettings = socialSettings;
    this.collections = collections || 0;
    this.pins = pins || 0;
  }
  getJson = () => {
    const { _id, description, followers, followees, verified, socialSettings, collections, pins } = this;
    return {
      _id,
      d: description,
      fr: followers,
      fe: followees,
      v: verified,
      ss: socialSettings,
      c: collections,
      p: pins
    }
  }
}
