import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import DrawerService from '../../services/Drawer';
import ClickableDiv from '../ClickableDiv';
import Scrollable from '../Scrollable';
import Version from '../../version.json';
import UserAvatar from '../UserAvatar';
import LogicBaseCollectionEditPopup from '../LogicBaseCollectionEditPopup';
import { getNotifications } from '../../services/Pagination';

const Wrapper = styled.div`
  background: white;
  padding: 0 1rem 2rem 1rem;
  height: 100%;
  overflow: hidden;
  position: relative;
  i {
    margin-right: 0.75rem;
  }
`;
const ContentWrapper = styled.div`
  padding: 0 1rem 2rem 1rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;
const LogoWrapper = styled.div`
  display: flex;
`;
const Divider = styled.div`
  background: #dbdbdb;
  height: 1px;
  padding: 0px !Important;
  margin: 1rem 0 1rem 0;
  cursor: unset;
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  > div {
    display: flex;
    align-items: center;
    > img {
      height: 32px;
      width: 108px;
    }
  }
`;
const Selection = styled.div`
  cursor: pointer;
  border-radius: 0.75rem;
  font-size: ${Constants.SemiSmallFontSize};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    background: rgb(242, 242, 242);
  }
`;

const logo = 'https://logictrystatic.s3.amazonaws.com/images/logo062623.svg';

export default class LogicBaseDrawer extends React.PureComponent {
  state = {
    showCreateCollection: false
  }
  componentDidMount() {
    UserAccount.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
    if (this.notifications) this.notifications.offStateUpdate(this);
    this.notifications = null;
  }
  getDiv = (url, text, icon, fontSize, profile, onClick, alert) => {
    const { account } = UserAccount;
    const { pathname, search } = window.location;
    return <Selection
      style={`${pathname}${search}` === url && { background: 'rgb(242, 242, 242)', padding: (icon || profile) ? '0.75rem' : '0.5rem 0.75rem' }
        || { padding: (icon || profile) ? '0.75rem' : '0.5rem 0.75rem' }}
      onClick={() => {
        if (DrawerService.open) { DrawerService.toggle(); }
        if (url) { url.startsWith('http') ? Navigation.site(url) : Navigation.navigate(url); }
        if (onClick) onClick();
      // }, 20) }
      }}
    >
      {(icon || profile) && <div style={{ width: 32, display: 'flex', alignItems: 'center', position: 'relative' }}>
        {icon && <i className={icon} style={{ width: 16, textAlign: 'center', fontSize, marginLeft: 3 }}></i>
          || profile && <UserAvatar mini onClick={this.clickedAccount} user={account} />}
          {alert && <div style={{ position: 'absolute', top: -6, right: 7, width: 12, height: 12, background: 'red', borderRadius: '50%' }}></div>}
      </div>}
      <div>{text}</div>
    </Selection>
  }
  render() {
    const { showCreateCollection } = this.state;
    const { isLoggedIn, isDevAccount, account } = UserAccount;
    let notification = false;
    if (isLoggedIn) {
      if (!this.notifications) {
        this.notifications = getNotifications(account._id);
        this.notifications.onStateUpdate(this);
      }
      notification = !!this.notifications.currentPage.find(({ read }) => !read);
      if ((!account.username || !account.fullname || (!account.image || !account.image.link))) notification = true;
    } else {
      if (this.notifications) this.notifications.offStateUpdate(this);
      this.notifications = null;
    }
    return (
      <Wrapper>
        <Scrollable
          vertical
          style={{ position: 'absolute', inset: 0 }}
        >
          <ContentWrapper>
            <LogoWrapper style={{ alignItems: 'center', padding: '0.25rem 0 1.5rem 18px' }}>
              <i onClick={() => DrawerService.toggle()} className="fas fa-bars"></i>
              <Logo>
                <ClickableDiv onClick={() => { if (DrawerService.open) { DrawerService.toggle(); } Navigation.push(Navigation.home); }}>
                  <img alt={logo} src={logo}></img>
                </ClickableDiv>
              </Logo>
            </LogoWrapper>
            {this.getDiv('/', 'Home', 'fas fa-home')}
            {this.getDiv('/users', 'Users', 'fas fa-users')}
            {this.getDiv('/collections', 'Discover', 'fas fa-search', 14)}
            {isLoggedIn && this.getDiv(null, 'Create', 'far fa-plus-square', 16, null, () => this.setState({ showCreateCollection: true }))}
            {isLoggedIn && this.getDiv('/account/notifications', 'Notifications', 'far fa-bell', 17, null, null, notification)}
            {isLoggedIn && this.getDiv('/account', 'Account', null, null, true)}
            <div style={{ flex: 1 }}></div>
            <Divider />
            {this.getDiv('/legal/privacy', 'Privacy Policy')}
            {this.getDiv('/legal/terms', 'Terms of Service')}
            {this.getDiv('https://app.logictry.com', 'Content Editor')}
            {this.getDiv('https://business.logictry.com', 'For Businesses')}
            {isDevAccount && <div style={{ padding: '0.5rem 1rem' }}>v{Version.version}</div>}
          </ContentWrapper>
        </Scrollable>
        {showCreateCollection && <LogicBaseCollectionEditPopup onClose={() => this.setState({ showCreateCollection: false })} />}
      </Wrapper>
    )
  }
}

