import Model from './model';
import { ADMIN_ROLE, VIEWER_ROLE } from './permissions';

export default class Company extends Model {
  /**
   * Constructor
   */
  constructor(_owner, _users, _userLoggedIn, _id, _shortname, _longname, _createdTime, _updateTime, _theme, _website, _subscriptions, _isDevAccount, _websiteSettings) {
    super();
    this._id = _id;
    if (_createdTime) this.createdTime = new Date(_createdTime);
    if (_updateTime) this.updateTime = new Date(_updateTime);
    this.userLoggedIn = _userLoggedIn;
    this.shortname = _shortname;
    this.longname = _longname;
    this.owner = _owner;
    this.users = _users;
    this.theme = _theme;
    this.website = _website;
    this.subscriptions = _subscriptions;
    this.isDevAccount = _isDevAccount;
    this.websiteSettings = _websiteSettings;
  }
  roles = [ADMIN_ROLE, VIEWER_ROLE];
  get isOwner() {
    return !!(this.userLoggedIn && (this.userLoggedIn === this.owner));
  }
  get isOwnerOrAdmin() {
    return !!(this.isDevAccount || this.userLoggedIn && (this.userLoggedIn === this.owner || this.subscriptions && this.subscriptions[0] && this.subscriptions[0].users.find((_user) => (_user._id === this.userLoggedIn && _user.role === ADMIN_ROLE))));
  }
  get subscriptionFound() {
    return !!(this.isDevAccount || this.userLoggedIn && (this.userLoggedIn === this.owner || this.subscriptions && this.subscriptions[0] && this.subscriptions[0].users.find((_user) => (_user._id === this.userLoggedIn && (_user.role === ADMIN_ROLE || _user.role === VIEWER_ROLE)))));
  }
  get formattedUpdateTime() {
    if (this.updateTime) return this.updateTime.toLocaleString();
    return '';
  }
  addUser(_user, role) {
    if (this.users.find((user) => user._id === _user._id)) return;
    this.users.push({
      _id: _user._id,
      role,
    });
  }
  removeUser(_user) {
    const index = this.users.findIndex((user) => user._id === _user._id);
    if (index < 0) return;
    this.users.splice(index, 1);
  }
  getJson() {
    const { _id, longname, shortname, owner, description, company, users, teams, theme, website, websiteSettings } = this;
    return {
      _id,
      longname,
      shortname,
      owner,
      description,
      company,
      users,
      teams,
      theme,
      website,
      websiteSettings
    };
  }
}
