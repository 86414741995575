import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import DrawerService from '../../services/Drawer';
import ClickableDiv from '../ClickableDiv';
import Scrollable from '../Scrollable';
import Version from '../../version.json';

const Wrapper = styled.div`
  background: white;
  padding: 0 1rem 2rem 1rem;
  height: 100%;
  overflow: hidden;
  position: relative;
  i {
    margin-right: 0.75rem;
  }
`;
const ContentWrapper = styled.div`
  padding: 0 1rem 2rem 1rem;
`;
const LogoWrapper = styled.div`
  display: flex;
`;
const Divider = styled.div`
  background: #dbdbdb;
  height: 1px;
  padding: 0px !Important;
  margin: 1rem 0 1rem 0;
  cursor: unset;
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  > div {
    display: flex;
    align-items: center;
    > img {
      height: 32px;
      width: 108px;
    }
  }
`;
const Selection = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.75rem;
  font-size: ${Constants.SemiSmallFontSize};

  &:hover {
    background: rgb(242, 242, 242);
  }
`;

const logo = 'https://logictrystatic.s3.amazonaws.com/images/logo062623.svg';

export default class LogicBaseDrawer extends React.PureComponent {
  getDiv = (url, text, icon) => {
    const { pathname, search } = window.location;
    return <Selection key={url}
      style={`${pathname}${search}` === url && { background: 'rgb(242, 242, 242)', fontWeight: icon && 600 || 'unset' } || { fontWeight: icon && 600 || 'unset' }}
      onClick={() => { if (DrawerService.open) { DrawerService.toggle(); } setTimeout(() => { url.startsWith('http') ? Navigation.site(url) : Navigation.navigate(url); }, 20) }}
    >
      {icon && <i className={icon} style={{ width: 16, textAlign: 'center' }}></i>}{text}
    </Selection>
  }
  render() {
    const { isLoggedIn, isDevAccount } = UserAccount;
    return (
      <Wrapper>
        <Scrollable
          vertical
          style={{ position: 'absolute', inset: 0 }}
        >
          <ContentWrapper>
            <LogoWrapper style={{ alignItems: 'center', padding: '0.25rem 0 1.5rem 18px' }}>
              <i onClick={() => DrawerService.toggle()} className="fas fa-bars"></i>
              <Logo>
                <ClickableDiv onClick={() => { if (DrawerService.open) { DrawerService.toggle(); } Navigation.push(Navigation.home); }}>
                  <img alt={logo} src={logo}></img>
                </ClickableDiv>
              </Logo>
            </LogoWrapper>
            {this.getDiv('/', 'Home', 'fas fa-home')}
            {this.getDiv('/users', 'Users', 'fas fa-users')}
            {/* {this.getDiv('/experts', 'Experts', 'fas fa-user-check')} */}
            {isLoggedIn && this.getDiv('/account', 'Account', 'fas fa-user')}
            <Divider />
            {this.getDiv('/collections', 'Collections', 'fas fa-images')}
            {[
              { title: 'Trending', url: 'trending' },
              { title: 'Logic', url: 'logic' },
              { title: 'World Logic Day', url: 'wld' },
              { title: 'Sustainable Development Goals', url: 'sdgs' },
              { title: 'Community', url: 'community' },
            ].map(({ title, url }) => this.getDiv(`/collections?t=${url}`, title))}
            <Divider />
            {this.getDiv('/account/settings', 'Settings')}
            {this.getDiv('/account/notifications', 'Notifications')}
            {this.getDiv('/legal/privacy', 'Privacy Policy')}
            {this.getDiv('/legal/terms', 'Terms of Service')}
            {this.getDiv('https://app.logictry.com', 'Content Editor')}
            {this.getDiv('https://business.logictry.com', 'For Businesses')}
            {isDevAccount && <div style={{ padding: '0.5rem 1rem' }}>v{Version.version}</div>}
          </ContentWrapper>
        </Scrollable>
      </Wrapper>
    )
  }
}

