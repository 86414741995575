/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import LoadingIndicator from '../../components/LoadingIndicator';
import Constants from '../../submodules/logictry_config/constants';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import TreeCache from '../../services/cache/TreeCache';
import Navigation from '../../services/Navigation';
import { getTemplateProjects } from '../../services/Pagination';
import TemplateProjectsBox from '../TreePage/TemplateProjectsBox';
import ListViewGridViewSelect from '../TreePage/ListViewGridViewSelect';
import TreeDisplay from '../../services/TreeDisplay';
import TreeAnswerBox from '../TreeAnswerPage/TreeAnswerBox';
import SelectMenu from '../../components/SelectMenu';
import { SEARCH_LOGIC } from '../../models/nodetypes';
import Popup from '../../components/Popup';
import ClickableIcon from '../../components/ClickableIcon';
import WordCloud from './WordCloud';
import ExportCSVButton from '../../components/ExportCSVButton';
import SpreadsheetResults from './SpreadsheetResults';
import Scrollable from '../../components/Scrollable/index';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;
const AnalyticsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
// const ShowSettings = styled.div`
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   * {
//     padding: 6px;
//   }
// `;
const SettingsMenu = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin: 0px 20px;
  }
`;
const SettingsWrapper = styled.div`
  padding: 20px;
`;
const SettingCheckbox = styled.div`

`;
const LoadMoreProjects = styled.div`

`;
const AnalyticsBox = styled.div`
  padding: 20px;
  margin: 20px 0px 0px 20px;
  background-color: ${Constants.StaticGray};
  text-align: center;
`;
const ShowMore = styled.button``;

const templateProjectsOptions = [{ name: 'App', value: 'App' }, { name: 'Spreadsheet', value: 'Spreadsheet' }, { name: 'Submissions', value: 'Submissions' }];
const lastProjectCountOptions = [{ name: 'Last 12', value: 'Last12' }, { name: 'Last 24', value: 'Last24' }, { name: 'Last 36', value: 'Last36' }, { name: 'Last 48', value: 'Last48' }];
let templateProjectsPageType = 'App';

export default class TemplateProjectsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const treeId = Navigation.currentLocation[2];
    this.pagination = getTemplateProjects(treeId);
  }
  state = {
    count: 'Last12',
    showSettings: false,
    hideEmptyProjects: false,
  }
  componentDidMount() {
    TreeCache.onStateUpdate(this);
    this.pagination.onStateUpdate(this);
  }
  componentWillUnmount() {
    TreeCache.offStateUpdate(this);
    this.pagination.offStateUpdate(this);
    if (this.analyticsTree) this.analyticsTree.offStateUpdate(this);
  }
  updateState = (type) => {
    templateProjectsPageType = type;
    this.forceUpdate();
  }
  
  render() {
    const { showSettings, hideEmptyProjects, count } = this.state;
    const treeId = Navigation.currentLocation[2];
    const tree = TreeCache.getTree(treeId);
    if (!tree || tree.loading) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (tree.error) return <FullScreenVerticalCenterContent><h1>App Not Found</h1></FullScreenVerticalCenterContent>;

    const { isOwnerOrAdmin, isViewer } = tree;
    if (!(isOwnerOrAdmin || isViewer)) return <FullScreenVerticalCenterContent><h1>User not authorized</h1></FullScreenVerticalCenterContent>;

    if (this.pagination.currentPage.length === 0) {
      if (this.pagination.allFound) return <FullScreenVerticalCenterContent><h1>No Submissions Yet</h1></FullScreenVerticalCenterContent>;
      return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    }
    
    // If the analytics tree doesn't exist, create it
    if (!this.analyticsTree) {
      this.analyticsTree = TreeCache.createObject({ ...tree.getTreeTemplateJson(), type: 'MultipleProjects' });
      this.analyticsTree.onStateUpdate(this);
      TreeDisplay.setTree(this.analyticsTree);
      this.lastPage = 0;
      this.projectAnalytics = {};
    }

    // If there is a new page, apply to the analytics tree
    if (this.lastPage !== this.pagination.currentPage.length) {
      this.pagination.currentPage.slice(this.lastPage).forEach((_tree) => {
        this.analyticsTree.addMultipleProjects(_tree.getTreeProjectJson());
        this.projectAnalytics[_tree._id] = _tree.projectAnalytics;
      });
      this.lastPage = this.pagination.currentPage.length;
      this.totalCompletedProjects = 0;
      this.totalCompletionTime = 0;
      this.totalTotalAnswerCount = 0;
      Object.values(this.projectAnalytics)
        .forEach(({ startTime, endTime, totalAnswerCount }) => {
          if (totalAnswerCount === 0) return;
          this.totalTotalAnswerCount += totalAnswerCount;
          this.totalCompletedProjects += 1;
          this.totalCompletionTime = this.totalCompletionTime + (endTime - startTime) / 1000;
        });
      this.averageAnswerCount = Math.round(this.totalTotalAnswerCount / this.totalCompletedProjects);
      this.averageCompletionTime = (this.totalCompletionTime / this.totalCompletedProjects).toFixed(2);
      this.averageCompleted = Math.round((this.totalCompletedProjects / this.lastPage) * 100);
    }

    const keyWordsFound = {};
    const searchLogics = this.analyticsTree.root.__recursivelyGetAllNodesByType(this.analyticsTree.root, SEARCH_LOGIC);
    searchLogics.forEach(({ keywordsFoundBySearch }) => {
      if (!keywordsFoundBySearch) return;
      Object.keys(keywordsFoundBySearch).forEach((key) => {
        keyWordsFound[key] = keywordsFoundBySearch[key];
      });
    });
    const { showMore, allFound } = this.pagination;
    const filename = tree.title.text || tree._id;
    const shouldShowMore = showMore || !allFound;
    return (
      <Scrollable
        vertical
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <DrawerBreadcrumb key={`${templateProjectsPageType}${this.lastPage}${shouldShowMore}`}>
          <SettingsMenu>
            <SelectMenu value={templateProjectsPageType} onChange={this.updateState} options={templateProjectsOptions} />
            {['Submissions'].includes(templateProjectsPageType) && <ListViewGridViewSelect />}
            <ExportCSVButton filename={filename} tree={this.analyticsTree} trees={this.pagination.currentPage} />
            <div>{this.lastPage} loaded</div>
            {(shouldShowMore) && <ShowMore onClick={() => this.pagination.getMore()}>Show More</ShowMore>}
          </SettingsMenu>
        </DrawerBreadcrumb>
        {(templateProjectsPageType === 'App') ? (
          <Wrapper>
            <TreeAnswerBox key={this.analyticsTree.key} tree={this.analyticsTree} />
          </Wrapper>
        ) : (templateProjectsPageType === 'Analytics') ? (
          <div>
            {Object.keys(keyWordsFound).length > 0 && <WordCloud words={keyWordsFound} />}
            <AnalyticsWrapper>
              <AnalyticsBox><h3>Total Submissions Loaded</h3><div>{this.lastPage}</div></AnalyticsBox>
              <AnalyticsBox><h3>Total Submissions Completed</h3><div>{this.totalCompletedProjects}</div></AnalyticsBox>
              <AnalyticsBox><h3>Average Completed</h3><div>{this.averageCompleted}%</div></AnalyticsBox>
              <AnalyticsBox><h3>Average Completion Time</h3><div>{this.averageCompletionTime}s</div></AnalyticsBox>
              <AnalyticsBox><h3>Average Answer Count</h3><div>{this.averageAnswerCount}</div></AnalyticsBox>
            </AnalyticsWrapper>
          </div>
        ) : (templateProjectsPageType === 'Spreadsheet') ? (
          <SpreadsheetResults count={this.lastPage} pagination={this.pagination} analyticsTree={this.analyticsTree} />
        ) : (
          <FullScreenVerticalCenterContent alignTop>
            <div>
              <TemplateProjectsBox paginationService={this.pagination} />
            </div>
          </FullScreenVerticalCenterContent>
        )}
        {showSettings && <Popup
          allowBackgroundClose
          onClose={() => { this.setState({ showSettings: false }); }}
        >
          <SettingsWrapper>
            <h2>Settings</h2>
            <LoadMoreProjects>{`Currently showing ${this.lastPage} submissions`}</LoadMoreProjects>
            <SelectMenu value={count} onChange={this.updateState} options={lastProjectCountOptions} />
            <SettingCheckbox><ClickableIcon onClick={this.emailNotifications} icon={(hideEmptyProjects)? 'fas fa-check-square' : 'far fa-square'} /><div>Hide Empty Submissions?</div></SettingCheckbox>
            <SettingCheckbox><ClickableIcon onClick={this.emailNotifications} icon={(hideEmptyProjects)? 'fas fa-check-square' : 'far fa-square'} /><div>Hide Empty Submissions?</div></SettingCheckbox>
          </SettingsWrapper>
        </Popup>}
      </Scrollable>
    );
  }
}
