/* eslint-disable no-unused-expressions */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import LoadingIndicator from '../../components/LoadingIndicator';
import Constants from '../../submodules/logictry_config/constants';
import Button from '../../components/Button/index';
import preventDefault from '../../utils/preventDefault';
import ContentDisplay from '../../services/ContentDisplay';
import UserCache from '../../services/cache/UserCache';
import Company from '../../services/Company';
import WindowSize from '../../services/WindowSize';
import UserAvatar from '../../components/UserAvatar';
import UsersBox from '../../components/UsersBox/index';
import Scrollable from '../../components/Scrollable';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const CreateNewBox = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Constants.BoxRadius}px;
  border: 1px dashed black;
  cursor: pointer;
  margin: 0.75rem;
`;
const CreateNewList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
  cursor: pointer;
  padding: 0.25rem 0;

  > div:first-child {
    border: 1px dashed black;
    border-radius: 8px;
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 10px;
  }
  > div:last-child {
    flex: 1;
  }
`;
const TreeItem = styled.div`
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const TreeList = styled(TreeItem)`
  position: relative;
  display: flex;
  align-items: center;
  color: ${Constants.DarkTextColor};
  padding: 0.25rem 0;
  width: 100%;
  > i:last-child {
    top: 0px;
    margin-right: -12px;
  }

  > div:first-child {
    border-radius: 8px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 10px;
    color: ${Constants.White};
    font-size: ${Constants.SmallFontSize};
    background-position: center !Important;
    background-size: contain !Important;
    background-repeat: no-repeat !Important;
  }
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 10px;
    white-space: nowrap;
    > div {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > div:first-child {
      font-size: 0.8125rem;
    }
    > div:nth-child(2) {
      color: ${Constants.LightTextColor};
    }
  }
`;
const BoxWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const TreeBox = styled(TreeItem)`
  border-radius: ${Constants.BoxRadius}px;
  position: relative;
  text-align: center;
  margin: 0.75rem;
  color: ${Constants.White};
  background-position: center !Important;
  background-size: contain !Important;
  background-repeat: no-repeat !Important;
  overflow: hidden;
  flex-shrink: 0;

  i {
    cursor: pointer;
    padding: 8px;
  }
  .box__displayonhover {
    ${Constants.MediaDesktop} {
      display: none;
    }
  }

  ${Constants.MediaDesktop} {
    transition: 0.3s box-shadow;
    &:hover {
      box-shadow: 0 1px 16px rgba(0,0,0,0.4);
      .box__displayonhover {
        display: block;
      }
    }
    > div:last-child {
      position: absolute;
      bottom: 4px;
    }
  }
`;
const EditTreeIcon = styled.div`
  position: absolute;
  top: 0px;
  right: 3px;
`;
const AddRemoveUsersIcon = styled.i`
  position: absolute;
  top: 4px;
  left: 7px;
`;
const LockedIcon = styled.i`
  position: absolute;
  top: 8px;
  left: calc(50% - 15px);
`;
const BoxUsersIcons = styled.div`
  position: absolute;
  bottom: 32px;
  left: 8px;
  right: 8px;
  pointer-events: none;
`;
const ListUsersIcons = styled.div`
`;
const BoxTitle = styled.div`
  position: absolute;
  top: 50px;
  max-height: 4rem;
  left: 8px;
  right: 8px;
  text-align: center;
  font-size: ${Constants.SemiSmallFontSize};
  color: ${Constants.White};
  line-height: 1.2;
`;
const GrayOverlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0,0,0,0.7);
  pointer-events: none;
  ${Constants.MediaMobileAndTablet} {
    display: none;
  }
`;
const BoxInitials = styled.div`
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  line-height: 1.2;
  * {
    text-align: center;
    font-size: 0.8125rem;
    color: ${Constants.White};
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    max-width: 100%;
    max-height: calc(100% - 3rem);
    line-height: 1.2;
  }
`;
const BoxProgress = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 16px;
  text-align: center;
  font-size: ${Constants.VerySmallFontSize};
  color: ${Constants.White};
  line-height: 1.2;
`;
const BoxTime = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 0px;
  text-align: center;
  font-size: ${Constants.VerySmallFontSize};
  color: ${Constants.White};
`;
const ShowMoreButton = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CreateDivWrapper = styled.div`

`;
const isColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
};

export default class Box extends React.PureComponent {
  static propTypes = {
    team: PropTypes.any,
    row: PropTypes.bool,
  }
  state = {
    showCreatePopup: null,
    showUsersbox: null,
    showOpenPopup: null,
  }
  componentDidMount() {
    this.paginationService.onStateUpdate(this);
    ContentDisplay.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    this.paginationService.offStateUpdate(this);
    ContentDisplay.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  createNew = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.create) this.create();
    else if (this.createNewPopup) this.setState({ showCreatePopup: true });
    return null;
  }
  hideCreatePopup = () => this.setState({ showCreatePopup: null });
  openTree = (e, _tree) => {
    if (!this.open && !this.openPopup) return;
    e.preventDefault();
    e.stopPropagation();
    if (this.open) this.open(_tree);
    else if (this.openPopup) this.setState({ showOpenPopup: this.openPopup(_tree) });
  }
  getBoxBackground(_tree) {
    return _tree.background && _tree.background.image && `url(${_tree.background.image})`|| _tree.background && isColor(_tree.background.color) && _tree.background.color || this.backgroundColor;
  }
  openUsersBox = (object) => {
    this.setState({ showUsersbox: object });
  }
  render() {
    const { mobile } = WindowSize;
    const { isAuthorizedToLogIn } = Company;
    let { gridOrList } = ContentDisplay;
    const { team, row } = this.props;
    if (row) gridOrList = 'grid';
    const gridSmall = gridOrList === 'grid';
    const gridLarge = gridOrList === 'grid-large';
    const { currentPage, showMore, allFound } = this.paginationService;

    if (!currentPage) return null;
    if (currentPage.some(({ loading }) => loading)) return null;
    const { showCreatePopup, showUsersbox, showOpenPopup } = this.state;
    const boxSize = gridSmall ? 140 : Constants.BoxSize;
    let fontSize = Constants.VerySmallFontSize;
    /**
     * Grid vs List View
     */
    let flexDirection;
    let width;
    let height;
    if (gridOrList === 'list') {
      flexDirection = 'column';
      width = '100%';
      fontSize = Constants.VerySmallFontSize;
    } else {
      flexDirection = 'row';
      width = boxSize;
      height = boxSize;
    }
    const userIds = new Set();
    currentPage.forEach((_tree) => {
      userIds.add(_tree.owner);
      _tree.users.forEach((_user) => {
        userIds.add(_user._id);
      });
    });
    const users = UserCache.getUsersByIds(Array.from(userIds));
    const ObjectSettings = this.objectSettings;
    const hideCreate = !isAuthorizedToLogIn
    if (currentPage.length === 0 && !allFound) return <BoxWrapper style={{ width: row ? width : '100%', height, margin: '1rem', padding: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><LoadingIndicator /></BoxWrapper>;
    return (
      <OuterWrapper style={{ width: '100%' }}>
        <Scrollable
          horizontal={row}
          showHorizontal
          vertical={!row}
          showVertical
          style={{
            paddingLeft: row && '2rem',
            paddingRight: row && '2rem',
            paddingTop: Constants.BoxRadius,
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection,
            flexWrap: row ? 'unset' : 'wrap',
          }}
        >
          {hideCreate && currentPage.length === 0 && <div style={{ margin: '1rem', textAlign: 'center' }}>No {this.name} Yet</div>}
          {!hideCreate && gridOrList !== 'list' && !this.props.invitedTo && this.createDiv && <BoxWrapper style={{ padding: '0.75rem' }}>
            <div style={{ pointerEvents: 'unset' }}>{this.createDiv(boxSize, boxSize, Constants.BoxRadius)}</div>
          </BoxWrapper>}
          {!hideCreate && gridOrList !== 'list' && !this.props.invitedTo && this.createText && this.createNew && <BoxWrapper>
            <CreateNewBox style={{ width, height, padding: '0.5rem' }} onClick={this.createNew}>{this.createText}</CreateNewBox>
          </BoxWrapper>}
          {gridOrList !== 'list' && currentPage.map((_tree) => {
            const background = this.getBoxBackground(_tree);
            const backgroundImageExists = _tree.background && _tree.background.image;
            return (
              <TreeBox key={_tree._id} role="button" tabIndex={-1} onClick={(e) => this.openTree(e, _tree)} style={{ width, height, background, padding: this.backgroundDiv ? 0 : 8, boxShadow: Constants.BoxShadow }}>
                {this.backgroundDiv && this.backgroundDiv(_tree, boxSize, boxSize, Constants.BoxRadius)}
                {!(backgroundImageExists) && (
                  <BoxInitials><div>{`${(_tree.title && _tree.title.text) || this.missingTitleText}${(_tree.description && _tree.description.text) ? `: ${_tree.description.text}` : ''}`}</div></BoxInitials>
                )}
                {_tree.formattedProgress && <BoxProgress style={{ fontSize }}>{_tree.formattedProgress()}</BoxProgress>}
                <BoxTime style={{ fontSize }}>{_tree.formattedUpdateTime}</BoxTime>
                <GrayOverlay className="box__displayonhover"></GrayOverlay>
                <EditTreeIcon className="box__displayonhover">
                  <ObjectSettings object={_tree} team={team} />
                </EditTreeIcon>
                {_tree.isOwnerOrAdmin && <AddRemoveUsersIcon role="button" tabIndex={-1} onClick={(e) => preventDefault(this.openUsersBox)(e, _tree)} className="fa fa-users box__displayonhover" aria-hidden="true" />}
                {users && !mobile && <BoxUsersIcons style={{ bottom: 8 }} className={'box__displayonhover'}>
                  <UserAvatar showShadow mini user={users.find((_user) => _user._id === _tree.owner)} />
                </BoxUsersIcons>}
              </TreeBox>
            )
          })}
          {!hideCreate && gridOrList === 'list' && !this.props.invitedTo && this.createDiv && <CreateDivWrapper style={{ width, height, padding: '0.25rem 0' }}><div style={{ width, height, pointerEvents: 'unset' }}>{this.createDiv(40, 40, 8)}</div></CreateDivWrapper>}
          {!hideCreate && gridOrList === 'list' && !this.props.invitedTo && this.createText && this.createNew && (
            <CreateNewList style={{ width, height }} onClick={this.createNew}>
              <div>
                <i className="fas fa-plus"></i>
              </div>
              <div>{this.createText}</div>
            </CreateNewList>
          )}
          {gridOrList === 'list' && currentPage.map((_tree) => {
            const background = this.getBoxBackground(_tree);
            const backgroundImageExists = _tree.background && _tree.background.image;
            return (
              <TreeList key={_tree._id} role="button" tabIndex={-1} onClick={(e) => this.openTree(e, _tree)} style={{ width, height }}>
                <div style={{ background }}>
                  {this.backgroundDiv && this.backgroundDiv(_tree, 40, 40, 8)}
                  {!this.backgroundDiv && !backgroundImageExists && <i className={this.boxIcon}></i>}
                </div>
                <div>
                  <div>{`${(_tree.title && _tree.title.text) || this.missingTitleText}${(_tree.description && _tree.description.text) ? `: ${_tree.description.text}` : ''}${_tree.formattedProgress  && _tree.formattedProgress(true) || ''}`}</div>
                  {!this.backgroundDiv && <div style={{ fontSize: Constants.VerySmallFontSize }}>{_tree.formattedUpdateTime}</div>}
                </div>
                {users && <ListUsersIcons>
                  <UserAvatar showShadow user={users.find((_user) => _user._id === _tree.owner)} />
                </ListUsersIcons>}
                <ObjectSettings object={_tree} team={team} />
              </TreeList>
            )
          })}
          {((showMore) && row) && <ShowMoreButton style={{ height: row ? '172px' : null, width: row ? '140px' : null, flexShrink: 0 }}>
            <Button onClick={() => this.paginationService.getMore()}>Show More</Button>
          </ShowMoreButton>}
        </Scrollable>
        {((showMore) && !row) && <ShowMoreButton>
          <Button onClick={() => this.paginationService.getMore()}>Show More</Button>
        </ShowMoreButton>}
        {showUsersbox && <UsersBox domainObject={showUsersbox} onClose={() => this.setState({ showUsersbox: null })} />}
        {showCreatePopup && this.createNewPopup()}
        {showOpenPopup}
      </OuterWrapper>
    );
  }
}
