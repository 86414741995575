import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import UserCache from '../../services/cache/UserCache';

const Version = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 0px;
  > * {
    width: 100%;
    // height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const VersionSelected = styled(Version)`
  background-color: rgb(242, 242, 242);
  > div {
    color: ${Constants.DarkTextColor};
  }
`;

export default class VersioningList extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    diffs: PropTypes.array,
    version: PropTypes.number,
    currentVersionDisplayed: PropTypes.number,
    showVersion: PropTypes.func,
    diffCache: PropTypes.object,
  };
  componentDidMount() {
    const { diffCache } = this.props;
    diffCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { diffCache } = this.props;
    diffCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
  }

  getUserFullname = (users, userId) => {
    if (!users || !userId) return '';
    const foundUser = users.find((_user) => _user._id === userId);
    return foundUser ? foundUser.fullname : '';
  }
  render() {
    const { node, diffs, version, currentVersionDisplayed, showVersion, diffCache } = this.props;
    if (!diffs || !version || version === 1) {
      const users = UserCache.getUsersByIds([node.owner]);
      if (!users) return null;
      return [
        <Version key={version}>
          <div>Current Version {version}</div>
          <div>{this.getUserFullname(users, node.owner)}</div>
          <div>{node.createdTime.toLocaleString()}</div>
        </Version>,
      ];
    }
    let totalDiffs = [...diffs];
    let newDiffs = true;
    while (newDiffs) {
      const nextVersion = version - totalDiffs.length;
      let nextDiffs = [];
      if (nextVersion > 1) {
        nextDiffs = diffCache.getDiffs(node._id, nextVersion);
        if (Array.isArray(nextDiffs)) {
          totalDiffs = [...totalDiffs, ...nextDiffs];
        }
      }
      if (nextDiffs.length === 0) newDiffs = false;
    }

    if (totalDiffs.length === 0) {
      return null;
    }
    totalDiffs.sort((a, b) => {
      if (a.version < b.version) return 1;
      if (b.version < a.version) return -1;
      return 0;
    });
    const userIdsToGet = Array.from(new Set([node.owner, ...totalDiffs.map((_diff) => _diff.owner).filter((_owner) => _owner)]));
    const users = UserCache.getUsersByIds(userIdsToGet);
    if (!users) return null;
    return [
      (!currentVersionDisplayed || currentVersionDisplayed === version) ? (
        <VersionSelected key={version} role="button" onClick={() => showVersion(version, [], 0)}>
          <div>Current Version {version}</div>
          <div>{this.getUserFullname(users, totalDiffs[0].owner)}</div>
          <div>{(new Date(totalDiffs[0].createdTime)).toLocaleString()}</div>
        </VersionSelected>
      ) : (
        <Version key={version} role="button" onClick={() => showVersion(version, [], 0)}>
          <div>Current Version {version}</div>
          <div>{this.getUserFullname(users, totalDiffs[0].owner)}</div>
          <div>{(new Date(totalDiffs[0].createdTime)).toLocaleString()}</div>
        </Version>
      ),
      totalDiffs.map((_diff, i) => {
        const diffVersion = _diff.version;
        if (!diffVersion) return null;
        let username;
        let timestamp;
        if (diffVersion === 1) {
          username = this.getUserFullname(users, node.owner);
          timestamp = node.createdTime ? (new Date(node.createdTime)).toLocaleString() : '';
        } else {
          const previousDiff = totalDiffs[i + 1];
          if (!previousDiff) return null;
          username = this.getUserFullname(users, previousDiff.owner);
          timestamp = previousDiff.createdTime ? (new Date(previousDiff.createdTime)).toLocaleString() : '';
        }
        if (diffVersion === currentVersionDisplayed) {
          return (
            <VersionSelected role="button" key={diffVersion} onClick={() => showVersion(diffVersion, totalDiffs, i + 1)}>
              <div>Version {diffVersion}</div>
              <div>{username}</div>
              <div>{timestamp}</div>
            </VersionSelected>
          );
        }
        return (
          <Version role="button" key={diffVersion} onClick={() => showVersion(diffVersion, totalDiffs, i + 1)}>
            <div>Version {diffVersion}</div>
            <div>{username}</div>
            <div>{timestamp}</div>
          </Version>
        );
      }),
    ];
  }
}
