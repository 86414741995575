/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Popup from '../Popup/index';
import CopyHtmlToClipboardButton from '../CopyHtmlToClipboardButton';
import EmbedCodePopup from '../ObjectSettings/EmbedCodePopup';
import Collection from '../../models/logicbasecollection';

const PopupContent = styled.div`
  width: 500px;
  text-align: center;
  max-width: 100%;
  padding: 2rem;
  a {
    text-decoration: none;
  }
  > div > div:nth-child(2) {
    display: flex;
    align-items: center;
    margin: 3rem 0;
    border: ${Constants.LightBorder};
    padding: 0 0.5rem;
    border-radius: 1rem;
    textarea {
      overflow: hidden;
      text-overflow: ellipsis;
      resize: none;
      white-space: nowrap;
    }
    > a {
      flex: 1;
      display: flex;
    }
  }
`;
const ShareButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  > * {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  > * > *:first-child {
    color: ${Constants.White};
    height: 60px;
    width: 60px;
    border-radius: 50%;
    line-height: 60px;
    text-align: center;
    font-size: ${Constants.SemiLargeFontSize};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  > * > *:last-child {
    font-size: ${Constants.SmallFontSize};
  }
  > *:nth-child(1) > div:first-child {
    background-color: ${Constants.PrimaryColor};
  }
  > *:nth-child(2) > div:first-child {
    background-color: ${Constants.FacebookBlue};
  }
  > *:nth-child(3) > div:first-child {
    background-color: black;
    svg {
      fill: white;
      height: 18px;
      margin: 12px;
    }
  }
  > *:nth-child(4) > div:first-child {
    background-color: ${Constants.LinkedInBlue};
  }
`;

export default class SocialSharePopup extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.object,
    onClose: PropTypes.func,
  }
  state= {
    nodeRef: null,
    showEmbedPopup: false,
  }
  render() {
    const { tree, onClose } = this.props;
    const { status, nodeRef, showEmbedPopup } = this.state;
    const shareUrl = tree instanceof Collection && `https://logictry.com/collections/${tree._id}`
      || `https://${window.location.hostname}/apps/${tree._id}`;
    const title = tree.title.text;
    const hashtag = 'logictry';
    return (
      <>
        <Popup showCloseButton allowBackgroundClose onClose={onClose}>
          <PopupContent>
            <div onClick={(e) => { e.stopPropagation(); }}>
              <h1>Share App</h1>
              <div>
                <textarea
                  rows="1"
                  style={{ margin: '1rem 0.5rem', fontSize: Constants.SemiSmallFontSize, resize: 'none', cursor: 'pointer', width: '100%' }}
                  key={nodeRef}
                  ref={(e) => { this.setState({ nodeRef: e }); }}
                  value={shareUrl}
                />
                <div>
                  <div style={{ marginLeft: '1rem', background: Constants.PrimaryColor, padding: '0.25rem 1rem 0.25rem 0.5rem', color: 'white', borderRadius: '1rem' }}>
                    <CopyHtmlToClipboardButton target={nodeRef} text="Copy" />
                  </div>
                </div>
              </div>
              <ShareButtons>
                <div onClick={() => this.setState({ showEmbedPopup: true })}>
                  <div>
                    <i className="fas fa-code"></i>
                  </div>
                  <div>Embed</div>
                </div>
                <a target="_blank" href={`https://www.facebook.com/sharer.php?u=${shareUrl}`}>
                  <div>
                    <i className="fab fa-facebook-f"></i>
                  </div>
                  <div>Facebook</div>
                </a>
                <a target="_blank" href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${title}&via=${hashtag}&hashtags=${hashtag}`}>
                  <div>
                    <svg viewBox="0 0 24 24" aria-hidden="true"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
                  </div>
                  <div>Twitter</div>
                </a>
                <a target="_blank" href={`https://www.linkedin.com/shareArticle?url=${shareUrl}`}>
                  <div>
                    <i className="fab fa-linkedin"></i>
                  </div>
                  <div>LinkedIn</div>
                </a>
              </ShareButtons>
              <div>{status}</div>
            </div>
          </PopupContent>
          {showEmbedPopup && <EmbedCodePopup domainObject={tree} onClose={() => this.setState({ showEmbedPopup: false })} />}
        </Popup>
      </>
    );
  }
}
