import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import UserAccount from '../../services/UserAccount';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import LoadingIndicator from '../../components/LoadingIndicator';
import Popup from '../Popup/index';
import BoxPopup from '../../containers/TreePage/BoxPopup';
import { getAccountCollections } from '../../services/Pagination/index';
import Scrollable from '../Scrollable';
import ConfirmDialog from '../ConfirmDialog';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem 3rem;
  h1 {
    text-align: center;
  }
  > div:last-child {
    padding: 1.5rem 0 2rem;
    width: 100%;
    > div {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
`;

export default class CollectionsPopup extends BoxPopup {
  static propTypes = {
    onClose: PropTypes.func,
    app: PropTypes.object,
  }
  state = {
    confirmRemove: false,
  }
  constructor() {
    super();
    const { account } = UserAccount;
    this.collections = getAccountCollections(account._id);
  }
  componentDidMount() {
    this.collections.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    this.collections.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
  }
  addRemove = (collection) => {
    const { app } = this.props;
    if (!app || !collection) return;
    if (collection.apps.includes(app._id)) {
      if (this.state.confirmRemove) {
        collection.apps = collection.apps.filter((id) => id !== app._id);
        this.setState({ confirmRemove: null });
      } else {
        return this.setState({ confirmRemove: collection });
      }
    } else {
      collection.apps.unshift(app._id);
    }
    return LogicBaseCollectionCache.update(collection);
  }
  onPagination = () => {
    if (this.collections.showMore && this.collections.allFound && this.collections.initialized) {
      this.collections.getMore();
    }
  }
  render() {
    const { onClose, app } = this.props;
    const { confirmRemove } = this.state;
    const { currentPage } = this.collections;
    if (!currentPage) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    return (
      <>
        <Popup
          showCloseButton
          allowBackgroundClose
          onClose={onClose}
        >
          <Scrollable
            vertical
            paginationHeight={200}
            onPagination={this.onPagination}
            style={{ width: '100%', maxHeight: 480 }}
          >
            <Wrapper key={currentPage.length}>
              <h1>Add / Remove Collections</h1>
              <div>
                {currentPage.map((collection) => (
                  <div key={collection._id}>
                    <div style={{ maxWidth: 260, wordBreak: 'break-word' }}>{collection.title}</div>
                    <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.addRemove(collection); }}>{collection.apps.includes(app._id) ? 'Remove' : 'Add'}</button>
                  </div>
                ))}
              </div>
            </Wrapper>
          </Scrollable>
        </Popup>
        <ConfirmDialog
          text="Are you sure you wish to remove?"
          open={!!confirmRemove}
          onNo={() => this.setState({ confirmRemove: false })}
          onYes={() => this.addRemove(confirmRemove)}
        />
      </>
    );
  }
}
