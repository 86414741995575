import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import TreeDisplay from '../../services/TreeDisplay';
import SelectMenu from '../../components/SelectMenu/index';
import { DATE_ATTR, DATE_TIME_ATTR, TIME_ATTR } from '../../models/nodeattributes';

const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const Days = [];
for (let i = 1; i < 32; i += 1) {
  Days.push(i);
}
const Years = [];
for (let i = 1970; i < 2050; i += 1) {
  Years.push(i);
}
const Hours = [];
for (let i = 1; i < 13; i += 1) {
  Hours.push(i);
}
const Minutes = [];
for (let i = 0; i < 60; i += 1) {
  Minutes.push(i)
}
const AMPM = ['AM', 'PM'];

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
const I = styled.i`
  padding: 0px 10px;
`;
const CalendarButton = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: 10px;
  }
`;

export default class FocusableInput extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  componentDidMount() {
    const { node } = this.props;
    const { parent } = node;
    node.onStateUpdate(this);
    parent.onStateUpdate(this);
    this.__parent = parent;
    TreeDisplay.onStateUpdate(this.checkFocus);
    this.checkFocus();
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
    this.__parent.offStateUpdate(this);
    TreeDisplay.offStateUpdate(this.checkFocus);
  }
  onChangeMonth = (month) => {
    const date = this.parseDate();
    date.setMonth(month - 1);
    this.updateDate(date);
  }
  onChangeDay = (day) => {
    const date = this.parseDate();
    date.setDate(day);
    this.updateDate(date);
  }
  onChangeYear = (year) => {
    const date = this.parseDate();
    date.setFullYear(year);
    this.updateDate(date);
  }
  onChangeHour = (year) => {
    const date = this.parseDate();
    const setHour = date.getHours() > 12 ? parseInt(year, 10) + 12 : parseInt(year, 10);
    date.setHours(setHour === 24 ? 0 : setHour);
    this.updateDate(date);
  }
  onChangeMinute = (year) => {
    const date = this.parseDate();
    date.setMinutes(year);
    this.updateDate(date);
  }
  onChangeAMPM = (year) => {
    const date = this.parseDate();
    let currentHours = date.getHours();
    if (year === 'AM' && currentHours > 12) currentHours -= 12;
    else if (year === 'AM' && currentHours === 0) currentHours = 12;
    else if (year === 'PM' && currentHours > 0 && currentHours < 13) currentHours += 12;
    date.setHours(currentHours === 24 ? 0 : currentHours);
    this.updateDate(date);
  }
  updateDate = (date) => {
    const { node } = this.props;
    const { parent } = node;
    const { userInputType } = parent;
    var dateString = date.toLocaleDateString('en-US');
    var timeString = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    node.updateText(userInputType === DATE_ATTR && dateString || userInputType === TIME_ATTR && timeString || userInputType === DATE_TIME_ATTR && `${dateString}, ${timeString}`);
  }
  onFocus = () => {
    const { node } = this.props;
    TreeDisplay.addActiveObject(node);
  }
  checkFocus = () => {
    const { node } = this.props;
    if (TreeDisplay.isActive(node) && this.ref) {
      this.ref.focus({ preventScroll: true });
    }
  }
  onRef = (e) => { this.ref = e; }
  getMonthDropdown = (month) => (
    <SelectMenu noPadding value={month.toString()} options={Months.map((m, i) => ({ name: m, value: (i + 1).toString() }))} onChange={this.onChangeMonth} />
  );
  getDayDropdown = (day) => (
    <SelectMenu noPadding value={day.toString()} options={Days.map((m, i) => ({ name: m.toString(), value: (i + 1).toString() }))} onChange={this.onChangeDay} />
  );
  getYearDropdown = (year) => (
    <SelectMenu noPadding value={year.toString()} options={Years.map((m, i) => ({ name: m.toString(), value: m.toString() }))} onChange={this.onChangeYear} />
  );
  getHourDropdown = (hour) => (
    <SelectMenu noPadding value={hour.toString()} options={Hours.map((m, i) => ({ name: m.toString(), value: (i + 1).toString() }))} onChange={this.onChangeHour} />
  );
  getMinuteDropdown = (minute) => (
    <SelectMenu noPadding value={(minute < 10 ? `0${minute}` : minute).toString()} options={Minutes.map((m, i) => ({ name: (m < 10 ? `0${m}` : m).toString(), value: (i).toString() }))} onChange={this.onChangeMinute} />
  );
  getAMPMDropdown = (year) => (
    <SelectMenu noPadding value={year.toString()} options={AMPM.map((m) => ({ name: m.toString(), value: m.toString() }))} onChange={this.onChangeAMPM} />
  );
  parseDate = () => {
    const { node } = this.props;
    const { parent } = node;
    const { userInputType } = parent;
    const { text } = node;
    let date = new Date(text);
    if (text) {
      if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
        if (userInputType === DATE_ATTR) date = new Date(`${text}, ${(new Date()).toLocaleTimeString('en-US')}`);
        else if (userInputType === TIME_ATTR) date = new Date(`${(new Date()).toLocaleDateString('en-US')}, ${text}`);
      }
      if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
        date = new Date(parseInt(text, 10));
      }
    }
    if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
      date = new Date();
      date.setSeconds(0);
    }
    return date;
  }
  setTime = () => {
    const date = this.parseDate();
    this.updateDate(date);
  }
  unvote = () => {
    const { editing } = TreeDisplay;
    const { node } = this.props;
    if (editing) node.updateText('');
    else node.removeUserVote();
  }
  getUnVote = () => (
    <I role="button" className="fas fa-times" onClick={this.unvote} />
  )


  render() {
    const { editing } = TreeDisplay;
    const { node } = this.props;
    const { parent } = node;
    const { userInputType } = parent;

    if (editing && !node.text || !editing && !node.checked) {
      const text = userInputType === 'Time' && 'Set Time' || userInputType === 'Date' && 'Set Date' || 'Set Date and Time';
      return (
        <Wrapper tabIndex={-1} ref={this.onRef} onClick={this.setTime}>
          <CalendarButton role="button"><i className="fas fa-calendar-alt"></i><div>{text}</div></CalendarButton>
        </Wrapper>
      );
    }

    const date = this.parseDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (userInputType === 'Time') {
      return (
        <Wrapper tabIndex={-1} ref={this.onRef}>
          {this.getHourDropdown(hours === 0 && 12 || hours > 12 && hours - 12 || hours)}
          {this.getMinuteDropdown(minutes)}
          {this.getAMPMDropdown((hours === 0 || hours > 12) ? 'PM' : 'AM')}
          {this.getUnVote()}
        </Wrapper>
      )
    }

    if (userInputType === 'Date') {
      return (
        <Wrapper tabIndex={-1} ref={this.onRef}>
          {this.getMonthDropdown(date.getMonth() + 1)}
          {this.getDayDropdown(date.getDate())}
          {this.getYearDropdown(date.getFullYear())}
          {this.getUnVote()}
        </Wrapper>
      )
    }

    return (
      <Wrapper tabIndex={-1} ref={this.onRef}>
        {this.getMonthDropdown(date.getMonth() + 1)}
        {this.getDayDropdown(date.getDate())}
        {this.getYearDropdown(date.getFullYear())}
        {this.getHourDropdown(hours === 0 && 12 || hours > 12 && hours - 12 || hours)}
        {this.getMinuteDropdown(minutes)}
        {this.getAMPMDropdown((hours === 0 || hours > 12) ? 'PM' : 'AM')}
        {this.getUnVote()}
      </Wrapper>
    );
  }
}
