import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import WindowSize from '../../services/WindowSize';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import UserCache from '../../services/cache/UserCache';
import LogicBaseRelationshipCache from '../../services/cache/LogicBaseRelationshipCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import { getUserFollowers, getUserFollowees, getUserFollowRequests } from '../../services/Pagination';
import UserProfileHeader from '../../components/UserProfileHeader';
import Scrollable from '../../components/Scrollable';
import UserAvatar from '../../components/UserAvatar';
import LogicBaseTab from '../../components/LogicBaseTab';

const NotificationWrapper = styled.div`
  max-width: 52rem;
  margin: auto;
  display: flex;
  flex-direction: column;

  > h1 {
    margin: 10px;
  }
`;
const Request = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  > * {
    margin: 0.5rem;
    ${Constants.MediaMobile} {
      margin: 0.5rem auto;
    }
  }
  > div:nth-child(2) {
    flex: 1;
  }
`;

export default class LogicBaseFollowPage extends React.PureComponent {
  constructor() {
    super();
    this.requested = getUserFollowRequests(UserAccount.account._id);
  }
  state = {
    page: Navigation.currentLocation.slice(-1)[0],
    username: ((Navigation.currentLocation[1] === 'users' ? Navigation.currentLocation[2] : Navigation.currentLocation[1].slice(1)) || '').toLowerCase(),
    isAccount: UserAccount.isLoggedIn && Navigation.currentLocation[1] === 'account'
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this.userUpdated);
    this.userUpdated();
    this.requested.onStateUpdate(this);
  }
  componentWillUnmount() {
    if (this.followers) this.followers.offStateUpdate(this);
    if (this.followees) this.followees.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this.userUpdated);
    this.requested.offStateUpdate(this);
  }
  onPagination = () => {
    const { page } = this.state;
    const userPaginations = page === 'follow-requests' ? this.requested : page === 'followers' ? this.followers : this.followees;
    if (userPaginations.showMore && userPaginations.allFound && userPaginations.initialized) {
      userPaginations.getMore();
    }
  }
  userUpdated = () => {
    const { username, isAccount } = this.state;
    if (isAccount) {
      this.user = UserAccount.account;
    } else if (username) {
      const users = UserCache.getUsers({ username });
      this.user = users && (users.length === 0 ? { error: 'NotFound' } : users[0]);
    }
    if (!this.user) return;
    this.followers = getUserFollowers(this.user._id);
    this.followers.onStateUpdate(this);
    this.followees = getUserFollowees(this.user._id);
    this.followees.onStateUpdate(this);
    this.forceUpdate();
  }
  approveDeny = (user, approve) => {
    if (approve) {
      user.requested = false
      LogicBaseRelationshipCache.update(user);
    } else {
      LogicBaseRelationshipCache.delete(user);
    }
  }
  render() {
    const { mobile } = WindowSize;
    const { page, isAccount } = this.state;
    if (!this.followers || !this.followees) return null;
    const followers = this.followers.currentPage.map(({ follower }) => follower);
    const followees = this.followees.currentPage.map(({ followee }) => followee);
    this.users = UserCache.getUsersByIds(page === 'follow-requests' && this.requested.currentPage.map(({ follower }) => follower) || page === 'followers' && followers || followees) || this.users;
    if (!this.users) return null;
    const tabs = [
      { name: 'followers', value: 'Followers' },
      { name: 'following', value: 'Following' }
    ];
    if (isAccount) tabs.unshift({ name: 'follow-requests', value: 'Follow Requests' });
    const onChange = (page) => {
      if (Constants.isIphone) this.setState({ page })
      else Navigation.replace(`${(isAccount && '/account') || Navigation.userProfile(this.user)}/${page}`)
    }
    return (
      <Scrollable
        key={page}
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: mobile ? '1rem 1rem 2rem' : '1rem 2rem 2rem' }}
        rememberScrollPosition={`logicbasefollow`}
      >
        <NotificationWrapper>
          <FullScreenVerticalCenterContent>
            <div style={{ marginBottom: '2rem' }}>
              <LogicBaseTab tabs={tabs} selected={page} onChange={onChange} />
            </div>
            {page === 'follow-requests' ? (this.requested.currentPage.length > 0 && this.requested.currentPage.map((follower) => {
              const user = this.users.find(({ _id }) => follower.follower === _id);
              if (!user) return null;
              return (
                <Request key={user._id}>
                  <UserAvatar onClick={() => Navigation.push(Navigation.userProfile(user))} user={user} />
                  <div onClick={() => Navigation.push(Navigation.userProfile(user))}>{user.fullname}</div>
                  {follower.requested && (
                    <>
                      <button style={{ cursor: 'pointer', color: 'white', background: Constants.PrimaryColor, padding: '0.25rem 0.5rem', borderRadius: '0.5rem', margin: 0 }} onClick={() => this.approveDeny(follower, true)}>Approve</button>
                      <button style={{ cursor: 'pointer', color: 'white', background: Constants.PrimaryColor, padding: '0.25rem 0.5rem', borderRadius: '0.5rem', margin: '0 0 0 0.5rem' }} onClick={() => this.approveDeny(follower, false)}>Reject</button>
                    </>
                  ) || (
                    <div>Approved</div>
                  )}
                </Request>
              )
            }) || (
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div>{'No requests yet'}</div>
              </div>
            )) : this.users && this.users.length > 0 ? this.users.map((user, i) => {
              return (
                <Request key={user._id}>
                  <UserProfileHeader
                    user={user}
                    large
                  />
                </Request>
              )
            }) : (
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div>{page === 'followers' ? 'No followers yet' : 'Not following anyone yet'}</div>
              </div>
            )}
          </FullScreenVerticalCenterContent>
        </NotificationWrapper>
      </Scrollable>
    );
  }
}
