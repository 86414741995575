import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import LogicBaseCollectionSubscriptionCache from '../../services/cache/LogicBaseCollectionSubscriptionCache';
import Constants from '../../submodules/logictry_config/constants';
import UserAccount from '../../services/UserAccount';
import StatefulButton from '../StatefulButton';
import Popup from '../Popup';
import { validateEmail } from '../../utils/regex';
import ConfirmDialog from '../ConfirmDialog';

const Wrapper = styled.div`
  max-width: 32rem;
  padding: 2rem 3rem;
  input {
    margin: 1rem 0px 2.5rem 0px;
    width: 100%;
    border-bottom: 2px solid ${Constants.PrimaryColor} !Important;
  }
  h1 {
    ${Constants.MediaMobile} {
      font-size: ${Constants.SemiLargeFontSize};
    }
  }
`;

export default class CollectionSubscribeButton extends React.PureComponent {
  static propTypes = {
    collection: PropTypes.object,
  }
  state = {
    warning: '',
    showPopup: false,
    confirmUnsubscribe: false
  }
  componentDidMount() {
    LogicBaseCollectionSubscriptionCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
  }
  componentWillUnmount() {
    LogicBaseCollectionSubscriptionCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
  }
  subscribe = (subscription, collection, owner) => {
    const { isLoggedIn } = UserAccount;
    if (!isLoggedIn && !owner) {
      return this.setState({ showPopup: true });
    }
    if (subscription) {
      if (this.state.confirmUnsubscribe) {
        this.setState({ confirmUnsubscribe: false })
        setTimeout(() => {
          LogicBaseCollectionSubscriptionCache.__deleteQueryFromCache({ fr: UserAccount.account._id, c: collection._id });
          LogicBaseCollectionSubscriptionCache.delete(subscription, null, true);
        });
        collection.followers -= 1;
        this.setState({ overrideRelationship: 'Subscribe' });
      } else {
        this.setState({ confirmUnsubscribe: true })
      }
    } else {
      setTimeout(() => {
        const newSubscription = { fr: owner || UserAccount.account._id, c: collection._id };
        LogicBaseCollectionSubscriptionCache.__deleteQueryFromCache(newSubscription);
        LogicBaseCollectionSubscriptionCache.create(LogicBaseCollectionSubscriptionCache.createObject(newSubscription), true);
      });
      collection.followers += 1;
      this.setState({ overrideRelationship: '✓ Subscribed' });
    }
  }
  subscribeLoggedOut = (subscription, collection) => {
    const email = this.inputRef.value;
    if (email === '') return this.setState({ warning: 'Email blank' });
    if (validateEmail(email)) return this.setState({ warning: 'Invalid email' });
    UserAccount.registerAccount({ email, reply: 'LoggedOutSave' }).then((r) => {
      this.setState({ showPopup: false, warning: '' });
      if (r && r._id) this.subscribe(subscription, collection, r._id);
    });
  }
  render() {
    const { collection } = this.props;
    const { warning, showPopup, confirmUnsubscribe } = this.state;
    const subscription = (UserAccount.isLoggedIn && LogicBaseCollectionSubscriptionCache.query({ fr: UserAccount.account._id, c: collection._id }, null, true) || [])[0];
    return (
      <>
        <div style={{ cursor: 'pointer', backgroundColor: 'rgb(242, 242, 242)', color: 'black', padding: '0.25rem 1rem', borderRadius: '0.5rem', flexShrink: 0 }} onClick={() => this.subscribe(subscription, collection)}>{this.state.overrideRelationship ? this.state.overrideRelationship : subscription ? '✓ Subscribed' : 'Subscribe'}</div>
        {showPopup && <Popup
          showCloseButton
          allowBackgroundClose
          onClose={() => { this.setState({ showPopup: false }) }}
        >
          <Wrapper>
            <h1>Subscribe to {collection.title}</h1>
            <p>Stay up-to-date as new interactive resources are added!</p>
            <input
              ref={(e) => { this.inputRef = e; }}
              type="email"
              name="email"
              autoComplete="true"
              placeholder="Email"
            />
            <StatefulButton
              variant="raised"
              onClick={() => this.subscribeLoggedOut(subscription, collection)}
              text="Submit"
              warning={warning}
            />
          </Wrapper>
        </Popup>}
        {confirmUnsubscribe && <ConfirmDialog allowBackgroundClose text="Are you sure you wish to unsubscribe?" description="You will no longer recieve updates from this collection." open onNo={() => this.setState({ confirmUnsubscribe: false })} onYes={() => this.subscribe(subscription, collection)} />}
      </>
    )
  }
}
