import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Popup from '../Popup/index';
import Button from '../Button/index';
import StatefulTextField from '../StatefulTextField';
import LinkCache from '../../services/cache/LinkCache';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem 3rem;
  max-width: 500px;
  word-break: break-all;

  > div {
    > p:first-child {
      text-decoration: underline;
    }
  }
`;
const ButtonsWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;


export default class LinksPopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    domainObject: PropTypes.object,
  }
  onSave = () => {
    const { onClose, domainObject } = this.props;
    LinkCache.update(domainObject);
    return onClose();
  }
  render() {
    const { onClose, domainObject } = this.props;
    const { title, description, link } = domainObject;
    return (
      <div>
        <Popup
          onClose={onClose}
          allowBackgroundClose
        >
          <Wrapper>
            <h1>Link Settings</h1>
            <div>
              <p>The short link:</p>
              <p>{`${window.location.origin}/links/${domainObject._id}`}</p>
            </div>
            <StatefulTextField
              value={title.text || ''}
              onChange={(val) => {
                title.text = val;
                this.forceUpdate();
              }}
              label="Title"
              maxLength={256}
              width={'100%'}
            />
            <StatefulTextField
              value={description.text || ''}
              onChange={(val) => {
                description.text = val;
                this.forceUpdate();
              }}
              label="Description"
              maxLength={256}
              width={'100%'}
            />
            <StatefulTextField
              value={link || ''}
              onChange={(val) => {
                domainObject.link = val;
                this.forceUpdate();
              }}
              label="Redirect to Url"
              maxLength={256}
              width={'100%'}
            />
            <ButtonsWrapper>
              <Button onClick={onClose} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={this.onSave} color="primary" autoFocus>
                Save
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </Popup>
      </div>
    );
  }
}
