import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import { PropTypes } from 'prop-types';
import Scrollable from '../Scrollable';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import LogicBaseCollectionRow from '../LogicBaseCollectionRow';

const Apps = styled.div`
  inset: 0;
  overflow: hidden;
  > div {
    flex: 1;
    width: 100%;
  }
`;
const Content = styled.div`
  h2 {
    font-size: ${Constants.LargeFontSize};
    ${Constants.MediaMobile} {
      font-size: ${Constants.SemiLargeFontSize};
    }
    font-weight: 500;
  }
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  ${Constants.MediaMobile} {
    gap: 2rem;
  }
`;

export default class LogicBaseAppLists extends React.PureComponent {
  static propTypes = {
    showUser: PropTypes.bool,
    menuLeft: PropTypes.bool,
  }
  state = {
    q: Navigation.currentLocation[1] === 'search' && Navigation.currentLocation[2] && unescape(Navigation.currentLocation[2]) || Navigation.currentSearch.q || ''
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
  }
  render() {
    const { mobile } = WindowSize;
    const { q } = this.state;
    const showCollections = q && LogicBaseCollectionCache.query({ search: q });
    return (
      <Apps style={{ position: mobile ? 'relative' : 'absolute' }}>
        <Scrollable
          vertical={!mobile}
          paginationHeight={400}
          style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: mobile ? '0 1rem' : '0 2rem' }}
          rememberScrollPosition={`logicbaseapplists`}
        >
          <Content>
            {showCollections && showCollections.map((collection) => <LogicBaseCollectionRow
              key={collection._id}
              collection={collection}
            />)}
          </Content>
        </Scrollable>
      </Apps>
    );
  }
}
