/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import NotFoundPage from '../../containers/NotFoundPage';
import LoginPage from '../../containers/LoginPage';
import RegisterPage from '../../containers/RegisterPage';
import Constants from '../../submodules/logictry_config/constants';
import SettingsPage from '../../containers/SettingsPage';
import CompanySettingsPage from '../../containers/CompanySettingsPage';
import EmailPage from '../../containers/EmailPage';
import ResetPasswordPage from '../../containers/ResetPasswordPage';
import Navigation from '../../services/Navigation';
import NotificationPage from '../../containers/NotificationsPage';
import Company from '../../services/Company';
import UserAccount from '../../services/UserAccount';
import TreeAnswerPage from '../../containers/TreeAnswerPage';
import TreeEditPage from '../../containers/TreeEditPage/index';
import TreePage from '../../containers/TreePage';
import ExportPage from '../../containers/ExportPage';
import SpreadsheetPage from '../../containers/SpreadsheetPage';
import DatabasePage from '../../containers/DatabasePage';
import DatabaseCollectionPage from '../../containers/DatabaseCollectionPage';
import TeamPage from '../../containers/TeamPage/index';
import TemplateProjectsPage from '../../containers/TemplateProjectsPage/index';
import CompanyBillingPage from '../../containers/CompanyBillingPage/index';
import RegisterCompany from '../../containers/RegisterPage/RegisterCompany';
import LandingPage from '../../containers/LandingPage/index';
import AppLandingPage from '../../containers/AppLandingPage';

const LEGAL = [
  {
    url: 'controlling-terms',
    app: '5f385208916d230a6bdf43a3'
  },
  {
    url: 'definitions',
    app: '5f384f9d916d230a6bdf43a1'
  },
  {
    url: 'partner/advocate/agreement',
    app: '5f5a330c014f57074bd5e017'
  },
  {
    url: 'platform/terms',
    app: '5f385275916d230a6bdf43a4'
  },
  {
    url: 'platform/connect/terms',
    app: '5f385453916d230a6bdf43a5'
  },
  {
    url: 'privacy',
    app: '5f3841ce916d230a6bdf439f'
  },
  {
    url: 'professional-services/agreement',
    app: '5f38554b916d230a6bdf43a6'
  },
  {
    url: 'terms',
    app: '5f3843f7916d230a6bdf43a0'
  },
];

function MainRoutes() {
  Navigation.useNavigate = useNavigate();
  Navigation.location = useLocation();
  const { isWidget } = Constants;

  if (isWidget) return (
    <Routes key={window.location.href}>
      <Route path="apps/:id" element={<TreeAnswerPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
  if (['login', 'register', 'reset-password'].includes(Navigation.currentLocation[1])) {
    if (UserAccount.isLoggedIn) setTimeout(() => { Navigation.replace(Navigation.home); });
  }

  const { isAuthorizedToLogIn } = Company;
  const loggedIn = UserAccount.isLoggedIn;
  const showCompanyRoutes = loggedIn && Company.isExpected && isAuthorizedToLogIn;
  return (
    <Routes key={`${window.location.pathname}${window.location.search}${JSON.stringify(Navigation.location.state)}`}>
      {loggedIn && <Route path="account/notifications" element={<NotificationPage />} />}
      {loggedIn && <Route path="account/settings" element={<SettingsPage />} />}
      <Route path="apps/:id" element={<TreeAnswerPage />} />
      {loggedIn && <Route path="apps/:id/analytics" element={<TemplateProjectsPage />} />}
      {loggedIn && isAuthorizedToLogIn && <Route path="apps/:id/edit" element={<TreeEditPage />} />}
      {loggedIn && isAuthorizedToLogIn && <Route path="apps/:id/export" element={<ExportPage />} />}
      {showCompanyRoutes && <Route path="company/billing" element={<CompanyBillingPage />} />}
      {showCompanyRoutes && <Route path="company/settings" element={<CompanySettingsPage />} />}
      {loggedIn && isAuthorizedToLogIn && <Route path="databases/:id" element={<DatabasePage />} />}
      {loggedIn && isAuthorizedToLogIn && <Route path="databasecollections/:id" element={<DatabaseCollectionPage />} />}
      <Route path="documentation" element={<AppLandingPage app="61041601d3eb27746f7fc168" />} />
      <Route path="feedback" element={<AppLandingPage app="60de191f9146e50896bd87f2" />} />
      <Route path="legal" element={<AppLandingPage app="5f38503f916d230a6bdf43a2" />} />
      {LEGAL.map(({ url, app }) => (
        <Route key={url} path={`legal/${url}`} element={<AppLandingPage app={app} />} />
      ))}
      <Route path="login" element={<LoginPage />} />
      <Route path="partners" element={<AppLandingPage app="60fefe964a83ea5fa4c5e17a" />} />
      <Route path="register" element={<RegisterPage />} />
      {loggedIn && isAuthorizedToLogIn && <Route path="register-company" element={<RegisterCompany />} />}
      <Route path="request-demo" element={<AppLandingPage app="620d051b4ab9d614e2792b1f" />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      {loggedIn && isAuthorizedToLogIn && <Route path="spreadsheets/:id" element={<SpreadsheetPage />} />}
      <Route path="support" element={<AppLandingPage app="5f3826f140b0e0780da9afe7" />} />
      {showCompanyRoutes && <Route path="teams/:id" element={<TeamPage />} />}
      <Route path="templates" element={<AppLandingPage app="6357e561bb34735df70674e9" />} />
      <Route path="unsubscribe" element={<EmailPage />} />
      <Route path="tutorials" element={<AppLandingPage app="6351498f2ba65e349b54912f" />} />
      <Route path="versions" element={<AppLandingPage app="5f383671916d230a6bdf439e" />} />
      <Route path="videos" element={<AppLandingPage app="5f444323d2070b7f59ae76bb" />} />
      <Route path="/" element={
        loggedIn && <TreePage />
        || <LandingPage />
      } />
      <Route path="*" element={(loggedIn && <NotFoundPage />) || <LandingPage />} />
    </Routes>
  );
}

export default MainRoutes;
