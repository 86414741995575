/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import Popup from '../Popup/index';
import Constants from '../../submodules/logictry_config/constants';
import UserAccount from '../../services/UserAccount';
import Button from '../Button/index';

const Wrapper = styled.div`
  // display: flex;
  max-width: 640px;

  h1 {
    margin-bottom: 1rem;
  }
  > div {
    padding: 2rem;
  }
`;
const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0px;
  input {
    cursor: pointer;
    margin-right: 10px;
  }
`
const AcceptButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const IDontAgree = styled(AcceptButton)`
  div {
    color: ${Constants.PrimaryColor};
    font-size: ${Constants.SemiSmallFontSize};
    text-decoration: underline;
  }
`;

class LogicBaseSettingsPopup extends React.PureComponent {
  state = {
    termsChecked: false,
  }
  componentDidMount() {
    UserAccount.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
  }
  accept = () => {
    const { account } = UserAccount;
    UserAccount.updateUserProfile({
      _id: account._id,
      legal: {
        terms: new Date(),
        privacy: new Date(),
        platform: {
          terms: new Date(),
        }
      }
    });
  }
  render() {
    return null;
    // if (!UserAccount.isLoggedIn) return null;
    const { termsChecked } = this.state;
    const { account } = UserAccount;
    const { legal } = account;
    // if (legal && legal.terms && legal.privacy && legal.platform && legal.platform.terms) return null;
    return (
      <Popup
        onClose={this.setTermsChecked}
        allowBackgroundClose
        showCloseButton
      >
        <Wrapper>
          <div>
            <div>
              <img alt="Logictry" style={{ height: 32 }} src={Constants.Logo} />
            </div>
            <p>Before continuing, we do need you to review our <a target="_blank" href="https://logictry.com/legal/terms">Terms of Service</a> to familiarize yourself with our terms and conditions.</p>
            <p>The expertise being shared, and their respective recommendations, should not be considered professional legal, medical, or financial advice and should not substitute the advice of experts, such as lawyers, doctors, and accountants.</p>
            <Checkbox><input type='checkbox' onClick={() => this.setState({ termsChecked: !termsChecked })} value={termsChecked}></input>I agree to the Terms of Service</Checkbox>
          </div>
        </Wrapper>
      </Popup>
    );
  }
}
export default LogicBaseSettingsPopup;
