import Service from './Service';
import Constants from '../submodules/logictry_config/constants';

const checkMobile = () => (window.innerWidth < Constants.MobileWidth);
const checkTablet = () => (window.innerWidth < Constants.TabletWidth);

let _mobile;
function updateMobile() {
  _mobile = checkMobile();
}

let _tablet;
function updateTablet() {
  _tablet = checkTablet();
}


const ua = window.navigator && window.navigator.userAgent || "";
// const isChromium = ua.indexOf("Chromium") !== -1;
const isWebkit = ua.indexOf("AppleWebKit") !== -1;
const isChrome = isWebkit && ua.indexOf("Chrome/") !== -1;
const isSafari = isWebkit && !isChrome;
// const isFirefox = ua.indexOf("Firefox/") !== -1;
// const isIE = ua.indexOf("MSIE ") !== -1 || ua.indexOf("Trident/") !== -1;
// let ieVersion = parseInt(ua.split("MSIE")[1], 10) || -1;
// if(isIE && ieVersion === -1 && ua.indexOf("Trident/7.0") !== -1) {
//   ieVersion = 11
// }

class WindowSize extends Service {
  get isSafari() {
    return isSafari;
  }
  get mobile() {
    if (_mobile === undefined) updateMobile();
    return _mobile;
  }
  get tablet() {
    if (_tablet === undefined) updateTablet();
    return _tablet;
  }

  updateDimensions() {
    if (checkMobile() !== _mobile || checkTablet() !== _tablet) {
      updateMobile();
      updateTablet();
      this.emitStateUpdate();
    }
  }
}

const singleton = new WindowSize();
updateMobile();
updateTablet();
window.addEventListener('resize', () => singleton.updateDimensions());
export default singleton;
