import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 4;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.65) 0px 1px 4px;
  padding: 2px 4px;
  img {
    height: 16px;
  }
`;

export default class PoweredByLogictry extends React.PureComponent {
  render() {
    const href = window.location.origin + window.location.pathname;
    return (
      <a target="_blank" href={href}>
        <Wrapper>
          <img alt="logictry" src="https://logictrystatic.s3.amazonaws.com/images/logo062623.svg" />
        </Wrapper>
      </a>
    );
  }
}
