/* eslint-disable no-unused-expressions */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import UsersBox from '../UsersBox';
import ConfirmDialog from '../ConfirmDialog';
import UserCache from '../../services/cache/UserCache';
import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import Navigation from '../../services/Navigation';
import TeamsBox from '../TeamsBox';
import CompanyBox from '../CompanyBox';
import Popup from '../Popup';
import MenuList from '../Popup/MenuList';
import MenuItem from '../Popup/MenuItem';
import SettingsBox from './SettingsBox';
import EmbedCodePopup from './EmbedCodePopup';
import SocialSharePopup from '../SocialShare/SocialSharePopup';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';

const Wrapper = styled.div`
  
`;
const MenuWrapper = styled.div`
  i {
    margin-right: 20px;
    justify-content: center;
    display: flex;
    width: 20px;
  }
`;
const SettingsIcon = styled.div`
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: relative;
`;

export default class ObjectSettings extends React.PureComponent {
  static propTypes = {
    object: PropTypes.any,
    team: PropTypes.any,
  }
  state = {
    showSettingsbox: null,
    showUsersbox: null,
    menuOpen: false,
    showEmbedCodePopup: false,
    showShareLinksPopup: false,
    error: null,
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
  }
  createNew = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.create) this.create();
    else if (this.createNewPopup) this.setState({ showCreatePopup: true });
    return null;
  }
  handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.anchorEl = e.target;
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen });
  }
  handleClose = () => {
    this.setState({ menuOpen: false });
  };
  hideCreatePopup = () => this.setState({ showCreatePopup: null });
  editObject = (e, object) => {
    e.preventDefault();
    e.stopPropagation();
    this.edit(object);
  }
  openSettingsBox = (object) => {
    this.setState({ menuOpen: false, showSettingsbox: object });
  }
  openUsersBox = (object) => {
    this.setState({ menuOpen: false, showUsersbox: object });
  }
  openTeamsBox = (object) => {
    this.setState({ menuOpen: false, showTeamsBox: object });
  }
  openCompanyBox = (object) => {
    this.setState({ menuOpen: false, showCompanyBox: object });
  }
  render() {
    const { object } = this.props;
    const { subscriptionVerified, isExpected } = Company;
    const { isDevAccount, account } = UserAccount;
    const { mobile } = WindowSize;
    const { showShareLinksPopup, showEmbedCodePopup, showSettingsbox, showUsersbox, showTeamsBox, showCompanyBox, showCreatePopup, confirmRestore, confirmDelete, error, confirmDuplicate } = this.state;
    let isOwner = false;
    let isOwnerOrAdmin = false;
    let isViewer = false;
    let isLocked = false;
    let isDeleted = false;
    if (object) {
      isOwner = object.isOwner;
      isOwnerOrAdmin = object.isOwnerOrAdmin;
      isViewer = object.isViewer;
      isLocked = object.isLocked;
      isDeleted = object.isDeleted
    }
    return (
      <Wrapper onClick={(e) => { e.stopPropagation(); }}>
        <SettingsIcon role="button" tabIndex={-1} onClick={(e) => this.handleToggle(e, object)}>
          <i style={{ pointerEvents: 'none' }} className="fas fa-ellipsis-h" />
        </SettingsIcon>
        {showSettingsbox && <SettingsBox domainObject={showSettingsbox} onClose={() => this.setState({ showSettingsbox: null })} />}
        {showUsersbox && <UsersBox domainObject={showUsersbox} onClose={() => this.setState({ showUsersbox: null })} />}
        {showTeamsBox && <TeamsBox domainObject={showTeamsBox} onClose={() => this.setState({ showTeamsBox: null })} />}
        {showCompanyBox && <CompanyBox domainObject={showCompanyBox} onClose={() => this.setState({ showCompanyBox: null })} />}
        {showEmbedCodePopup && <EmbedCodePopup domainObject={object} onClose={() => this.setState({ showEmbedCodePopup: false })} />}
        {showShareLinksPopup && <SocialSharePopup onClose={() => this.setState({ showShareLinksPopup: false })} tree={object} />}
        {showCreatePopup && this.createNewPopup()}
        <ConfirmDialog text="Are you sure you wish to duplicate?" description={null} open={!!confirmDuplicate} onNo={() => this.setState({ confirmDuplicate: false })} onYes={() => this.duplicate(object)} />
        <ConfirmDialog text="Are you sure you wish to restore?" description={null} open={!!confirmRestore} onNo={() => this.setState({ confirmRestore: false })} onYes={() => this.restore(object)} />
        <ConfirmDialog text="Are you sure you wish to delete?" description="This is permanent and cannot be undone." open={!!confirmDelete} onNo={() => this.setState({ confirmDelete: false })} onYes={() => this.delete(object)} />
        {error && <ConfirmDialog text={error.title} description={error.description} open onOk={() => this.setState({ error: null })} />}
        {this.state.menuOpen && <Popup
          allowBackgroundClose
          onClose={this.handleClose}
          anchorEl={this.anchorEl}
          anchorBottomRight
        >
          <MenuWrapper>
            <MenuList>
              {!isExpected && this.showOpenInApp && <MenuItem onClick={() => { window.location = `https://logictry.com${Navigation.apps}/${object._id}`; }}>
                <i class="fas fa-mobile-alt" style={{ fontSize: 17 }}></i>
                <div>Open in App</div>
              </MenuItem>}
              {!mobile && (this.edit || this.open) && <MenuItem onClick={() => { this.setState({ menuOpen: false }); (this.edit ? this.edit(object) : this.open(object)); }}>
                <i className="fas fa-edit" style={{ fontSize: 13 }}></i>
                <div>Edit</div>
              </MenuItem>}
              {!isDeleted && (isDevAccount || isOwnerOrAdmin) && <MenuItem onClick={() => this.openSettingsBox(object)}>
                <i className="fas fa-cog" style={{ fontSize: 15 }}></i>
                <div>Settings</div>
              </MenuItem>}
              {!isDeleted && (isDevAccount || isOwnerOrAdmin || isViewer) && <MenuItem onClick={() => this.openUsersBox(object)}>
                <i className="fa fa-user"></i>
                <div>Users</div>
              </MenuItem>}
              {!isDeleted && subscriptionVerified && (isDevAccount || isOwnerOrAdmin) && <MenuItem onClick={() => this.openTeamsBox(object)}>
                <i className="fa fa-users"></i>
                <div>Teams</div>
              </MenuItem>}
              {!isDeleted && (isDevAccount || isOwner) && <MenuItem onClick={() => this.openCompanyBox(object)}>
                <i className="fas fa-building"></i>
                <div>Company</div>
              </MenuItem>}
              {(isDevAccount || isOwnerOrAdmin || isViewer) && this.projects && <MenuItem onClick={() => this.projects(object)}>
                <i className="fas fa-chart-area"></i>
                <div>Analytics</div>
              </MenuItem>}
              {!isDeleted && this.template && <MenuItem onClick={() => this.template(object)}>
                <i className="fas fa-network-wired"></i>
                <div>App</div>
              </MenuItem>}
              {isDeleted && (isOwnerOrAdmin) && this.restore && <MenuItem onClick={() => this.setState({ menuOpen: false, confirmRestore: true })}>
                <i className="fas fa-trash-restore"></i>
                <div>Restore</div>
              </MenuItem>}
              {!isDeleted && this.duplicate && <MenuItem onClick={() => { this.setState({ menuOpen: false, confirmDuplicate: true }); }}>
                <i className="fas fa-clone"></i>
                <div>Duplicate</div>
              </MenuItem>}
              {!isDeleted && isOwner && this.lock && !isLocked && <MenuItem onClick={() => { this.setState({ menuOpen: false }); this.lock(object) }}>
                <i className="fas fa-lock"></i>
                <div>Lock</div>
              </MenuItem>}
              {!isDeleted && isOwner && this.unlock && isLocked && <MenuItem onClick={() => { this.setState({ menuOpen: false }); this.unlock(object) }}>
                <i className="fas fa-lock-open"></i>
                <div>Unlock</div>
              </MenuItem>}
              {isOwner && !isLocked && !isDeleted && this.delete && !this.props.team && <MenuItem onClick={() => this.setState({ menuOpen: false, confirmDelete: true })}>
                <i className="fas fa-trash"></i>
                <div>Delete</div>
              </MenuItem>}
              {!isDeleted && this.showShareLinks && <MenuItem onClick={() => this.setState({ menuOpen: false, showShareLinksPopup: object })}>
                <i className="fas fa-share-alt"></i>
                <div>Share</div>
              </MenuItem>}
              {!isDeleted && this.showEmbedCode && <MenuItem onClick={() => this.setState({ menuOpen: false, showEmbedCodePopup: object })}>
                <i className="fas fa-code"></i>
                <div>Embed</div>
              </MenuItem>}
              {account && account._id === '5cf13c4899397a33fd191007' && this.updateVectors && <MenuItem onClick={() => { this.setState({ menuOpen: false }); this.updateVectors(object); }}>
                <i className="fas fa-database"></i>
                <div>Update Vectors</div>
              </MenuItem>}
            </MenuList>
          </MenuWrapper>
        </Popup>}
      </Wrapper>
    );
  }
}
