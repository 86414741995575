import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import WindowSize from '../../services/WindowSize';
import Constants from '../../submodules/logictry_config/constants';
import TreeCache from '../../services/cache/TreeCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import UserAccount from '../../services/UserAccount';
import LogicBaseCollectionSubscriptionCache from '../../services/cache/LogicBaseCollectionSubscriptionCache';
import UserCache from '../../services/cache/UserCache';
import LogicBaseAppList from '../../components/LogicBaseAppList';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../LoadingIndicator';
import Navigation from '../../services/Navigation';
import Scrollable from '../Scrollable';
import CollectionSubscribeButton from '../CollectionSubscribeButton';
import CollectionNewAppPopup from '../CollectionNewAppPopup';

const Wrapper = styled.div`
  h2 {
    font-size: ${Constants.LargeFontSize};
    ${Constants.MediaMobile} {
      font-size: ${Constants.SemiLargeFontSize};
    }
    font-weight: 500;
    cursor: pointer;
    overflow: hidden;
    -webkit-line-clamp: 1;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
  p {
    margin: 0 0 0.5rem;
  }
`;

export default class LogicBaseCollectionRow extends React.PureComponent {
  static propTypes = {
    collection: PropTypes.object,
    wrap: PropTypes.bool,
    showCreateNew: PropTypes.bool,
  }
  state = {
    createNewPopup: false
  }
  componentDidMount() {
    TreeCache.onStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    TreeCache.offStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  render() {
    const { collection, wrap, showCreateNew } = this.props;
    const { createNewPopup } = this.state;
    const { mobile, tablet } = WindowSize;
    const collectionStyle = { height: !wrap && (mobile && 190 || tablet && 206 || 228) || null };
    const loading = <Wrapper key={collection._id} style={collectionStyle}><FullScreenVerticalCenterContent key={collection._id}><LoadingIndicator /></FullScreenVerticalCenterContent></Wrapper>;
    const notFound = <Wrapper key={collection._id} style={collectionStyle}><FullScreenVerticalCenterContent key={collection._id}><h1>Page Not Found</h1></FullScreenVerticalCenterContent></Wrapper>;
    const apps = TreeCache.getByIds(collection.apps);
    if (!apps) return loading;
    const publicUser = LogicBaseUserCache.get(collection.owner);
    if (!publicUser) return loading;
    const users = UserCache.getUsersByIds([collection.owner]);
    if (!users) return loading;
    const user = users[0];
    if (!user || !user._id) return notFound;
    return (
      <Wrapper key={collection._id} style={collectionStyle}>
        {!wrap && <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <h2 onClick={() => Navigation.push(`/collections/${collection._id}`)}>{collection.title}</h2>
          <CollectionSubscribeButton collection={collection} />
        </div>}
        <Scrollable
          horizontal={!wrap}
          vertical={wrap}
          onScroll={this.onScroll}
          scrollRef={(e) => { this.topicsRef = e; }}
          rememberScrollPosition={`logicbasecollection_${collection._id}`}
          style={{ padding: wrap && '2rem 0' }}
        >
          <LogicBaseAppList
            showUser
            apps={apps}
            horizontal
            small={wrap && mobile}
            collection={collection._id}
            wrap={wrap}
            showCreateNew={showCreateNew}
            onCreateNew={() => this.setState({ createNewPopup: true })}
          />
        </Scrollable>
        {createNewPopup && <CollectionNewAppPopup
          collection={collection}
          onClose={() => this.setState({ createNewPopup: false })}
        />}
      </Wrapper>
    )
  }
}
