/**
 * App Constant Values
 */

// Make all query parameters case insensitive
const newSearch = {};
(window.location.href.split('?')[1] || '').split('&').forEach((keyValPair) => {
  const keyValArray = unescape(keyValPair).split('=');
  const key = keyValArray[0];
  const value = keyValArray[1];
  newSearch[key] = value;
  newSearch[key.toLowerCase()] = value;
});

// Conditional flags
const isDevelopment = window.location.hostname.endsWith('localhost');
const environment = window.logictry && window.logictry.environment || isDevelopment && 'development';
const isApp = window.parent === window.self && window.self === window.top && !window.frameElement;
const isIphone = window.logictry && window.logictry.isIphone;
const isWidget = !isApp;
const isSalesforce = false;
const isLogicWiki = window.location.hostname === 'logictry.com' || window.location.hostname === 'localhost';

const documentReferrer = document.referrer && document.referrer.split( '/' )[2].split(':')[0];
const { apikey } = newSearch;

let isWidgetOnSameDomain = false;
if (isWidget) {
  try {
    // Check if window.parent and its properties are accessible
    if (window.parent && window.parent.location && window.parent.location.origin) {
      isWidgetOnSameDomain = window.location.origin === window.parent.location.origin;
    }
  } catch (e) {
    // Handle the case where access to window.parent is blocked
  }
}

// Default colors
const White = 'white';
const StaticGray = '#fafafa';
const DarkGray = '#030303';
const Gray = '#606060';
const Black = 'black';
const FacebookBlue = '#3966b8';
const TwitterBlue = '#1b67f2';
const LinkedInBlue = '#0077b5';
const LightFacebookBlue = '#6d84b4';
const InstagramPurple = '#C13584';
const DisabledColor = 'rgba(0, 0, 0, 0.3)';
const DisabledBackgroundColor = 'rgba(0, 0, 0, 0.7)';
const ButtonShadow = 'rgba(0, 0, 0, 0.3) 0 0 2.5rem 0';
const ImageHoverOverlay = 'rgba(255, 246, 170, 0.15)';
const CardShadow = 'rgba(0, 0, 0, 0.3) 0 0.125rem 0.25rem 0';
const BoxShadow = '0 0.0625rem 0.375rem rgba(0,0,0,0.4)';
const CTAShadow = 'rgba(0, 0, 0, 0.1) 0px 8px 23px 0px';
const LightBorder = '1px solid #dbdbdb';
const LightBackground = '#f9f9f9';

// Detect if valid color
const isColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
}

// Allow for company themes
const theme = {};
if (window.logictry && window.logictry.company && window.logictry.company.theme) {
  Object.keys(window.logictry.company.theme).forEach((key) => {
    theme[key.toLowerCase()] = window.logictry.company.theme[key];
  });
}

// Set colors based on whether a query param, company theme, or default
const colors = {
  PrimaryColor: getPropertyQueryOrTheme('primarycolor', '#1ebd53'),
  BackgroundColor: getPropertyQueryOrTheme('backgroundcolor', White),
  LogoWhite: getPropertyQueryOrTheme('logowhite', 'https://logictrystatic.s3.amazonaws.com/images/logo_white.png', true),
  Logo: getPropertyQueryOrTheme('logo', 'https://logictrystatic.s3.amazonaws.com/images/logo062623.svg', true),
};
function getPropertyQueryOrTheme(_prop, _default, _notColor) {
  return (theme && (_notColor || isColor(theme[_prop])) && theme[_prop] || _default);
}

// Default sizing
const BannerTextFontSize = '4rem';
const VeryLargeFontSize = '2rem';
const LargeFontSize = '1.5rem';
const SemiLargeFontSize = '1.17rem';
const NormalFontSize = '1rem';
const SemiSmallFontSize = '0.875rem';
const SmallFontSize = '0.75rem';
const VerySmallFontSize = '0.6rem';
const NormalSectionMargin = '1.875rem auto';
const NormalSectionPadding = '1.875rem 0';
const MaxWidth = '68rem';
const MobileWidth = 500;
const TabletWidth = 900;
const NativeHeaderPaddingTop = '1.375rem';
const NativeiPhoneHeaderHeight = '4.375rem';
const NativeHeaderHeight = '5rem';
const MobileHeaderHeight = '3.5rem';
const DesktopHeaderHeight = '3.5rem';
const NativeTabBarHeight = '2.875rem';
const NativeTabBarPaddingBottom = '0';
const MobileFullscreenHeight = 'calc(100vh - 4rem)';
const NativeFullscreenHeight = 'calc(100vh - 7.25rem)';
const NormalTransitionTime = '0.5s';
const UserInfoPopupMaxWidth = '400px';
const ListPopupWidth = '600px';

const TreeContentIndent = '2.5rem';
const TreeContentHalfIndent = '1rem';
const TreeContentIconIndent = '1.75rem';
const TreeAnswerIndent = '2rem';

const MindmapRoot = 'darkred';
const MindmapQuestion = '#D1E4D1';
const MindmapAnswer = '#AFE3C5';
const MindmapModule = '#1ebd53';
const MindmapDefault = '#187E52';
const ButtonHoverLightenFilter = 'brightness(120%)';
const BottomBorderDivider = '0.0625rem solid #e2e2e2';

const FacebookPage = 'https://www.facebook.com/logictry/';
const InstagramPage = 'https://www.instagram.com/logictry/';
const TwitterPage = 'https://twitter.com/logictry';
const LinkedInPage = 'https://www.linkedin.com/company/logictry/';
const YouTubePage = 'https://www.youtube.com/@logictry';
const TikTokPage = 'https://tiktok.com/@logicishot';

const PaginationCount = 12;
const BoxSize = 180;
const SmallBoxSize = 120;
const BoxRadius = 16;

class Constants {
  constructor() {
    const properties = {
      ...colors,
      documentReferrer,
      apikey,
      isDevelopment,
      environment,
      isWidget,
      isWidgetOnSameDomain,
      isSalesforce,
      isApp,
      isIphone,
      isLogicWiki,
      TreeContentIndent,
      TreeContentHalfIndent,
      TreeContentIconIndent,
      TreeAnswerIndent,
      BottomBorderDivider,
      NativeHeaderPaddingTop,
      NativeHeaderHeight,
      NativeiPhoneHeaderHeight,
      MobileHeaderHeight,
      DesktopHeaderHeight,
      NativeTabBarHeight,
      NativeTabBarPaddingBottom,
      DesktopFullscreenHeight: isWidget ? '100vh' : 'calc(100vh - 5rem)',
      MobileFullscreenHeight,
      NativeFullscreenHeight,
      DisabledBackgroundColor,
      White,
      StaticGray,
      DarkGray,
      DarkBlue: '#2b2d42',
      MediumTextGray: '#666',
      LightTextGray: '#444',
      Black,
      FacebookBlue,
      TwitterBlue,
      LinkedInBlue,
      LightFacebookBlue,
      InstagramPurple,
      MindmapRoot,
      MindmapQuestion,
      MindmapAnswer,
      MindmapModule,
      MindmapDefault,
      ButtonTextColor: White,
      ButtonHoverLightenFilter,
      ButtonShadow,
      CardShadow,
      BoxShadow,
      CTAShadow,
      LightBorder,
      LightBackground,
      InputDisabledColor: DisabledColor,
      DarkTextColor: DarkGray,
      LightTextColor: Gray,
      ImageHoverOverlay,
      NormalSectionMargin,
      NormalSectionPadding,
      BannerTextFontSize,
      VeryLargeFontSize,
      LargeFontSize,
      SemiLargeFontSize,
      NormalFontSize,
      SemiSmallFontSize,
      SmallFontSize,
      VerySmallFontSize,
      MobileWidth,
      TabletWidth,
      UserInfoPopupMaxWidth,
      ListPopupWidth,
      MaxWidth,
      NormalTransitionTime,
      MediaDesktop: `@media (min-width: ${TabletWidth}px)`,
      MediaTablet: `@media (min-width: ${MobileWidth}px) and (max-width: ${TabletWidth - 1}px)`,
      MediaMobile: `@media (max-width: ${MobileWidth - 1}px)`,
      MediaMobileAndTablet: `@media (max-width: ${TabletWidth - 1}px)`,
      MediaTabletAndDesktop: `@media (min-width: ${MobileWidth}px)`,
      MediaStaticMaxWidth: `@media (max-width: 80rem)`,
      StaticContentMaxWidth: 'max-width: 80rem;',
      StaticTextMaxWidth: 'max-width: 60rem;',
      desktop: "only screen and (min-width: 1400px) and (max-width: 1920px)",
      laptop: "only screen and (min-width: 1200px) and (max-width: 1399px)",
      lg: "only screen and (min-width: 992px) and (max-width: 1199px)",
      md: "only screen and (min-width: 768px) and (max-width: 991px)",
      xs: "(max-width: 767px)",
      sm: "only screen and (min-width: 576px) and (max-width: 767px)",
      FacebookPage,
      InstagramPage,
      TwitterPage,
      LinkedInPage,
      YouTubePage,
      TikTokPage,
      PaginationCount,
      BoxSize,
      SmallBoxSize,
      BoxRadius,
    };
    Object.keys(properties).forEach((_key) => {
      this[_key] = properties[_key];
    });
    this.InputBorderBottom = `border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.42);
    &:focus {
      border-bottom: 0.0625rem solid ${this.PrimaryColor};
    }`;
    this.defaultLineHeight = '1.3125rem';
    this.defaultLinePadding = '0.59375rem';
    this.WarningTextColor = this.PrimaryColor;
    this.CancelTextColor = this.DarkTextColor;
    this.ConfirmTextColor = this.PrimaryColor;
    this.ButtonActiveColor = this.PrimaryColor;
    this.InternetDisabledColor = this.PrimaryColor;
    this.GradientBackground = `
      background: radial-gradient(circle farthest-side at center bottom, ${this.LightPrimaryColor}, ${this.DarkPrimaryColor} 100%);
    `;
    this.LinearGradientBackground = `
      background: linear-gradient(to bottom left,#42b618,#06680e 100%);
    `;
  }
}

const singleton = new Constants();
export default singleton;
