import Model from './model';

export default class LogicBaseApp extends Model {
  constructor(_id, createdTime, likes, pins) {
    super();
    this._id = _id;
    this.createdTime = createdTime;
    this.likes = likes || 0;
    this.pins = pins || 0;
  }
  getJson = () => {
    const { _id, likes, pins } = this;
    return {
      _id,
      l: likes,
      p: pins,
    }
  }
}
