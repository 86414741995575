import React from 'react';
import styled from 'styled-components';
import UserCache from '../../services/cache/UserCache';
import { getTopLikedUsers, getTopPinnedUsers } from '../../services/Pagination';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import UserProfileHeader from '../../components/UserProfileHeader/index';
import Scrollable from '../../components/Scrollable';
import WindowSize from '../../services/WindowSize';
import LogicBaseTab from '../../components/LogicBaseTab';

const Leaderboard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 1rem;
  max-width: 52rem;
  margin: auto;
  > div {
    margin: 1rem 1rem;
  }
  input {
    border: 1px solid #C1C1C1;
    border-radius: 1.5rem;
    overflow: hidden;
    line-height: 18px;
    padding: 1rem 3rem 1rem 1rem;
    margin: 2rem 1rem;
  }
`;
let lastShowed = 'pins'
export default class LogicBaseLeaderboard extends React.PureComponent {
  constructor() {
    super();
    this.topPinnedUsersCache = getTopPinnedUsers('');
    this.topLikedUsersCache = getTopLikedUsers('');
  }
  state = {
    showing: lastShowed
  }
  componentDidMount() {
    this.topPinnedUsersCache.onStateUpdate(this);
    this.topLikedUsersCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    lastShowed = this.state.showing
    this.topPinnedUsersCache.offStateUpdate(this);
    this.topLikedUsersCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    clearTimeout(this.__searchTimeout);
    WindowSize.offStateUpdate(this);
  }
  onPagination = () => {
    if (!this.showCache) return;
    if (this.showCache.showMore && this.showCache.allFound && this.showCache.initialized) {
      this.showCache.getMore();
    }
  }
  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.runSearch();
    } else {
      clearTimeout(this.__searchTimeout);
      this.__searchTimeout = setTimeout(() => {
        this.runSearch();
      }, 500);
    }
  }
  runSearch = () => {
    this.searchText();
  }
  searchText = () => {
    const { searchInput } = this;
    this.textToSearch = searchInput.value.toLowerCase();
    this.forceUpdate();
  }
  render() {
    const { mobile } = WindowSize;
    const { showing } = this.state;
    this.showCache = showing === 'pins' && this.topPinnedUsersCache || showing === 'followers' && this.topLikedUsersCache;
    this.menuUsers = this.showCache.currentPage.length === 0 && this.menuUsers || this.showCache.currentPage;
    const topUserIds = this.menuUsers.map((u) => u._id);
    const users = UserCache.getUsersByIds(topUserIds);
    const search = this.textToSearch || '';
    const usersSearched = search && UserCache.getUsers({
      page: 1,
      search,
    });
    if (search) {
      this.showUsers = usersSearched && {
        search: true,
        users: usersSearched
      } || this.showUsers;
    } else {
      this.showUsers = users && {
        search: false,
        users: users
      } || this.showUsers;
    }
    const tabs = [
      { name: 'pins', value: 'Most Pins' },
      { name: 'followers', value: 'Most Followers' }
    ];
    return (
      <Scrollable
        key={showing}
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: '1rem 0 2rem' }}
        rememberScrollPosition={`logicbaseleaderboard_${showing}`}
      >
        <Leaderboard>
          <input
            key="usersearch"
            onKeyDown={this.onKeyDown}
            ref={(e) => { this.searchInput = e; }}
            placeholder={"Search users"}
            defaultValue={search}
          ></input>
          {(!this.showUsers || !this.showUsers.search) && <LogicBaseTab tabs={tabs} selected={showing} onChange={(showing) => this.setState({ showing })} />}
          {this.showUsers && this.showUsers.users.filter((user) => !user.error).map((user) => (
            <div key={user._id}>
              <UserProfileHeader
                user={user}
                large
              />
            </div>
          ))}
        </Leaderboard>
      </Scrollable>
    )
  }
}
