import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import { getNotifications } from '../../services/Pagination';
import UserAvatar from '../UserAvatar';
import ClickableDiv from '../ClickableDiv';
import Status from '../Header/Status';
import Popup from '../Popup/index';
import MenuList from '../Popup/MenuList';
import MenuItem from '../Popup/MenuItem';
import LogicBaseSearchBar from '../LogicBaseSearchBar';
import DrawerService from '../../services/Drawer';
import LogicBaseCollectionEditPopup from '../LogicBaseCollectionEditPopup';

const Wrapper = styled.div`
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 0 1.5rem 0.5rem 1.5rem;
  ${Constants.MediaMobile} {
    height: 3.5rem;
    padding: 0;
  }
  a {
    text-decoration: none;
  }
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  > div {
    display: flex;
    align-items: center;
    > img {
      padding-left: 2px;
      height: 32px;
      width: 112px;
    }
  }
`;
const Links = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > div {
    flex-shrink: 0;
    height: 100%;
    display: flex;
    align-items: center;
    > button {
      padding: 0.5rem;
      @media (max-width: 340px) {
        padding: 0.25rem;
      }
      width: 100%;
      height: 100%;
      a {
        color: black;
      }
    }
  }
  > div:nth-last-child(2) {
    @media (max-width: 350px) {
      display: ${props => props.display};
    }
  }
  > * {
    font-size: ${Constants.SemiSmallFontSize};
    padding: 0.75rem;
    cursor:pointer;
  }
  i {
    font-size: ${Constants.NormalFontSize};
  }
`;
const Avatar = styled.div`
  padding: 0.5rem;
  position: relative;
`;

const logo = 'https://logictrystatic.s3.amazonaws.com/images/logo062623.svg';

export default class LogicBaseHeader extends React.PureComponent {
  state = {
    open: false,
    mobileShowSearch: false,
    showCreateCollection: false,
  }
  componentDidMount() {
    Navigation.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
  }
  componentWillUnmount() {
    Navigation.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    if (this.notifications) this.notifications.offStateUpdate(this);
    this.notifications = null;
  }
  clickedAccount = () => this.setState({ open: true });
  close = () => this.setState({ open: false });
  render() {
    const { mobile } = WindowSize;
    const { open, mobileShowSearch, showCreateCollection } = this.state;
    const { isLoggedIn, account, isDevAccount, isLoggingIn } = UserAccount;
    const routes = [{ name: 'Collections', url: 'collections' }, { name: 'Users', url: 'users' }];
    if (!isLoggedIn) routes.push({ name: 'Login', url: 'login' });
    let notification = false;
    if (isLoggedIn) {
      if (!this.notifications) {
        this.notifications = getNotifications(account._id);
        this.notifications.onStateUpdate(this);
      }
      notification = !!this.notifications.currentPage.find(({ read }) => !read);
      if ((!account.username || !account.fullname || (!account.image || !account.image.link))) notification = true;
    } else {
      if (this.notifications) this.notifications.offStateUpdate(this);
      this.notifications = null;
    }
    const uniqueKeyToForceReRenderOnPageChange = `${window.location.pathname}${window.location.search}${JSON.stringify(Navigation.location.state)}`;
    if (isLoggingIn) return <Wrapper></Wrapper>;
    return (
      <Wrapper>
        {!Constants.isIphone && <i onClick={() => DrawerService.toggle()} style={{ padding: '0.5rem', marginLeft: '0.5rem', cursor: 'pointer' }} className="fas fa-bars"></i>}
        <Logo style={{ marginLeft: Constants.isIphone && '0.5rem' || null }}>
          <ClickableDiv onClick={() => Navigation.push(Navigation.home)}>
            <img alt={logo} src={logo}></img>
          </ClickableDiv>
        </Logo>
        {(!mobile || mobileShowSearch) && <div style={{ flex: 1, padding: '0 1rem' }} key={uniqueKeyToForceReRenderOnPageChange}>
          <LogicBaseSearchBar onSearching={(state) => { if (state === false) { this.setState({ mobileShowSearch: false }); } }} key={mobile ? 'hideTyping' : 'showTyping'} showTyping={!mobile} autoFocus={mobileShowSearch} />
        </div>}
        <Status hideStatusUpdates />
        <Links style={{ flex: mobile ? 1 : 'unset', marginRight: mobile && '0.5rem' }} display={isLoggedIn ? null : 'none'}>
          {mobile && <div onClick={() => this.setState({ mobileShowSearch: true })}>
            <i className="fas fa-search"></i>
          </div>}
          {isLoggedIn && <>
            {!mobile && <i
              className={"far fa-plus-square"}
              style={{ fontSize: Constants.SemiLargeFontSize, color: 'black' }}
              onClick={() => this.setState({ showCreateCollection: true })}
            ></i>}
            <div style={{ position: 'relative' }} onClick={() => Navigation.push(Navigation.accountNotifications)}>
              <i className={"far fa-bell"} style={{ fontSize: Constants.SemiLargeFontSize, color: 'black' }}></i>
              {notification && <div style={{ position: 'absolute', top: 6, right: 6, width: 12, height: 12, background: 'red', borderRadius: '50%' }}></div>}
            </div>
            <Avatar>
              <UserAvatar buttonRef={(e) => { this.anchorEl = e; }} onClick={this.clickedAccount} user={account} />
            </Avatar>
          </> || <>
            <div onClick={() => { Navigation.push(Navigation.login) }}>
              Login
            </div>
            <div onClick={() => { Navigation.push(Navigation.register) }}>
              Sign Up
            </div>
          </>}
        </Links>
        {showCreateCollection && <LogicBaseCollectionEditPopup
          onClose={() => this.setState({ showCreateCollection: false })}
        />}
        {open && <Popup
          allowBackgroundClose
          onClose={this.close}
          anchorEl={this.anchorEl}
          anchorBottomRight
        >
          <MenuList>
            <MenuItem onClick={() => { this.close(); Navigation.push(Navigation.account); }}>
              Account
            </MenuItem>
            {isDevAccount && <MenuItem onClick={() => { this.close(); Navigation.push(Navigation.analytics); }}>
              Analytics
            </MenuItem>}
            <MenuItem onClick={() => { this.close(); Navigation.push(Navigation.accountNotifications); }}>
              Notifications
            </MenuItem>
            <MenuItem onClick={() => { this.close(); Navigation.push(Navigation.accountSettings); }}>
              Settings
            </MenuItem>
            <MenuItem onClick={() => { this.close(); UserAccount.logout() }}>
              Log Out
            </MenuItem>
          </MenuList>
        </Popup>}
      </Wrapper>
    )
  }
}
