import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import UserCache from '../../services/cache/UserCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import { getAppLikes, getCollectionSubscribers, getCommentLikes } from '../../services/Pagination';
import UserProfileHeader from '../../components/UserProfileHeader';
import Scrollable from '../../components/Scrollable';
import LoadingIndicator from '../../components/LoadingIndicator';

const NotificationWrapper = styled.div`
  max-width: 52rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;

  > h1 {
    margin: 10px;
  }
`;
const Request = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  > * {
    margin: 0.5rem;
    ${Constants.MediaMobile} {
      margin: 0.5rem auto;
    }
  }
  > div:nth-child(2) {
    flex: 1;
  }
`;

export default class LogicBaseUsersPage extends React.PureComponent {
  state = {
    content: Navigation.currentLocation[1],
    id: Navigation.currentLocation[2]
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    UserCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    if (this.cache) this.cache.offStateUpdate(this);
  }
  onPagination = () => {
    if (this.cache.showMore && this.cache.allFound && this.cache.initialized) {
      this.cache.getMore();
    }
  }
  render() {
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const { mobile } = WindowSize;
    const { content, id } = this.state;

    let users;
    if (content === 'comments') {
      if (!this.cache) {
        this.cache = getCommentLikes(id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ follower }) => follower);
    } else if (content === 'collections') {
      if (!this.cache) {
        this.cache = getCollectionSubscribers(id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ follower }) => follower);
    } else {
      if (!this.cache) {
        this.cache = getAppLikes(id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ follower }) => follower);
    }

    if (!users) return loading;
    this.users = UserCache.getUsersByIds(users) || this.users;
    if (!this.cache.allFound) return loading;
    if (!this.users) return loading;
    return (
      <Scrollable
        key={id}
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: '1rem 0 2rem' }}
        rememberScrollPosition={`logicbaseusers`}
      >
        <NotificationWrapper>
          {this.users && this.users.length > 0 ? this.users.map((user) => (
            <Request key={user._id}>
              <UserProfileHeader
                user={user}
                large
              />
            </Request>
          )) : (
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <div>{content === 'collections' ? 'No subscribers yet' : 'No likes yet'}</div>
            </div>
          )}
        </NotificationWrapper>
      </Scrollable>
    );
  }
}
