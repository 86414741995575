import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import UserCache from '../../services/cache/UserCache';
import TreeCache from '../../services/cache/TreeCache';
import { TreeJsonToTreeText } from '../../models/tree';
import Constants from '../../submodules/logictry_config/constants';
import Popup from '../../components/Popup/index';
import Wysiwyg from '../../submodules/logictry_wysiwyg/src/js';
import { convertStructuredTextIntoLogicSyntax } from '../../submodules/logictry_wysiwyg/src/utils';
import GoInteractive from '../../components/GoInteractive';
import OpenAI from '../../services/OpenAI';
import StatefulButton from '../../components/StatefulButton';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 6rem);
  width: 860px;
  height: 640px;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 6rem);
  > h2 {
    text-align: center;
    margin: 2rem 0 1rem;
  }

  input {
    padding: 20px;
    width: 100%;
  }
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 1.5rem 0;
    padding-bottom: 8px;
    > div {
      position: relative;
      cursor: pointer;
      // width: 130px;
      text-align: center;
    }
    > div.underline {
      // color: ${Constants.PrimaryColor};
      font-weight: 500;
    }
    > div.underline::after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: black;
    }
  }
  > div:nth-child(2) {
    flex: 1;
    width: 100%;
    position: relative;
    > div {
      position: absolute;
      inset: 0 1rem;
    }
  }
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem;
  gap: 1rem;
  > button {
    flex: 1;
    height: 3rem;
    color: white;
    background: ${Constants.PrimaryColor};
    border-radius: 0.5rem;
    font-size: ${Constants.NormalFontSize};
  }
`;
const SearchBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 784px;
  input {
    flex: 1;
    height: 48px;
    border: 1px solid rgb(193, 193, 193);
    border-radius: 0.5rem;
  }
`;

const PASTE_TEXT = 'PasteText';
const ANALYZE_WEBSITE = 'AnalayzeWebsite';
const CREATE_WITH_AI = 'CreateWithAI';
const USE_TEMPLATE = 'UseTemplate';

export default class CreateTemplatePopup extends React.PureComponent {
  static propTypes = {
    treeOrSpreadsheet: PropTypes.object,
    team: PropTypes.string,
    onClose: PropTypes.func,
  }
  state = {
    selection: PASTE_TEXT,
    name: '',
    html: '',
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    if (this.wysiwyg) {
      this.wysiwyg.unmount();
      this.wysiwyg = null;
    }
    TreeCache.offStateUpdate(this);
  }
  onRef = (mountNode) => {
    if (this.wysiwyg || !mountNode) return;
    this.wysiwyg = new Wysiwyg(mountNode, {
      onBlur: null,
      height: '100%',
      width: '100%',
      focusOnInit: null,
      fullscreen: true,
      disabled: false,
      initialText: '',
      onTextChanged: (html) => this.setState({ html }),
      borderRadius: 8
    });
  }
  create = () => {
    const { onClose, team } = this.props;
    const { name, html } = this.state;
    const initialJSON = {
      children: [
        {
          text: name || '',
          attributes: ['Title'],
          children: [],
        },
        {
          text: '',
          attributes: ['Description'],
          children: [],
        },
        {
          text: '',
          attributes: ['Root'],
          children: [],
        }
      ]
    };
    let newTreeText = '';
    if (html) {
      convertStructuredTextIntoLogicSyntax(html, initialJSON);
      newTreeText = TreeJsonToTreeText(initialJSON);
    }
    const tree = TreeCache.createNew(newTreeText);
    if (team) tree.addTeam(team);
    TreeCache.createTree(tree);
    onClose();
  }
  render() {
    const { onClose } = this.props;
    const { selection, generating, html } = this.state;
    // const tree = TreeCache.getTree('6357e561bb34735df70674e9');
    return (
      <Popup
        onClose={generating !== 'busy' && onClose}
        allowBackgroundClose
        showCloseButton
      >
        <Wrapper>
          <div>
            <div className={selection === PASTE_TEXT && 'underline' || ''} onClick={() => this.setState({ generating: null, selection: PASTE_TEXT })}>Paste Text</div>
            <div className={selection === ANALYZE_WEBSITE && 'underline' || ''} onClick={() => this.setState({ generating: null, selection: ANALYZE_WEBSITE })}>Analyze Website</div>
            {/* <div className={selection === CREATE_WITH_AI && 'underline' || ''} onClick={() => this.setState({ selection: CREATE_WITH_AI })}>Create with AI</div>
            <div className={selection === USE_TEMPLATE && 'underline' || ''} onClick={() => this.setState({ selection: 2 })}>Use Template</div> */}
          </div>
          <div>
            <div style={{ display: selection === PASTE_TEXT && !generating && 'flex' || 'none' }} ref={this.onRef}></div>
            <div style={{ display: selection === ANALYZE_WEBSITE && !generating && 'flex' || 'none', flexDirection: 'column' }}>
              <SearchBar>
                <input id="GOINTERACTIVE__WEBSITE" placeholder="Your Website (URL)" type="text" defaultValue={OpenAI.website}></input>
                <StatefulButton
                  onClick={() => {
                    if (!document.getElementById('GOINTERACTIVE__WEBSITE').value) return;
                    this.setState({ generating: 'busy' });
                  }}
                  text="Go!"
                  state={generating === 'busy' ? 'busy' : null}
                />
              </SearchBar>
            </div>
          </div>
          {generating && <div style={{ position: 'absolute', inset: 0, display: 'flex', flexDirection: 'column', background: 'white' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '1rem 2.5rem' }}>
              {generating === 'complete'  && <i onClick={() => this.setState({ generating: null })} className="fas fa-arrow-left"></i>}
              <h2>{generating === 'complete' ? 'Suggestions' : 'Generating Suggestions'}</h2>
            </div>
            <div style={{ position: 'relative', flex: 1 }}>
              <GoInteractive
                html={selection === PASTE_TEXT && html}
                website={selection === ANALYZE_WEBSITE && document.getElementById('GOINTERACTIVE__WEBSITE').value}
                onComplete={() => this.setState({ generating: 'complete' })}
              />
            </div>
          </div>}
          {(selection === PASTE_TEXT) && !generating && <Buttons>
            <button onClick={this.create}>
              Create App from Content
            </button>
            <button onClick={() => {
              if (!html) return;
              this.setState({ generating: 'busy' })
            }}>
              Generate Content Suggestions
            </button>
          </Buttons>}
        </Wrapper>
      </Popup>
    );
  }
}
